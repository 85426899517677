
import React, { useEffect, useState } from "react";
import { useParams, } from 'react-router-dom';
import logodark from "../../assets/images/moondance_logo_sm.png";
import logolight from "../../assets/images/moondance_logo_sm.png";
import { Link } from 'react-router-dom';

import { Card, CardBody, Alert, Col, Container, Form, Input, Label, Row } from "reactstrap";


import UseMoondanceApi  from "../lib/api";


const MoondanceLostPassword = () => {


    const moondanceApi = UseMoondanceApi();
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [submitResult, setSubmitResult] = useState(false);
    const params = useParams();
    const activation_code = (params.code ? params.code : null);


    function handlePasswordChange(e) {
        setErrors(false)
        setSubmitResult(false)
        setPassword(e.target.value);
    }
    function handleConfirmPasswordChange(e) {
        setErrors(false)
        setSubmitResult(false)
        setConfirmPassword(e.target.value);
    }


    function handleSubmit() {
        const newErrors = {};
        const requestData = {}
        var isError = false
        if (password !== confirmPassword) {
            newErrors.confirmPassword = 'Confirm password does not match password'
            isError = true
        }
        if (password.length < 9) {
            newErrors.password = 'Password must be at least 8 character long, more is recommended'
            isError = true
        }



        if (isError) {
            setErrors(newErrors)
            console.log(newErrors)



        } else {
            console.log('reset passwords')
            requestData.password = password
            requestData.activation_code = activation_code
            console.log(params)
            moondanceApi.resetPassword(requestData).then(response => {
                setErrors({})
                response.success = true
                setSubmitResult(response)
                console.log(response)
            })
                .catch(error => {
                    newErrors.alert = 'An error occured'
                    setErrors(newErrors)
                    setSubmitResult(error)


                });
        }


    }
    useEffect(() => {
        document.body.className = "bg-pattern";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>

            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card>
                                <CardBody className="p-4">
                                    <div>
                                        <div className="text-center">
                                            <Link to="/">
                                                <img
                                                    src={logodark}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-dark mx-auto"
                                                />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-light mx-auto"
                                                />
                                            </Link>
                                        </div>
                                        <h4 className="font-size-18 text-muted mt-2 text-center">
                                            Reset your password.
                                        </h4><hr/>
                                        {submitResult.success &&
                                            (<Row>
                                                <Col md={12}>
                                                    <p className="text-center">
                                                        Your new password has been successfully saved.
                                                    </p>
                                                    <hr/>
                                                    <div className="d-grid mt-4">
                                                <a
                                                    className="fw-medium btn btn-success waves-effect waves-light"
                                                    href="/login" >
                                                    Click here to log in
                                                </a>
                                                </div>
                                                </Col>
                                                </Row>
                                            )
                                        }
                                        {!submitResult.success && (

                                            <Form
                                                className="form-horizontal"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-4">
                                                            <Label className="form-label">New Password</Label>
                                                            <Input
                                                                name="password"
                                                                value={password || ""}
                                                                type="password"
                                                                placeholder="Enter Password"
                                                                onChange={handlePasswordChange}

                                                            />

                                                            {errors.password && (<label className="error mt-2 text-danger">{errors.password}</label>)}
                                                        </div>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Confirm Password</Label>
                                                            <Input
                                                                name="password"
                                                                value={confirmPassword || ""}
                                                                type="password"
                                                                placeholder="Confirm Password"
                                                                onChange={handleConfirmPasswordChange}

                                                            />

                                                            {errors.confirmPassword && (<label className="error mt-2 text-danger">{errors.confirmPassword}</label>)}
                                                        </div>



                                                        <div className="d-grid mt-4">
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                type="submit"
                                                            >
                                                                Reset your password
                                                            </button>
                                                        </div>



                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );


}


export default MoondanceLostPassword;