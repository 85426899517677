
import React, { useState, useEffect,useContext } from "react";
import { Card, CardBody, Col, Row, Container, Button, Modal } from "reactstrap";
import UseMoondanceApi from "../lib/api";

import { MoondanceContext } from '../../App.js';
import Switch from "react-switch";
import { QRCodeSVG as QRCode } from 'qrcode.react';

import Select from "react-select";

import MoondanceLayout from "../lib/layout.js";

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
        </div>
    );
};

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Yes
        </div>
    );
};
const UserProfile = () => {

    const moondanceApi = UseMoondanceApi();
    const [id, setId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [vat, setVat] = useState("");
    const [currency, setCurrency] = useState("EUR");
    const [availableCurrency, setAvailableCurrency] = useState([ 
                {value: 'EUR', label: 'Euro €' },
                {value: 'USD', label: 'US Dollar' },
                {value: 'GBP', label: 'UK Sterling Pound' },
                {value: 'CHF', label: 'Swiss Franc' },
                {value: 'INR', label: 'Indian Rupee' }
                ]);
    const [tel, setTel] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [initialValue, setInitialValue] = useState({});
    const [accountSelected, setAccountSelected] = useState([]);
    const [submitResult, setSubmitResult] = useState(false);
    const [mfaMethod, setMfaMethod] = useState('0');
    const [accountAccessRights, setAccountAccessRights] = useState({});
    const [errors, setErrors] = useState({});
    const [totpSecret, setTotpSecret] = useState(false);
    const[ user_data, setUserData] = useState([]);
    const [totpCode, setTotpCode] = useState(false);
    const [totpVerified, setTotpVerified] = useState(false);
    const [totpError, setTotpError] = useState(false);
    const [totpModalisOpen, toggleTotpModal] = useState(false);
    const [availableAuthMethod, setAvailableAuthmethod] = useState([{ value: 0, label: 'None' }, { value: 'EMAIL', label: 'Email' }, { value: 'TOTP', label: 'Time-based one time password (Google Authenticator)' }]);

    const { addAlert } = useContext(MoondanceContext);
    const {setUserCurrency} = useContext(MoondanceContext);
    function changeMfaMethod(value) {
        setMfaMethod(value)
        if (value === 'TOTP' && !totpVerified) {
            var requestData = {
                id: id
            }
            moondanceApi.createTotpSecret(requestData).then(response => {
                if (response.data) {
                    setTotpSecret(response.data)
                    toggleTotpModal(true)
                }

            })
                .catch(error => {
                    console.log(error)
                });
        } else {
            setTotpSecret(false)
        }

    }
    function validateTotpSetup() {
        var requestData = {
            user_id: id,
            otp: totpCode
        }
        moondanceApi.checkOtp(requestData).then(response => {
            console.log('otp', response)
            if (response && response.success) {
                setTotpVerified(true)
                setTotpError(false)

            } else {
                setTotpVerified(false)
                setTotpError(true)
            }

        })
            .catch(error => {
                console.log(error)
                setTotpVerified(false)
                setTotpError(true)
            });
    }

    function closeTotpModal() {

        toggleTotpModal(false)
    }

    useEffect(() => {

        moondanceApi.getLoggedinUser().then(profile => {

            if (profile) {
                var initValue = initialValue;
                console.log('Profile', profile)
                setId(profile.data.id);
                setFirstName(profile.data.first_name);
                setLastName(profile.data.last_name);
                setCompany(profile.data.company);
                setVat(profile.data.vat);
                setMfaMethod(profile.data.MFA);
                setCurrency(profile.data.currency);
                setTel(profile.data.tel);
                setUserData(profile.data);

                if (profile.data.MFA && profile.data.MFA !== 0) {
                    setMfaMethod(profile.data.MFA)
                } else {
                    setMfaMethod(0)
                }

                initValue.firstName = profile.data.first_name;
                initValue.lastName = profile.data.last_name;
                initValue.company = profile.data.company;
                initValue.vat = profile.data.vat;
                initValue.currency = profile.data.currency;
                initValue.tel = profile.data.tel;
                initValue.MFA = profile.data.mfa;

                setInitialValue(initValue)
            }


        }).catch(errors => {
            console.log(errors)
        });

        moondanceApi.fetchAccounts().then(response => {

            if (response.data) {

                response.data.forEach(element => {
                    if (element.access === 'admin') {
                        moondanceApi.listAccountUsers(element.id).then(response => {
                            var tmp = response.data
                            var tmpAccessRights = accountAccessRights;
                            tmpAccessRights[element.id] = tmp
                            setAccountAccessRights(tmpAccessRights)
                            //console.log(accountAccessRights)

                        }).catch(
                            console.log('Account list not found')
                        )
                    }

                });
                setAccounts(response.data)
                var initValue = initialValue;
                var tmp = response.data
                var initialAccountSelection = {}
                if (Array.isArray(tmp) && tmp.length > 0) {
                    initValue.accounts = []
                    tmp.map((account, index) => (
                        initialAccountSelection[account.id] = (account.selectedByDefault === 1 ? true : false)
                    ))
                    tmp.map((account, index) => (
                        initValue.accounts[account.id] = { n: account.n, selectedByDefault: account.selectedByDefault }
                    ))
                    setAccountSelected(initialAccountSelection)
                }
                setInitialValue(initValue)
            }
        }).catch(errors => {
            console.log(errors)
        })
    }, [accountAccessRights, initialValue]);

useEffect(() => {
console.log('userData', user_data)
}, [user_data]);

    function changeSelectedAccount(id, value) {
        var tmp = accounts
        tmp.map((account, index) => (
            account.id === id ? account.selectedByDefault = value : account.selectedByDefault = account.selectedByDefault

        ))
        setAccounts(tmp)
    }
    function submitForm() {
        console.log('submit Form')

        //Check if anything change in  profile
        if (firstName !== initialValue.firstName || lastName !== initialValue.lastName || company !== initialValue.company || vat !== initialValue.vat || currency !== initialValue.currency || tel !== initialValue.tel || mfaMethod !== initialValue.MFA) {
            console.log('profile changed')

            var requestData = {
                id: id,
                first_name: firstName,
                last_name: lastName,
                company: company,
                vat: vat,
                currency: currency,
                tel: tel,
                MFA: mfaMethod
            }
            console.log('Request data', requestData)
            moondanceApi.updateProfile(requestData).then(response => {
                setErrors({})
                setSubmitResult(response)
                addAlert({
                    status: 'success',
                    title: 'Profile Updated',
                    message: 'Your profile has  been updated'
                })
                setUserCurrency(currency)
            })
                .catch(error => {
                    setErrors({})
                    setSubmitResult(error)
                    console.log(submitResult)
                    return false
                });
        }

        //Check if anything change in  accounts
        if (JSON.stringify(accounts) !== JSON.stringify(initialValue.accounts)) {
        
            accounts.forEach(function (account) {

                if (account.selectedByDefault !== initialValue.accounts[account.id].selectedByDefault) {
                    var requestData = {
                        id: account.id,
                        selectedByDefault: (account.selectedByDefault ? 1 : 0),
                        uid: id,
                    }
                    moondanceApi.updateUserAccountsSelection(requestData).then(response => {
                        setSubmitResult(response)
                        console.log(submitResult)
                    })
                        .catch(error => {
                            setSubmitResult(error)
                            console.log(submitResult)
                        });

                }
            });
        }

    }



    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        <h1><i className="mdi mdi-account-circle-outline"></i> Your Profile</h1>
                        <hr />
                        <Col xl={8} sm={8}>
                            <p> Your customer account : {(user_data.customer_account_name ? user_data.customer_account_name :'No customer account')}</p>
                            <p> Your subscription includes  :</p>
                            <p>  Accounts  : {(user_data && user_data.current_accounts_count ? user_data.current_accounts_count :' NA')}  / {(user_data && user_data.max_accounts_allowed ? user_data.max_accounts_allowed :' - ')} ( Maximum number of accounts included in your subscription)</p>
                            <p>  Positions  : {(user_data && user_data.current_positions_count ? user_data.current_positions_count :' NA')}  / {(user_data && user_data.max_positions_allowed ? user_data.max_positions_allowed :1)} ( Maximum number of positions included in your subscription)</p>
                            <p>  Users  :{(user_data && user_data.current_users_count ? user_data.current_users_count :' NA')}  / {(user_data && user_data.max_users_allowed ? user_data.max_users_allowed :1)} ( Maximum number of Users included in your subscription)</p>
                        
                        </Col>
                        <Col xl={8} sm={8}>
                            <Card >

                                <CardBody>
                                    <form className="user">
                                        <Row className="mb-3">
                                            <label >First Name</label>
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={firstName}
                                                onChange={e => setFirstName(e.target.value)}
                                            />

                                        </Row>
                                        <Row className="mb-3">
                                            <label >Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={lastName}

                                                onChange={e => setLastName(e.target.value)}
                                            />
                                        </Row>
                                        <Row className="mb-3">
                                            <label>Tel </label>
                                            <input
                                                type="tel"
                                                className="form-control "
                                                value={tel}
                                                onChange={e => setTel(e.target.value)}
                                            />

                                        </Row>
                                        <Row className="mb-3">
                                            <label>Your reference currency</label>
                                            <Select
                                                menuPortalTarget={document.body}
                                                menuPosition={'fixed'}
                                                value={availableCurrency.find(option => option.value === currency)}
                                                onChange={selectedOption => setCurrency(selectedOption.value)}
                                                placeholder="Select Currency"
                                                options={availableCurrency}
                                                classNamePrefix="select2-selection"
                                                styles={MoondanceLayout.select2customStyles}
                                          />
                                            <p id="signup_form_curency-error" className="error mt-2 text-danger"></p>
                                        </Row>
                                        <Row className="mb-3">
                                            <label>Multi factor authentication</label>
                                            <select
                                                className="form-control "
                                                value={mfaMethod}
                                                onChange={e => changeMfaMethod(e.target.value)}
                                            >
                                                {availableAuthMethod.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>

                                        </Row>
                                        <Row>
                                            {(mfaMethod === 'EMAIL' ? <p className="text-muted">A confirmation code will be sent to your email each time you try to login from an unknown device</p> : "")}
                                            {((mfaMethod === 0 || mfaMethod === '0') ? <p className="alert alert-danger text-danger">For your safety, we highly recommend choosing some form of muti factor authentication (MFA) to keep your account more secure. Please choose an MFA method to double-protect your account.</p> : "")}

                                        </Row>

                                        <Modal isOpen={totpModalisOpen} >
                                            <div className="modal-header">
                                                <h5>Setting up Time-based One-Time Password</h5>
                                                <button
                                                    type="button"
                                                    onClick={closeTotpModal}
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            {(totpVerified ?

                                                <div className="modal-body">
                                                    <p>Your account is now secured using  Time-based One-Time Password  !</p>
                                                </div>

                                                :
                                                <div className="modal-body">
                                                    <p>A Time-based One-Time Password (TOTP) is an algorithm that generates a temporary one-time password, typically valid for 30 seconds. Using TOTP enhances the security of your accounts.</p>
                                                    <p><strong>Follow these steps to secure your account:</strong></p>
                                                    <ol> {/* Ordered list for step-by-step instructions */}
                                                        <li><strong>Download the Google Authenticator app</strong> (or another TOTP-compatible app) from your device's app store.</li>
                                                        <li><strong>Scan the QR code</strong> provided below.</li>
                                                        <div style={{ textAlign: 'center', margin: '1em' }}> {/* Centers and adds margin to the QR code */}
                                                            <QRCode value={totpSecret} />
                                                        </div>
                                                        <li><strong>Enter the 6-digit code</strong> generated by the Google Authenticator app into the service's verification field to complete the setup.
                                                            <input
                                                                type="number"
                                                                className="form-control "
                                                                value={totpCode}
                                                                onChange={e => { setTotpCode(e.target.value); setTotpError(false) }}
                                                            /></li>
                                                        {(totpCode.length == 6 ? <li>
                                                            < Button style={{ margin: '1em' }} className="btn btn-success" onClick={() => { validateTotpSetup() }}>Click here to validate your TOTP setup</Button>

                                                        </li> : '')}
                                                        {(totpError ? <p className="alert alert-danger text-danger">The code you entered is not valid. Please try again.</p> : "")}


                                                    </ol>
                                                </div>

                                            )}
                                            <div className="modal-footer">

                                                <button
                                                    type="button"
                                                    onClick={closeTotpModal}
                                                    className="btn btn-secondary "
                                                    data-dismiss="modal"
                                                >
                                                    Close
                                                </button>

                                            </div>

                                        </Modal>

                                        <Row className="mb-3">
                                            <label >Company</label>
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={company}
                                                onChange={e => setCompany(e.target.value)}
                                            />

                                            <p id="signup_form_company-error" className="error mt-2 text-danger"></p>
                                        </Row>
                                        <Row className="mb-3">
                                            <label >VAT number</label>
                                            <input
                                                type="text"
                                                className="form-control "
                                                value={vat}

                                                onChange={e => setVat(e.target.value)}
                                            />

                                            <p id="signup_form_vat-error" className="error mt-2 text-danger"></p>
                                        </Row><hr />
                                        <h4>Your Accounts</h4>
                                        <Row className="mb-3" >

                                            <Col xl={6} sm={6}>Account Name</Col>
                                            <Col xl={6} sm={6}>Displayed by default</Col>
                                        </Row>

                                        {accounts ?
                                            accounts.map((account, index) => (
                                                <Row className="mb-3" >
                                                    <Col xl={6} sm={6}>

                                                        <label >{account.n}</label>


                                                    </Col>
                                                    <Col xl={2} sm={2}>
                                                        <Switch
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            className="me-1 mb-sm-8 mb-2"
                                                            onColor="#02a499"
                                                            onChange={() => {
                                                                const updatedSelection = { ...accountSelected };
                                                                updatedSelection[account.id] = !accountSelected[account.id];
                                                                changeSelectedAccount(account.id, !accountSelected[account.id])
                                                                setAccountSelected(updatedSelection);
                                                            }}
                                                            checked={(accountSelected[account.id] ? accountSelected[account.id] : 0)} />

                                                    </Col>

                                                </Row>

                                            )) : ""}






                                        <Row className="mb-3">
                                            <Button className="btn btn-success btn-user btn-block "
                                                onClick={() => { submitForm() }} >Save profile
                                            </Button>
                                            {submitResult && submitResult.success && (<label className="error mt-2 text-success"><b>Profile updated !</b></label>)}

                                        </Row>



                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >




    )

}
/*
 <Row className="mb-3">
                                            <label for="email">Email</label>
                                            <input type="email" className="form-control "
                                                id="signup_form_email" required />
 
                                            <p id="signup_form_email-error" className="error mt-2 text-danger"></p>
                                        </Row>
                                        <Row className="mb-3">
 
                                            <label for="email">Choose a password</label>
                                            <input type="password" className="form-control "
                                                id="signup_form_password" placeholder="Password" />
 
                                            <p id="signup_form_password-error" className="error mt-2 text-danger"></p>
                                        </Row>
                                        <Row className="mb-3">
 
                                            <label for="email">Repeat password</label>
                                            <input type="password" className="form-control "
                                                id="signup_form_confirm_password" placeholder="Password" />
 
                                            <p id="signup_form_confirm_password-error" className="error mt-2 text-danger"></p>
                                        </Row>
                                        <Row className="mb-3">
 
                                            <label for="email">Multiple factor authentication</label>
                                            <select className="form-control" id="signup_form_MFA">
                                                <option value="null">No Double factor authentication (NOT Recommended)</option>
                                                <option value="sms">Send a confirmation code by SMS each time I try to login from an unknown device</option>
                                                <option value="email">Send a confirmation code by EMAIL each time I try to login from an unknown device</option>
                                            </select>
                                        </Row>
*/

export default UserProfile;