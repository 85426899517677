import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";

import logoDark from "../../assets/images/moondance_logo.png";


const TopBar = () => {

    return (
        <React.Fragment >
            <header id="home-topbar" >
                <nav className="navbar navbar-expand-lg ">
                    <div className="container-fluid">
                        <span className="logo-sm">
                        <Link to="/home"><img src={logoDark} alt="logo-sm-dark" height="60" /></Link> 
                        </span>
                       
                        <div className="d-flex justify-content-end " style={{ 'paddingTop': '0.5em' }}>
                            
                            <Link style={{ 'marginRight': '0.5em' }} className="btn btn-sm btn-outline-primary" to="/register" >Sign In</Link>

                            <Link  style={{ 'marginRight': '0.5em' }} className="btn btn-sm btn-primary" to="/login" >Login</Link>
           
                </div>
                    </div>
                </nav>
            </header>
           
        </React.Fragment>
    )
}


export default TopBar;