import React, { useEffect, useState } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";
import TopBar from '../publicWebsite/topbar.js';
import Carousel from '../publicWebsite/carousel.js';
import Contact from '../publicWebsite/contactForm.js';



import img1 from "../../assets/images/public/jcurve.png";
import video from "../../assets/images/company_intro_v1_1.mp4";
import { Link } from "react-router-dom";


const PublicWebsite = () => {


    useEffect(() => {
        document.body.className = "homePageBody";
    });


    return (

        <React.Fragment>
            <TopBar />
            <div id="homeBanner" >
                <Container id="homeBannerContainer" className="container-lg homeContainer" >

                    <Row>
                        <h1> Moondance</h1><br />
                        <h2>Simplifying Private Equity Management</h2><br/>
                        <p>MoonDance offers a streamlined approach for managing Private Equity Funds, designed to support investors and family offices. Providing practical tools for tracking investments, calculating metrics, and forecasting outcomes efficiently.</p>
                    </Row>
                    
                </Container>
            </div>

            <div  style={{textAlign:"center",}}>
            <Container id="homeVideo" className="container-lg homeContainer" >
            <video controls style={{maxWidth:'100%'}}>
                <source src={video} type="video/mp4" />
            </video>
            </Container>
            </div>

            
           
           

            <div id="HomeSecondaryContent" >
            <Container id="HomeSecondaryContentContainer" className="container-lg homeContainer" >

                <h1>Elevate Your Private Equity Investment Management</h1>
                <ul>
                    <li>Seamlessly Monitor Your Private Equity Portfolios</li>
                    <li>Effortlessly Track Capital Calls and Distributions</li>
                    <li>Aggregate Critical Performance Metrics by Fund or Account</li>
                    <li>Simulate Fund Cashflows with Custom Assumptions</li>
                    <li>Forecast the Impact of Future Commitments on Cash Flows</li>
                    <li>Enhance Family Office Operations with Streamlined Efficiency</li>
                    <li>Record Transactions and Net Asset Value Across Customer Accounts    </li>

                </ul>
                <h3>Empower Your Investment Journey with Moondance</h3>
           
            </Container>
           
            </div>
                

            <div  className="page-content publicHomePage" >

                <div className="homePageContactForm">
                    <Container className="container-lg" >

                        <Contact />
                    </Container>
                </div>
            </div>
            <div id="homeFooter" >
                @2024 Made in Belgium, All Rights Reserved  COGESPAR SA <Link to="/generalSalesConditions">General Sales Conditions</Link>
            </div>
           
        </React.Fragment>
    );

}


export default PublicWebsite;
