
import React, { useState, useContext, useEffect } from "react";

import * as XLSX from 'xlsx';

import {
  Card, CardBody, CardTitle, Col, Container, Button, Nav, Table,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown, UncontrolledAlert
} from "reactstrap";


import Variables from "../lib/variables/variables.js";
import Format from '../lib/format.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
//###############################################################################################################

const MoondanceLayout = {

  number_format: 'fr-FR',
  is_dark_mode: false,
  userCurrency: 'EUR',


  //###############################################################################################################
  //backward compatibility with funcitions moved to format.js
  formatDate: Format.formatDate,
  formatCurrency: Format.formatCurrency,
  formatPercentage: Format.formatPercentage,
  formatNumber: Format.formatNumber,
  capitalizeFirstLetter: Format.capitalizeFirstLetter,
  formatDate_DMY_to_YMD: Format.formatDate_DMY_to_YMD,
  formatValue: Format.formatValue,
  customSelectPattern: Format.customSelectPattern,
  select2customStyles: Format.select2customStyles,
  findMaxMin: Format.findMaxMin,


  //###############################################################################################################
  //backward compatibility with  variables moved to variables.js
  statusList: Variables.statusList,
  currencyList: Variables.currencyList,
  sectorList: Variables.sectorList,
  geographyList: Variables.geographyList,
  assetType: Variables.assetType,
  transactionType: Variables.transactionType,
  dark_colorScheme: Variables.dark_colorScheme,
  light_colorScheme: Variables.light_colorScheme,
  colorScheme: Variables.colorScheme,
  //###############################################################################################################

  getGeographyList() {

    this.geographyList.sort(function (a, b) {
      var labelA = a.label.toUpperCase();
      var labelB = b.label.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
    return this.geographyList
  },



  displayArrayLabels(arrayOfitems) {
    if (!Array.isArray(arrayOfitems) || arrayOfitems.length === 0) {
      return 'No data'
    }
    let returnValue = '';
    arrayOfitems.forEach(item => {
      returnValue += item.label + ', ';
    });
    return returnValue.slice(0, -2);
  },

  switchDarkMode(value) {
    this.is_dark_mode = value;
    Variables.colorScheme = value ? this.dark_colorScheme : this.light_colorScheme;
    if (this.is_dark_mode) {
      localStorage.setItem("darkmode", true);
      document.body.style.backgroundColor = '#202124';
      var rootElement = document.getElementById('root');
      if (rootElement) {
        rootElement.style.backgroundColor = '#202124';
      }
    } else {

      localStorage.setItem("darkmode", false);
      document.body.style.backgroundColor = '#F3F3F4';
      var rootElement = document.getElementById('root');
      if (rootElement) {
        rootElement.style.backgroundColor = '#F3F3F4';
      }

    }

  },
  currencyShort: {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
    'CAD': 'CAD',
    'NOK': 'NOK',
    'CHF': 'CHF'


  },





  testurl(needle, haystack) {
    const regex = new RegExp(needle);
    return regex.test(haystack);
  },

  alertBlock(title, body, type = "warning") {

    return (
      <div style={{ marginBottom: "2em" }}>
        <UncontrolledAlert color="light" role="alert" className="card border p-0 mb-0">
          <div className={`card-header bg-soft-${type} `}>
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5 className={`font-size-16  my-1 `}><i className="mdi mdi-alert-outline"></i>&nbsp;{title}
                </h5>
              </div>
              <div className="flex-shrink-0">

              </div>
            </div>
          </div>

          <CardBody>{body}
          </CardBody>
        </UncontrolledAlert>
      </div>
    )

  },


  currentYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear
  },
  buildAssetTypeSelectOptions() {

    const assetTypeArray = Object.keys(this.assetType).map((key) => {
      const { label, description } = this.assetType[key];
      return { value: key, label, description };
    });
    //console.log(assetTypeArray)
    return assetTypeArray

  },
  getTransactionTypeLabel(searchId) {
    var matchingRowthisTransactionType = MoondanceLayout.transactionType.find(item => item.value === searchId);
    if (matchingRowthisTransactionType) {
      return matchingRowthisTransactionType.label;
    } else {
      return searchId
    }
  },
  currencySymbol(currency) {
    if (this.currencyShort[currency]) {
      return this.currencyShort[currency]
    } else {
      return currency
    }

  },

  card(props) {

    return (

      <Col xl={(props.xlWidth ? props.xlWidth : 3)} sm={(props.smWidth ? props.smWidth : 3)} md={(props.mdWidth ? props.mdWidth : 4)} xs={(props.xsWidth ? props.xsWidth : 6)}>
        <Card className="equal-height-card"  >

          {(props.cardBody ? <CardBody>{props.cardBody}</CardBody> :

            <CardBody>
              {(props.cardTitle ? <CardTitle>{props.cardTitle}</CardTitle> : '')}

              {((props.title || props.value || props.value_sub || props.textprops || props.subText) ?
                <div className="d-flex text-muted">
                  <div className="flex-grow-1 overflow-hidden">

                    {(props.title ? <p className="mb-1">{props.title}</p> : '')}
                    {(props.value ? <><h5 className="mb-3">{props.value}</h5>
                      {(props.value_sub ?
                        <h6 className="text-muted">{(props.value_sub ? props.value_sub : '')}</h6>
                        : ''
                      )}</>
                      : '')}
                    {(props.text ?
                      <p className="mb-0">{(props.text ? props.text : '')}<br /></p>
                      : '')}
                    {(props.subText ?
                      <p className=" mb-0">{(props.subText ? props.subText : '')}<br /></p>
                      : '')}

                  </div>
                </div> : '')}

            </CardBody>
          )}
        </Card>

      </Col>
    )

  },
  insideItem(props) {

    return (


      <Col xl={(props.xlWidth ? props.xlWidth : 3)} sm={(props.smWidth ? props.smWidth : 3)}>
        <div className="d-flex text-muted">
          <div className="flex-grow-1 overflow-hidden">

            {(props.title ? <p className="mb-1">{props.title}</p> : '')}
            {(props.value ? <>
              <h5 className="mb-3">{props.value}</h5> {(props.value_sub ?
                <h6 className="text-muted">{(props.value_sub ? props.value_sub : '')}</h6>
                : ''
              )}</> : '')}
            {(props.text ?
              <p className="mb-0">{(props.text ? props.text : '')}<br /></p>
              : '')}
            {(props.subText ?
              <p className=" mb-0">{(props.subText ? props.subText : '')}<br /></p>
              : '')}

          </div>
        </div>

      </Col >
    )

  },
  progressBar(props) {

    return (
      <div className="d-flex">
        {(props.icon ?
          <div className="avatar-xs align-self-center me-3">
            <div className="avatar-title rounded-circle bg-light  font-size-18">
              <span className={props.icon}></span>
            </div>
          </div>
          : '')}
        <div className="flex-grow-1">
          <Row>
            <Col xl={8}><p className="text-muted mb-2"><span>{props.title}</span></p></Col>
            <Col xl={4}><p className="text-muted mb-2 text-end"><span>{props.value} </span></p></Col>
          </Row>


          {(props.custom_progressBar ? <div>{props.custom_progressBar}</div> : '')}

          {(props.value_pct ?
            <div>
              <ProgressBar
                variant={(props.style ? props.style : "info")}
                min={(props.min ? props.min : 0)}
                max={(props.max ? props.max : 100)}
                now={parseInt(props.value_pct)}
                label={(props.label ? props.label : props.value_pct + "%")}
              />
            </div>
            : '')}
          <Row>
            <Col xl={8}><p className="text-muted mb-2" style={{ marginTop: "8px" }}><span>{(props.subText ? props.subText : '')}</span></p></Col>
            <Col xl={4}><p className="text-muted mb-2 text-end" style={{ marginTop: "8px" }}><span>{(props.value_sub ? props.value_sub : '')}</span></p></Col>
          </Row>

        </div>
      </div>

    )



  },
  buildCashFlowChart(data, cashflowChartDisplay, showCapitalCallsOnly, showBudget = false, scale = "cash") {
    //console.log('buildCashFlowChart', data)
    var returnData = {}
    returnData.rawdata = [];
    returnData.rawdata['rcc'] = [];
    returnData.rawdata['rd'] = [];
    returnData.rawdata['b'] = [];
    returnData.rawdata['budget_cc'] = [];
    returnData.rawdata['budget_d'] = [];
    returnData.rawdata['budget_b'] = [];
    returnData.rawdata['mcc'] = [];
    returnData.rawdata['md'] = [];
    returnData.categories = [];
    var budgetData = []
    //console.log('buildCashFlowChart', showBudget, data.budget)

    if (showBudget && data.budget) {
      //console.log('buildCashFlowChart', data.budget)
      budgetData = MoondanceLayout.calculateBudget(data.budget)
    }

    //remove trailing empty values
    while (data.perYear.length > 0) {
      let lastItem = data.perYear[data.perYear.length - 1];
      if (lastItem.sum_real_capital_call_in_user_currency === 0 &&
        lastItem.sum_model_capital_call_in_user_currency === 0 &&
        lastItem.sum_model_distribution_in_user_currency === 0 &&
        lastItem.sum_real_distribution_in_user_currency === 0) {
        data.perYear.pop(); // Remove last item
      } else {
        break; // Break out of the loop once a non-zero item is found
      }
    }
    let coef = 1;
    if (scale === "pct_commited") {
      coef = 1 / data.summary.position_commitment_in_;
    }

    data.perYear.map((item, index) => {
      //console.log('buildCashFlowChart', item)
      let rcc = item.sum_real_capital_call_in_user_currency;
      let rd = item.sum_real_distribution_in_user_currency;
      let mcc = item.sum_model_capital_call_in_user_currency;
      let smd = item.sum_model_distribution_in_user_currency;
      let srcc = item.sum_remaining_capital_call_in_user_currency
      let srd = item.sum_remaining_distribution_in_user_currency

      if (scale === "cash_asset_currency") {

        rcc = item.sum_real_capital_call_in_asset_currency;
        rd = item.sum_real_distribution_in_asset_currency;
        mcc = item.sum_model_capital_call_in_asset_currency;
        smd = item.sum_model_distribution_in_asset_currency;
        srcc = item.sum_remaining_capital_call_in_asset_currency
        srd = item.sum_remaining_distribution_in_asset_currency

      } else if (scale === "pct_commited") {
        rcc = item.sum_real_capital_call_in_asset_currency / data.summary.position_commitment_in_asset_currency;
        rd = item.sum_real_distribution_in_asset_currency / data.summary.position_commitment_in_asset_currency;
        mcc = item.sum_model_capital_call_in_asset_currency / data.summary.position_commitment_in_asset_currency;
        smd = item.sum_model_distribution_in_asset_currency / data.summary.position_commitment_in_asset_currency;
        srcc = item.sum_remaining_capital_call_in_asset_currency / data.summary.position_commitment_in_asset_currency
        srd = item.sum_remaining_distribution_in_asset_currency / data.summary.position_commitment_in_asset_currency
      } else if (scale === "pct_called") {
        rcc = item.sum_real_capital_call_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency;
        rd = item.sum_real_distribution_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency;
        mcc = item.sum_model_capital_call_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency;
        smd = item.sum_model_distribution_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency;
        srcc = item.sum_remaining_capital_call_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency
        srd = item.sum_remaining_distribution_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency

      }

      if (item.year > 0 && item.year < new Date().getFullYear()) {

        if (cashflowChartDisplay !== 'Future') {
          returnData.categories.push(item.year.toString())
          returnData.rawdata['rcc'].push(rcc)
          returnData.rawdata['rd'].push(rd)
          returnData.rawdata['mcc'].push(0)
          returnData.rawdata['md'].push(0)
          returnData.rawdata['b'].push(rcc + rd)

          returnData.rawdata['budget_cc'].push(0)
          returnData.rawdata['budget_d'].push(0)

        }

      } else if (item.year > new Date().getFullYear()) {

        if (cashflowChartDisplay !== 'Past') {

          returnData.categories.push(item.year.toString())
          returnData.rawdata['rcc'].push(0)
          returnData.rawdata['rd'].push(0)

          returnData.rawdata['mcc'].push(mcc)
          returnData.rawdata['md'].push((smd > 0 ? smd : 0))
          returnData.rawdata['b'].push(mcc + smd)

          if (budgetData && budgetData.distributions && budgetData.distributions[item.year]) {
            returnData.rawdata['budget_d'].push(budgetData.distributions[item.year])
          } else {
            returnData.rawdata['budget_d'].push(0)
          }
          if (budgetData && budgetData.capitalCalls && budgetData.capitalCalls[item.year]) {
            returnData.rawdata['budget_cc'].push(-budgetData.capitalCalls[item.year])
          } else {
            returnData.rawdata['budget_cc'].push(0)
          }

        }
      } else if (item.year === new Date().getFullYear()) {

        returnData.categories.push(item.year.toString())
        if (cashflowChartDisplay === 'Future') {

          returnData.rawdata['rcc'].push(0)
          returnData.rawdata['rd'].push(0)

          returnData.rawdata['mcc'].push((mcc - rcc < 0 ? mcc - rcc : 0))
          returnData.rawdata['md'].push((smd - rd > 0 ? smd - rd : 0))
          returnData.rawdata['b'].push(
            (-mcc > -rcc  ?  mcc - rcc  : 0)
            + (smd > rd ? smd - rd : 0)
          )
          if (budgetData && budgetData.distributions && budgetData.distributions[item.year]) {
            returnData.rawdata['budget_d'].push(budgetData.distributions[item.year])
          } else {
            returnData.rawdata['budget_d'].push(0)
          }
          if (budgetData && budgetData.capitalCalls && budgetData.capitalCalls[item.year]) {
            returnData.rawdata['budget_cc'].push(-budgetData.capitalCalls[item.year])
          } else {
            returnData.rawdata['budget_cc'].push(0)
          }
          //console.log('returnData.rawdata', returnData.rawdata)
        } else if (cashflowChartDisplay === 'Past') {

          returnData.rawdata['rcc'].push(rcc)
          returnData.rawdata['rd'].push(rd)
          returnData.rawdata['b'].push(rcc + rd)

          returnData.rawdata['mcc'].push(0)
          returnData.rawdata['md'].push(0)

        } else if (cashflowChartDisplay === 'All') {

          returnData.rawdata['rcc'].push(rcc)
          returnData.rawdata['rd'].push(rd)

          returnData.rawdata['mcc'].push((mcc - rcc < 0 ? mcc - rcc : 0))
          returnData.rawdata['md'].push((smd - rd > 0 ? smd - rd : 0))
       
          returnData.rawdata['b'].push(
            (-mcc > -rcc  ?  mcc   : rcc)
            + (smd > rd ? smd  : rd)
          )
        


        }
      }
    })
    //colmplete serie with budget
    if (cashflowChartDisplay !== 'Past' && showBudget && budgetData && budgetData.distributions && budgetData.distributions.length > 0) {
      budgetData.distributions.map((item, index) => {

        if (!returnData.categories.find((element) => element === index.toString())) {
          returnData.rawdata['budget_d'].push(item);
          returnData.rawdata['budget_cc'].push((budgetData.capitalCalls[index] ? -budgetData.capitalCalls[index] : 0));
          returnData.rawdata['rcc'].push(0);
          returnData.rawdata['rd'].push(0);
          returnData.rawdata['mcc'].push(0);
          returnData.rawdata['md'].push(0);

          returnData.categories.push(index.toString())
        }
      })

      returnData.rawdata['budget_d'].map((item, index) => {
        ////console.log('returnData.rawdata[budget_b]', index, returnData.rawdata['b'][index], returnData.rawdata['budget_b'][index])
        let full_balance = (returnData.rawdata['b'][index] ? returnData.rawdata['b'][index] : 0) + (returnData.rawdata['budget_d'][index] ? returnData.rawdata['budget_d'][index] : 0) + (returnData.rawdata['budget_cc'][index] ? returnData.rawdata['budget_cc'][index] : 0)
        returnData.rawdata['budget_b'].push(full_balance);

      })


    }

    returnData.data = []

    let backgroundColors = []
    let strokeWith = []
    let strokeColors = []

    if (!showBudget) {
      if (cashflowChartDisplay === 'Future') {
        backgroundColors.push(Variables.colorScheme.expected_neutral)
        backgroundColors.push(Variables.colorScheme.expected_neutral)
        backgroundColors.push(Variables.colorScheme.dark)
        strokeWith = [0, 0, 0]
        strokeColors = [Variables.colorScheme.expected_neutral, Variables.colorScheme.expected_neutral, Variables.colorScheme.dark]

      } else if (cashflowChartDisplay === 'Past') {
        backgroundColors.push(Variables.colorScheme.danger)
        backgroundColors.push(Variables.colorScheme.success)
        backgroundColors.push(Variables.colorScheme.dark)
        strokeColors = [Variables.colorScheme.danger, Variables.colorScheme.success, Variables.colorScheme.dark]
        strokeWith = [0, 0, 0]
      } else if (!showBudget) {
        backgroundColors.push(Variables.colorScheme.danger)
        backgroundColors.push(Variables.colorScheme.success)
        backgroundColors.push(Variables.colorScheme.expected_neutral)
        backgroundColors.push(Variables.colorScheme.expected_neutral)
        backgroundColors.push(Variables.colorScheme.dark)
        strokeColors = [Variables.colorScheme.danger, Variables.colorScheme.success, Variables.colorScheme.expected_neutral, Variables.colorScheme.expected_neutral, Variables.colorScheme.dark]
        strokeWith = [0, 0, 0, 0, 1]
      }
    } else {
      //console.log('showBudget', returnData.rawdata['budget_d'])
      // replace Balance (add budget)
      let new_balance = []
      returnData.categories.map((item, index) => {
        new_balance.push((returnData.rawdata['b'][index] ? returnData.rawdata['b'][index] : 0) + returnData.rawdata['budget_d'][index] + returnData.rawdata['budget_cc'][index])
      })
      returnData.rawdata['b'] = new_balance


    }

    if (cashflowChartDisplay !== 'Future') {
      returnData.data.push(
        {
          name: 'Actual Capital Call',
          type: 'column',
          data: returnData.rawdata['rcc'],

        },
        {
          name: 'Actual distribution',
          type: 'column',
          data: returnData.rawdata['rd']
        })
    }
    if (cashflowChartDisplay !== 'Past') {
      returnData.data.push({
        name: 'Expected Capital Call',
        type: 'column',
        data: returnData.rawdata['mcc'],
      },

        {
          name: 'Expected distribution',
          type: 'column',
          data: returnData.rawdata['md']
        },

      )

      if (showBudget) {
        returnData.data.push({
          name: 'Budget Capital Call',
          type: 'column',
          data: returnData.rawdata['budget_cc'],
        },

          {
            name: 'Budget distribution',
            type: 'column',
            data: returnData.rawdata['budget_d']
          },

        )

        backgroundColors.push(Variables.colorScheme.expected_neutral)
        backgroundColors.push(Variables.colorScheme.expected_neutral)
        backgroundColors.push(Variables.colorScheme.main_backgroundColor)
        backgroundColors.push(Variables.colorScheme.main_backgroundColor)
        backgroundColors.push(Variables.colorScheme.dark)
        strokeWith = [1, 1, 1, 1, 1]
        strokeColors = [Variables.colorScheme.expected_neutral, Variables.colorScheme.expected_neutral, Variables.colorScheme.info, Variables.colorScheme.info,]

      }
    }
    //returnData.data.push({ name: 'Balance', type: 'column', data: returnData.rawdata['b'] });


    returnData.options = {
      colors: backgroundColors

      ,
      xaxis: {
        type: 'category',
        categories: returnData.categories,
        convertedCatToNumeric: false,

      },
      markers: {
        size: 4,
        strokeWidth: 1,
        colors: Variables.colorScheme.dark
      },

      stroke: {
        curve: 'smooth',

        colors: strokeColors,
        width: strokeWith
      }
    };

    returnData.options.annotations = {
      yaxis: [
        {
          y: 0,
          borderColor: Variables.colorScheme.dark,
        }
      ],
      points:
        returnData.categories.map(function (item, index) {
          return {
            id: 'balance' + index,
            x: returnData.categories[index],
            y: returnData.rawdata['b'][index],
            marker: {
              size: 3,
              style: 'rectangle',
              fillColor: Variables.colorScheme.dark,
            },
            label: {

              text: (scale === "cash" || scale === "cash_asset_currency" ? Math.round(returnData.rawdata['b'][index] / 1000) + ' k ' + (scale === "cash" ? MoondanceLayout.userCurrency : data.summary.asset_currency) : MoondanceLayout.formatPercentage(returnData.rawdata['b'][index])),
            }



          }
        })
    };
    //console.log('returnData annotations',showBudget, returnData.rawdata['b'],returnData.options.annotations)
    //console.log('returnData',returnData)
    return returnData
  },
  buildCumulatedCashFlowChart(data, cashflowChartDisplay, showCapitalCallsOnly = false, showBudget = false, scale = "cash") {
    var returnData = {}
    returnData.categories = [];

    returnData = {}

    returnData.categories = [];
    returnData.rawdata = [];
    returnData.rawdata['real'] = [];
    returnData.rawdata['expected'] = [];
    returnData.rawdata['real_aggr'] = [];
    returnData.rawdata['rb'] = [];

    returnData.rawdata['budget_balance'] = [];
    var cumulatedCall = 0;
    var cumulatedDist = 0;
    var cumulBudget = 0;
    var budgetData = []

    //console.log('scale', scale)

    if (showBudget && data.budget) {
      budgetData = MoondanceLayout.calculateBudget(data.budget)
      //console.log('budgetData', budgetData)
    }

    //remove trailing empty values
    while (data.perYear.length > 0) {
      let lastItem = data.perYear[data.perYear.length - 1];
      if (lastItem.sum_real_capital_call_in_user_currency === 0 &&
        lastItem.sum_model_capital_call_in_user_currency === 0 &&
        lastItem.sum_model_distribution_in_user_currency === 0 &&
        lastItem.sum_real_distribution_in_user_currency === 0) {
        data.perYear.pop(); // Remove last item
      } else {
        break; // Break out of the loop once a non-zero item is found
      }
    }
    data.perYear.map((item, index) => {

      let rcc = item.sum_real_capital_call_in_user_currency;
      let rd = item.sum_real_distribution_in_user_currency;
      let mcc = item.sum_model_capital_call_in_user_currency;
      let smd = item.sum_model_distribution_in_user_currency;
      let srcc = item.sum_real_capital_call_in_user_currency
      let srd = item.sum_real_distribution_in_user_currency
      let srb = item.sum_remaining_balance_in_user_currency


      if (scale === "cash_asset_currency") {

        rcc = item.sum_real_capital_call_in_asset_currency;
        rd = item.sum_real_distribution_in_asset_currency;
        mcc = item.sum_model_capital_call_in_asset_currency;
        smd = item.sum_model_distribution_in_asset_currency;
        srcc = item.sum_real_capital_call_in_asset_currency
        srd = item.sum_real_distribution_in_asset_currency
        srb = item.sum_remaining_balance_in_asset_currency

      } else if (scale === "pct_commited") {
        rcc = item.sum_real_capital_call_in_asset_currency / data.summary.position_commitment_in_asset_currency;
        rd = item.sum_real_distribution_in_asset_currency / data.summary.position_commitment_in_asset_currency;
        mcc = item.sum_model_capital_call_in_asset_currency / data.summary.position_commitment_in_asset_currency;
        smd = item.sum_model_distribution_in_asset_currency / data.summary.position_commitment_in_asset_currency;
        srcc = item.sum_real_capital_call_in_asset_currency / data.summary.position_commitment_in_asset_currency
        srd = item.sum_real_distribution_in_asset_currency / data.summary.position_commitment_in_asset_currency
        srb = item.sum_remaining_balance_in_asset_currency / data.summary.position_commitment_in_asset_currency
      } else if (scale === "pct_called") {

        rcc = item.sum_real_capital_call_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency;
        rd = item.sum_real_distribution_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency;
        mcc = item.sum_model_capital_call_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency;
        smd = item.sum_model_distribution_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency;
        srcc = item.sum_real_capital_call_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency
        srd = item.sum_real_distribution_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency
        srb = item.sum_remaining_balance_in_asset_currency / -data.summary.position_real_capital_call_in_asset_currency
      }


      if (item.year > 0 && item.year < new Date().getFullYear()) {
        if (cashflowChartDisplay !== 'Future') {
          returnData.categories.push(item.year.toString())
          cumulatedCall = cumulatedCall + srcc
          cumulatedDist = cumulatedDist + srd
          returnData.rawdata['real'].push(srcc + srd)
          returnData.rawdata['expected'].push(0)
          returnData.rawdata['budget_balance'].push(0)
        }

      } else if (item.year > new Date().getFullYear()) {


        if (cashflowChartDisplay === 'Future' && showCapitalCallsOnly) {

          returnData.categories.push(item.year.toString())
          cumulatedCall = cumulatedCall + srcc + mcc
          cumulatedDist = 0

          returnData.rawdata['real'].push(0)
          returnData.rawdata['expected'].push(mcc)


        } else if (cashflowChartDisplay !== 'Past') {

          returnData.categories.push(item.year.toString())
          cumulatedCall = cumulatedCall + srcc + mcc
          cumulatedDist = cumulatedDist + srd + smd
          if (showBudget && budgetData && budgetData.balance && budgetData.balance[item.year]) {
            cumulatedDist += budgetData.balance[item.year]
          }

          returnData.rawdata['real'].push(0)

          returnData.rawdata['expected'].push(
            mcc + smd
          )


        }
        if (cashflowChartDisplay !== 'Past' && showBudget && budgetData && budgetData.balance && budgetData.balance.length > 0) {
          returnData.rawdata['budget_balance'].push((budgetData.balance[item.year] ? budgetData.balance[item.year] : 0))
        }


      } else if (item.year === new Date().getFullYear()) {

        returnData.categories.push(item.year.toString())
        if (cashflowChartDisplay === 'Future') {
          cumulatedCall = cumulatedCall + srb

          if (showCapitalCallsOnly) {
            cumulatedDist = 0
            returnData.rawdata['expected'].push(srcc)

          } else {
            cumulatedDist = cumulatedDist + srb
            returnData.rawdata['expected'].push(srb)
            if (cashflowChartDisplay !== 'Past' && showBudget && budgetData && budgetData.balance && budgetData.balance.length > 0) {
              returnData.rawdata['budget_balance'].push((budgetData.balance[item.year] ? budgetData.balance[item.year] : 0))
            }
          }
          /*    returnData.rawdata['budget_cc'].push(-budgetData.capitalCalls[item.year])
                    } else {
                      returnData.rawdata['budget_cc'].push(0)
                    }*/


        } else if (cashflowChartDisplay === 'Past') {
          cumulatedCall = cumulatedCall + srcc
          cumulatedDist = cumulatedDist + srd
          returnData.rawdata['real'].push(srcc + srd)


        } else if (cashflowChartDisplay === 'All') {
          cumulatedCall = cumulatedCall + srcc
          cumulatedDist = cumulatedDist + srd
          returnData.rawdata['expected'].push(srb)
          returnData.rawdata['real'].push(srcc + srd)



          if (cashflowChartDisplay !== 'Past' && showBudget && budgetData && budgetData.balance && budgetData.balance.length > 0) {
            returnData.rawdata['budget_balance'].push((budgetData.balance[item.year] ? budgetData.balance[item.year] : 0))
          }
        }


      }

    })

    //console.log('budgetData', returnData.rawdata['budget_balance'])
    if (showBudget && budgetData && budgetData.balance && budgetData.balance.length > 0) {
      budgetData.balance.map((item, index) => {

        if (!returnData.categories.find((element) => element === index.toString())) {
          returnData.rawdata['real'].push(0)
          returnData.rawdata['expected'].push(0)

          returnData.rawdata['budget_balance'].push(budgetData.balance[index])
          returnData.categories.push(index.toString())

        }
      })
    }


    //complete serie with budget

    returnData.rawdata['real_aggr'] = []
    returnData.rawdata['budget_aggr'] = []


    //console.log('returnData.categories', returnData.categories)
    if (returnData.categories) {
      returnData.categories.map((item, index) => {

        let agg =
          (returnData.rawdata['real_aggr'][index - 1] ? returnData.rawdata['real_aggr'][index - 1] : 0)
          + (returnData.rawdata['real'][index] ? returnData.rawdata['real'][index] : 0)
          + (returnData.rawdata['expected'][index] ? returnData.rawdata['expected'][index] : 0)

        returnData.rawdata['real_aggr'].push(agg)


        if (showBudget && budgetData && budgetData.balance && budgetData.balance.length > 0) {
          let budget_aggr = (returnData.rawdata['budget_aggr'][index - 1] ? returnData.rawdata['budget_aggr'][index - 1] : 0)
          budget_aggr += (returnData.rawdata['real'][index] ? returnData.rawdata['real'][index] : 0)
          budget_aggr += (returnData.rawdata['expected'][index] ? returnData.rawdata['expected'][index] : 0)
          budget_aggr += (budgetData && budgetData.balance && budgetData.balance[parseInt(item)] ? budgetData.balance[parseInt(item)] : 0)

          returnData.rawdata['budget_aggr'].push(budget_aggr)

        }
      })
    }


    //
    //console.log('budget', budgetData)
    //complete years where therea are no real or expected data
    /*
    if (showBudget && budgetData && budgetData.balance && budgetData.balance.length > 0) {
      budgetData.balance.map((item, index) => {
        if (!returnData.categories.find((element) => element === index.toString())) {
          console.log('BudgetData',item,index)
          returnData.rawdata['budget_balance'].push( parseFloat(budgetData.balance[index]))
          returnData.rawdata['real'].push(0)
          returnData.rawdata['expected'].push(0)
         
          
          returnData.categories.push(index.toString())
        }
      })
  */

    //  console.log('budget cumul', returnData.rawdata['budget'])

    returnData.data = [


      {
        name: 'Actual Annual Cash Flow',
        type: 'column',
        data: returnData.rawdata['real'],

      },
      {
        name: 'Expected Annual Cash Flow',
        type: 'column',
        data: returnData.rawdata['expected'],

      }
    ];


    returnData.data.push(
      {
        name: 'Expected Cumulated Cash Flow',
        type: 'line',
        data: returnData.rawdata['real_aggr']
      });


    if (showBudget && budgetData) {
      returnData.data.push({
        name: 'Budgeted Annual Cash Flow',
        type: 'column',
        data: returnData.rawdata['budget_balance'],

      });
      returnData.data.push({ name: 'Global Cumulated Cashflow', type: 'line', data: returnData.rawdata['budget_aggr'] })
    }
    //console.log('returnData',showBudget, returnData.data)
    returnData.options = {
      colors: [
        Variables.colorScheme.dark,
        Variables.colorScheme.expected_neutral,
        Variables.colorScheme.primary,
        Variables.colorScheme.main_backgroundColor
      ],
      xaxis: {
        type: 'category',
        categories: returnData.categories,
        convertedCatToNumeric: false,

      },
      markers: {
        size: 4,
        strokeWidth: 1,
        colors: [Variables.colorScheme.dark, Variables.colorScheme.primary, Variables.colorScheme.primary],
        offsetX: 0,
      },
      xaxis: {
        type: 'category',
        categories: returnData.categories,
        convertedCatToNumeric: true,

      },
      stroke: {

        curve: 'smooth',
        colors: [
          Variables.colorScheme.actual_neutral,
          Variables.colorScheme.expected_neutral,
          Variables.colorScheme.actual_neutral,
          Variables.colorScheme.info,
          Variables.colorScheme.info,
          Variables.colorScheme.actual_neutral,
          Variables.colorScheme.dark,
        ],
        width: [1, 1, 1, 1, 1]
      },
      tooltip: {
        y: {
          formatter: undefined,
          title: {
            formatter: (seriesName) => seriesName,
          },
        },

      }
    };

    if (cashflowChartDisplay === 'Future') {
      returnData.data.splice(0, 1)
      returnData.options.colors.splice(0, 1)
      returnData.options.stroke.colors.splice(0, 1)
      returnData.options.stroke.width.splice(0, 1)

    }
    if (cashflowChartDisplay === 'Past') {
      returnData.data.splice(1, 1)
      returnData.options.colors.splice(1, 1)
      returnData.options.stroke.colors.splice(1, 1)
      returnData.options.stroke.width.splice(1, 1)
    }
    //console.log('buildCumulatedCashFlowChart', returnData)
    return returnData
  },

  buildOverviewChart(data,currency = false) {
    if (!data.summary) { return false }
      let commitment = 0
      let capitalCall = 0
      let distribution = 0
      let nav = 0
      console.log('currency',currency)

    //Fix for  dashboard values 
    if (data.summary && !data.summary.position_commitment_in_user_currency && data.summary.total_commited_in_user_currency) {
      data.summary.position_commitment_in_user_currency = data.summary.total_commited_in_user_currency
    }
    if (data.summary && !data.summary.position_real_capital_call_in_user_currency && data.summary.total_capital_call_in_user_currency) {
      data.summary.position_real_capital_call_in_user_currency = -data.summary.total_capital_call_in_user_currency
    }
    if (data.summary && !data.summary.position_real_distribution_in_user_currency && data.summary.total_distributed_in_user_currency) {
      data.summary.position_real_distribution_in_user_currency = data.summary.total_distributed_in_user_currency
    }
    if (data.summary && !data.summary.position_nav_in_user_currency_current_rate && data.summary.total_nav_in_user_currency_current_rate) {
      data.summary.position_nav_in_user_currency_current_rate = data.summary.total_nav_in_user_currency_current_rate
    }

    if(currency !=='asset_currency'){
         commitment = data.summary.position_commitment_in_user_currency
         capitalCall = data.summary.position_real_capital_call_in_user_currency
         distribution = data.summary.position_real_distribution_in_user_currency
         nav = data.summary.position_nav_in_user_currency_current_rate
    } else {
       commitment = data.summary.position_commitment_in_asset_currency
       capitalCall = data.summary.position_real_capital_call_in_asset_currency
       distribution = data.summary.position_real_distribution_in_asset_currency
       nav = data.summary.position_nav_in_asset_currency
    }
    var returnData = {}
    returnData.data = [];

console.log('commitment',commitment)
    // -Math.round(data.summary.total_capital_call_in_user_currency/1000,2)


    returnData.data.push(
      {
        x: 'Commitment',
        y: [0, Math.round(commitment / 1000, 2)],
        fillColor: Variables.colorScheme.main_backgroundColor,
        strokeColor: Variables.colorScheme.gray,
        value: Math.round(commitment / 1000, 2),
      }
    );

    returnData.data.push(
      {
        x: 'Cash contributed',
        y: [0, -Math.round(capitalCall / 1000, 2)],
        fillColor: Variables.colorScheme.gray,
        strokeWidth: 0,
        value: -Math.round(capitalCall / 1000, 2),
      }
    );

    returnData.data.push(
      {
        x: 'Gain & Losses',
        y: [
          Math.round(-capitalCall / 1000, 2),
          Math.round((
            -capitalCall
            + distribution
            + nav + capitalCall) / 1000, 2)
        ],

        fillColor: (capitalCall + distribution + nav > 0 ? Variables.colorScheme.success : Variables.colorScheme.danger),
        strokeWidth: 0,
        value: Math.round((capitalCall + distribution + nav) / 1000, 2),
      },
    );

    returnData.data.push(
      {
        x: 'Distributed',
        y: [Math.round((
          - capitalCall
          + nav + capitalCall) / 1000, 2),
        Math.round((
          - capitalCall
          + distribution
          + nav + capitalCall) / 1000, 2)
        ],

        strokeColor: Variables.colorScheme.success,
        fillColor: Variables.colorScheme.main_backgroundColor,
        value: -Math.round(distribution / 1000, 2),
      },
    );

    returnData.data.push(
      {
        x: 'NAV',
        y: [
          0, Math.round(nav / 1000, 2)
        ],
        strokeColor: Variables.colorScheme.success,
        fillColor: Variables.colorScheme.main_backgroundColor,
        value: Math.round(nav / 1000, 2),
      },
    );


    returnData.options = {
      xaxis: {
        categories: ['Capital Called', 'Distributed', 'NAV', 'Gain & Losses'],

        type: 'category',
        convertedCatToNumeric: false,
      },
      yaxis: {
        //  min: -(parseInt(data.summary.position_commitment_in_user_currency / 100000) + 1) * 100000,
        //  max: (capitalCall + distribution + data.summary.position_nav_in_user_currency_current_rate > 0 ? (parseInt((data.summary.position_real_capital_call_in_user_currency + data.summary.position_real_distribution_in_user_currency + data.summary.position_nav_in_user_currency_current_rate) / 10000) + 1) * 10000 : 0),
      },

    };
    return returnData


  },
  buildCallableChart(data) {

    if (!data.summary) { return false }
    //Fix for  dashboard values 
    if (data.summary && !data.summary.position_commitment_in_user_currency && data.summary.total_commited_in_user_currency) {
      data.summary.position_commitment_in_user_currency = parseInt(data.summary.total_commited_in_user_currency)
    }
    if (data.summary && !data.summary.position_real_capital_call_in_user_currency && data.summary.total_capital_call_in_user_currency) {
      data.summary.position_real_capital_call_in_user_currency = parseInt(data.summary.total_capital_call_in_user_currency)
    }
    if (data.summary && !data.summary.position_real_distribution_in_user_currency && data.summary.total_distributed_in_user_currency) {
      data.summary.position_real_distribution_in_user_currency = parseInt(data.summary.total_distributed_in_user_currency)
    }



    var callable = {}

    callable.data = [
      {
        x: 'Commitment',
        y: [0, -data.summary.position_commitment_in_user_currency],
        fillColor: Variables.colorScheme.gray,
        value: -data.summary.position_commitment_in_user_currency,
      }]
    callable.data.push(
      {
        x: 'Called', y: [
          - data.summary.position_commitment_in_user_currency,
          -data.summary.position_commitment_in_user_currency - data.summary.position_real_capital_call_in_user_currency,
        ],
        fillColor: Variables.colorScheme.gray,
        value: -data.summary.position_real_capital_call_in_user_currency,
      },
    );
    callable.data.push(
      {
        x: 'Open Commitment',
        y: [
          0,
          -data.summary.position_commitment_in_user_currency - data.summary.position_real_capital_call_in_user_currency,
        ],
        fillColor: '#ff3d60',
        value: -data.summary.position_commitment_in_user_currency - data.summary.position_real_capital_call_in_user_currency,
      },
    );

    callable.options = {
      xaxis: {
        categories: ['Commitment', 'Called', 'Open Commitment'],
      },
      yaxis: {
        min: -(parseInt(data.summary.position_commitment_in_user_currency / 100000) + 1) * 100000,
        max: (data.summary.position_real_capital_call_in_user_currency + data.summary.position_real_distribution_in_user_currency + data.summary.position_nav_in_user_currency_current_rate > 0 ? (parseInt((data.summary.position_real_capital_call_in_user_currency + data.summary.position_real_distribution_in_user_currency + data.summary.position_nav_in_user_currency_current_rate) / 10000) + 1) * 10000 : 0),
      },

    };

    //console.log('buildCallableChart', callable);
    return callable;

  },

  sumArrays(array1, array2) {
    const maxLength = Math.max(array1.length, array2.length);
    const result = [];

    for (let i = 0; i < maxLength; i++) {
      // Use || to substitute 0 where a value is undefined
      const value1 = array1[i] || 0;
      const value2 = array2[i] || 0;
      result.push(value1 + value2);
    }

    return result;
  },

  isDate(variable) {
    return variable instanceof Date;
  },
  dateStringToTimestamp(dateStr) {
    // Assuming dateStr is in DD/MM/YYYY format
    const parts = dateStr.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-indexed
    const year = parseInt(parts[2], 10);

    // Create a date object with year, month, and day
    const date = new Date(year, month, day);
    console.log('dateStringToTimestamp', date, date.getTime())
    // Return the timestamp (number of milliseconds since the Unix Epoch)
    return date.getTime() / 1000;
  },
  timestampToDateString(timestamp) {
    // Assuming the timestamp is in milliseconds
    const date = new Date(timestamp * 1000);
    // Use the browser's locale to determine the date format
    return new Intl.DateTimeFormat(navigator.language, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);
  },

  chartOptionTimelineChart(data, xaxis, yaxis, output) {
    const chartData = data.map(item => {
      return {
        x: item[xaxis], // Assuming the date is in a format ApexCharts can understand
        y: item[yaxis]
      };
    });
    //console.log('chartOptionTimelineChart', chartData)
    const chartOptions = {
      chart: {
        type: 'line'
      },/*
      xaxis: {
        type: 'category',
        categories: chartData.map(item => item.x),
      },
      yaxis: {
        title: {
          text: 'NAV in EUR'
        }
      },*/
    }
    const chartSeries = [{
      name: 'NAV in EUR',
      data: chartData
    }]
    if (output === 'options') {
      //console.log('chartOptionTimelineChart', chartOptions)
      return chartOptions
    } else {

      //console.log('chartOptionTimelineChart', chartSeries)
      return chartSeries
    }

  },


  findMatchingPositions(position_list, selected_position_id) {
    // Find the selected position object

    const selectedPosition = position_list.find(position => position.position_id === parseInt(selected_position_id));

    //console.log('findMatchingPositions', position_list, selected_position_id,selectedPosition)
    if (!selectedPosition) {
      // If the selected position is not found, return an empty array
      return [];
    }

    // Extract asset_name and asset_feeder_name from the selected position
    const { asset_name, asset_feeder_name } = selectedPosition;

    // Find other positions with the same asset_name or asset_feeder_name
    const matchingPositions = position_list.filter(position => {
      return (position.asset_name === asset_name || (position.asset_feeder_name !== 'undefined' && position.asset_feeder_name !== '' && position.asset_feeder_name === asset_feeder_name)) && parseInt(position.position_id) != parseInt(selected_position_id);
    });
    console.log('Matching positions found :', matchingPositions)
    return matchingPositions;
  },

  calculateBudget(budget) {

    let capitalCalls = [];
    let distributions = [];
    let balance = [];
    let investments = [];
    let commitment = [];
    let cumulativeBalance = [];
    let cumulativeCalls = [];
    let already_commited = [];

    //console.log('calculateBudget', budget)
    // Initialize investments for each year

    Object.entries(budget).forEach(([accountId, accountBudget]) => {
      //console.log('accountBudget', accountBudget)
      accountBudget.forEach(accountBudget => {

        for (let yearCount = 0; yearCount < accountBudget.invest_period; yearCount++) {
          investments.push({
            accountId: accountId,
            already_commited: (yearCount == 0 ? accountBudget.already_commited : 0),
            commited: (yearCount == 0 ? accountBudget.remaining_budget : 0),
            amount: accountBudget.remaining_budget / accountBudget.invest_period,
            startYear: accountBudget.year + yearCount,
            detentionPeriod: accountBudget.detention_period,
            irr: accountBudget.expected_irr
          });
        }
      })
    });
    // console.log('investments', investments)

    let maxYear = Math.max(...investments.map(item => item.startYear)) + Math.max(...investments.map(item => item.detentionPeriod))
    //console.log('max year', maxYear)
    let startYear = MoondanceLayout.currentYear()

    // Calculate capital calls and distributions
    for (let year = startYear; year < maxYear; year++) {
      let yearIndex = year;
      capitalCalls[yearIndex] = 0;
      distributions[yearIndex] = 0;
      commitment[yearIndex] = 0;
      already_commited[yearIndex] = 0;


      //Commitment

      investments.forEach(investment => {
        if (investment.startYear === year) {
          commitment[yearIndex] += parseInt(investment.commited)
          already_commited[yearIndex] += parseInt(investment.already_commited)
        }
      });


      // Capital Calls
      investments.forEach(investment => {
        if (investment.startYear === year) {
          capitalCalls[yearIndex] += parseInt(investment.amount)
        }
      });

      // Distributions
      investments.forEach(investment => {
        if (year - investment.startYear === investment.detentionPeriod) {
          let compoundedAmount = investment.amount * Math.pow(1 + investment.irr, investment.detentionPeriod);
          let distribution = compoundedAmount;

          distributions[yearIndex] += parseInt(distribution)

        }
      });
      // Distributions
      balance[yearIndex] = (balance[yearIndex] ? balance[yearIndex] : 0) + (distributions[yearIndex] ? distributions[yearIndex] : 0) - (capitalCalls[yearIndex] ? capitalCalls[yearIndex] : 0)

      cumulativeBalance[yearIndex] = (cumulativeBalance[yearIndex - 1] ? cumulativeBalance[yearIndex - 1] : 0) + balance[yearIndex]
      cumulativeCalls[yearIndex] = (cumulativeCalls[yearIndex - 1] ? cumulativeCalls[yearIndex - 1] : 0) - capitalCalls[yearIndex]
    }
    //console.log('calculateBudget', capitalCalls, distributions, balance,cumulativeBalance,cumulativeCalls,already_commited)
    return { capitalCalls, distributions, balance, cumulativeBalance, cumulativeCalls, commitment, already_commited };
  },

  filterDataPeryear(dataArray) {
    const currentYear = new Date().getFullYear();

    dataArray.forEach((item, index) => {

      if (item.year === 0) {
        dataArray.splice(index, 1);
      }


      if (item.year > 0 && item.year < currentYear) {
        item['sum_remaining_capital_call_in_user_currency'] = 0;
        item['sum_remaining_distribution_in_user_currency'] = 0;
        item['sum_remaining_balance_in_user_currency'] = 0;
        item['cumul_model_balance_in_user_currency'] = 0;

        Object.keys(item).forEach(key => {
          if (key.includes("model")) {
            item[key] = 0;
          }
        });
      } else if (item.year > 0 && item.year == currentYear) {
        item['sum_remaining_capital_call_in_user_currency'] = item['sum_model_remaining_capital_call_in_user_currency']
        item['sum_remaining_distribution_in_user_currency'] = item['sum_model_remaining_distribution_in_user_currency'] 
        item['sum_remaining_balance_in_user_currency'] = item['sum_remaining_capital_call_in_user_currency'] + item['sum_remaining_distribution_in_user_currency'];

      } else if (item.year > 0 && item.year > currentYear) {
        item['sum_remaining_capital_call_in_user_currency'] = (item['sum_model_capital_call_in_user_currency'] - item['sum_real_capital_call_in_user_currency'] < 0 ? item['sum_model_capital_call_in_user_currency'] - item['sum_real_capital_call_in_user_currency'] : 0);
        item['sum_remaining_distribution_in_user_currency'] = (item['sum_model_distribution_in_user_currency'] - item['sum_real_distribution_in_user_currency'] > 0 ? item['sum_model_distribution_in_user_currency'] - item['sum_real_distribution_in_user_currency'] : 0);
        item['sum_remaining_balance_in_user_currency'] = item['sum_remaining_capital_call_in_user_currency'] + item['sum_remaining_distribution_in_user_currency'];

      }



      if (item.year < currentYear) {
        item['cumul_actual_balance_in_user_currency'] = (dataArray[index - 1] && dataArray[index - 1]['cumul_actual_balance_in_user_currency'] ? dataArray[index - 1]['cumul_actual_balance_in_user_currency'] + item['sum_real_balance_in_user_currency'] : item['sum_real_balance_in_user_currency'])
        item['balance_in_user_currency'] = item['sum_real_balance_in_user_currency']
        item['cumul_model_balance_in_user_currency'] = 0

        item['cumul_balance_in_user_currency'] = item['cumul_actual_balance_in_user_currency']

      } else if (item.year == currentYear) {
        item['cumul_actual_balance_in_user_currency'] = (dataArray[index - 1] && dataArray[index - 1]['cumul_actual_balance_in_user_currency'] ? dataArray[index - 1]['cumul_actual_balance_in_user_currency'] + item['sum_real_balance_in_user_currency'] : item['sum_real_balance_in_user_currency'])
        item['cumul_model_balance_in_user_currency'] = (dataArray[index - 1] && dataArray[index - 1]['cumul_model_balance_in_user_currency'] ? dataArray[index - 1]['cumul_model_balance_in_user_currency'] + item['sum_remaining_balance_in_user_currency'] : item['sum_remaining_balance_in_user_currency'])

        item['balance_in_user_currency'] = item['sum_real_balance_in_user_currency'] + item['sum_remaining_balance_in_user_currency']

        item['cumul_balance_in_user_currency'] = item['cumul_actual_balance_in_user_currency'] + item['sum_remaining_balance_in_user_currency']


      } else {
        item['cumul_model_balance_in_user_currency'] = (dataArray[index - 1] && dataArray[index - 1]['cumul_model_balance_in_user_currency'] ? dataArray[index - 1]['cumul_model_balance_in_user_currency'] + item['sum_remaining_balance_in_user_currency'] : item['sum_remaining_balance_in_user_currency'])
        item['balance_in_user_currency'] = item['sum_remaining_balance_in_user_currency']
        item['cumul_balance_in_user_currency'] = (dataArray[index - 1] && dataArray[index - 1]['cumul_balance_in_user_currency'] ? dataArray[index - 1]['cumul_balance_in_user_currency'] + item['sum_remaining_balance_in_user_currency'] : item['sum_remaining_balance_in_user_currency'])

      }

    });



    return dataArray

  },



}
function distributePercentages(years, quartile) {
  const positions = {
    1: (years + 1) / 4,
    2: 2 * (years + 1) / 4,
    3: 3 * (years + 1) / 4
  };
  const mean = positions[quartile];  // Adjust mean to the selected quartile
  const stdDev = years / 6;          // Standard deviation, controls the spread

  // Calculate normal distribution density
  function normalDensity(x) {
    return Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2)) / (stdDev * Math.sqrt(2 * Math.PI));
  }

  // Calculate densities for each year
  let densities = [];
  for (let i = 0; i < years; i++) {
    densities.push(normalDensity(i));
  }

  // Normalize densities to get percentages that sum to 1 (or 100%)
  const sumDensities = densities.reduce((a, b) => a + b, 0);
  let percentages = densities.map(d => (d / sumDensities) * 100);

  // Round to two decimal places and adjust to ensure sum is exactly 100%
  percentages = percentages.map(p => Math.round(p * 100) / 100);
  const error = 100 - percentages.reduce((a, b) => a + b, 0);
  percentages[percentages.length - 1] += error;  // Adjust the last element by the rounding error

  return percentages;

}


export default MoondanceLayout;