

import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

import Select from "react-select";
import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";

//i18n
import { withTranslation } from "react-i18next";

//import images
import logoSm from "../../assets/images/moondance_logo_sm.png";
import logoDark from "../../assets/images/moondance_logo.png";
import logoLight from "../../assets/images/moondance_logo.png";
import PositionSelector from "../../moondance/lib/components/PositionSelector";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";

import { MoondanceContext } from '../../App';
import UseMoondanceApi from "../../moondance/lib/api";
import {groupAndSortPositions, buildAccountParameter,formatOptionLabel,formatGroupLabel, groupStyles} from "../../moondance/lib/formatData";

import MoondanceLayout from "../../moondance/lib/layout.js";

const Header = (props) => {

  const moondanceApi = UseMoondanceApi();
  const { darkMode } = useContext(MoondanceContext);
  const { accountList } = useContext(MoondanceContext);
  const { addAlert } = useContext(MoondanceContext);
  const { alertContent } = useContext(MoondanceContext);
  const { selectedStatus , setSelectedStatus } = useContext(MoondanceContext);
  const { selectedAccountList } = useContext(MoondanceContext);
  const [search, setsearch] = useState(false);
  const [PositionList, setPositionList] = useState(null);
  const navigate = useNavigate();

  const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
               
 
  useEffect(() => {

    async function fetchData() {
      var api_parameters = {
        account: buildAccountParameter(selectedAccountList),
        status: 'All',
      }
      if(accountList){
        try {
          moondanceApi.fetchPositionsList(api_parameters).then(response => {
            
            if(response.data.length > 0){
              setPositionList(groupAndSortPositions(response.data))
            }
          })
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchData();
  }, [accountList,selectedAccountList, lastUpdate]);

  const customSelectPattern = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#0bb197' : 'white',
      color: state.isFocused ? 'white' : 'black',
    }),

  };
  function handleSelectPosition(p) {
    navigate(`/position/${p.value}`);

  }

  
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className={(darkMode  ? 'darkMode' :'')} >
        <div  className={(darkMode  ? 'navbar-header darkMode' :'navbar-header')}>
          <div className={(darkMode  ? 'd-flex darkMode' :'d-flex')}>
            <div className={(darkMode  ? 'navbar-brand-box text-center darkMode' :'navbar-brand-box text-center')}> 
              <Link to="/" className="logo logo-dark ">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-dark" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="logo-dark" height="60" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-light" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="logo-light" height="60" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => {
                tToggle();
              }}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button>

            <form className="app-search d-none d-lg-block" style={{ minWidth: '25em' }}>
              { PositionList && PositionList.length >0 ?  (
                <div className="position-relative">
                <Select

                  onChange={(f) => {
                    handleSelectPosition(f);
                  }}
                  placeholder="Select Position"
                  options={PositionList}
                  classNamePrefix="select2-selection"
                  formatOptionLabel={formatOptionLabel}

                  formatGroupLabel={formatGroupLabel}
                               
                  styles={MoondanceLayout.select2customStyles}
                />
             </div>
              ):""}

            </form>
        
            
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="bx bx-search-alt" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                  { PositionList && PositionList.length >0 ?  (
                <div className="position-relative">
                <Select

                  onChange={(f) => {
                    handleSelectPosition(f);
                  }}
                  placeholder="Select Position"
                  options={PositionList}
                  classNamePrefix="select2-selection"
                  formatOptionLabel={formatOptionLabel}

                  formatGroupLabel={formatGroupLabel}
                               
                  styles={MoondanceLayout.select2customStyles}
                />
                
              </div>
              ):""}
                  </div>
                </form>
              </div>
            </div>

            <div
              className="dropdown d-inline-block"
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
            >
        
            </div>
    
            <button
              type="button"
              onClick={() => {navigate('/addTransaction')}}
              style={{backgroundColor:'transparent',marginTop:'1em',marginBottom:'1em',maxWidth:'8em'}} className="hideOnMobile btn btn-sm btn-outline-primary"
              >Add Transaction
            </button>
            <button
              type="button"
              onClick={() => {navigate('/addNav')}}
              style={{backgroundColor:'transparent',marginLeft:'1em', marginTop:'1em',marginBottom:'1em',maxWidth:'8em'}} className="hideOnMobile btn btn-sm btn-outline-primary"
              >Update Nav
            </button>
          
            <PositionSelector />
            {(alertContent && alertContent.length > 0 ?<NotificationDropdown/> :'')}


            <ProfileMenu  />


          </div>
        </div>
        
        
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
/*
            <NotificationDropdown />*/