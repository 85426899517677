
import React, {  useContext } from "react";
import {    Card, CardBody,CardTitle  } from "reactstrap";
import { MoondanceContext } from '../../../App';

const Loading = () => {
    
    const { portraitMode } = useContext(MoondanceContext);

    return (
        (portraitMode ?
            <Card >
              <CardBody>
                <CardTitle>  <span className="spinner-border text-primary" style={{ height: '1em', width: '1em', marginRight: '1em' }} role="status"></span> Loading data ...</CardTitle>
              </CardBody>
            </Card>
            : '')
    )
}

export default Loading;