
import React, { useState, useContext, useEffect } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button, Input, InputGroup, InputGroupAddon, InputGroupText,
    NavItem,
    NavLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { MoondanceContext } from '../../App';

import MoondanceTable from "../lib/components/MoondanceTable.js";
import UseMoondanceApi from "../lib/api";
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Logs from '../admin/logs.js'
import Accounts from '../admin/accounts.js'

const MoonBase = () => {

    let Navigate = useNavigate();

    const moondanceApi = UseMoondanceApi();
    const { darkMode } = useContext(MoondanceContext);
    const { lastUpdate } = useContext(MoondanceContext);
    const [activeTab, setactiveTab] = useState("Clients");
    const [userListKeyword, setUserListKeyword] = useState("");
    const [clientListKeyword, setClientListKeyword] = useState("");
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };
    const [DATA, setDATA] = useState({ userList: [] })

    const [showAdminMenu, setShowAdminMenu] = useState(false);




    useEffect(() => {
        const fetchUserProfile = async () => {
            const profile = await moondanceApi.getLoggedinUser();
            if (
                profile
                && profile.data
                && profile.data.access_level
                && profile.data.access_level === 'admin') {
                setShowAdminMenu(true);
            } else {
                setShowAdminMenu(false);
                Navigate('/dashboard')

            }
        };
        const fetchUsersList = async () => {
            const userlist = await moondanceApi.admin_get_users_list({ keyword: userListKeyword });
            if (userlist && userlist.data) {
                console.log(userlist.data)
                setDATA({ userList: userlist.data })
            }
        };
        const fetchClientList = async () => {
            const clientList = await moondanceApi.admin_get_clients_list({ keyword: clientListKeyword });
            if (clientList && clientList.data) {
                console.log(clientList.data)
                setDATA({ clientList: clientList.data })
            }
        };
       


        fetchUserProfile();
        if (activeTab === "Users") {
            fetchUsersList();
        } else if (activeTab === "Clients") {
            fetchClientList();
        } 
    }, [activeTab]);




    return (
        <React.Fragment >
            {(showAdminMenu === true && (
                <div className={(darkMode ? 'page-content darkMode' : 'page-content')} >
                    <Container fluid={true}>
                        <Row style={{ marginBottom: '1em' }}>
                            <div className="flex">
                                <h1><i className="mdi mdi-desktop-mac-dashboard"></i> Backoffice</h1>
                            </div>
                        </Row>
                        <hr />
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Clients' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Clients"); }}
                                >
                                    <i className="mdi mdi-account-group  me-1 align-middle"> </i>{" "}Clients
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Users' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Users"); }}
                                >
                                    <i className="mdi mdi-account me-1 align-middle"> </i>{" "}Users
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Accounts' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Accounts"); }}
                                >
                                    <i className="mdi mdi-account me-1 align-middle"> </i>{" "}Accounts
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Xrates' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Xrates"); }}
                                >
                                    <i className="mdi mdi-cash me-1 align-middle"> </i>{" "}Exchange rates
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Stats' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Logs"); }}
                                >
                                    <i className="dripicons-home me-1 align-middle"> </i>{" "}Logs
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={(activeTab === 'Stats' ? "active nav-link " : "")}
                                    onClick={() => { toggle("Stats"); }}
                                >
                                    <i className="dripicons-home me-1 align-middle"> </i>{" "}Statistics
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="p-3">
                            <TabPane tabId="Users" id="Users">


                                <Row>
                                    <Col xl={12} sm={12}>
                                        <Card>
                                            <CardBody>
                                                {(DATA.userList && DATA.userList.length > 0 ? (
                                                    <MoondanceTable
                                                        key={`${DATA.userList}-${lastUpdate}`}
                                                        title="Users  List"
                                                        rows={DATA.userList}
                                                        defaultSort={{ key: 'last_name', direction: 'ascending' }}
                                                        paginationSize={25}
                                                        columns={[
                                                            {
                                                                label: 'Id', field: 'id',
                                                                customFormat: (row) => {
                                                                    return (
                                                                        <Link className="success" to={'/admin/users/' + row.id}>{row.id}</Link>)
                                                                },


                                                            },
                                                            { label: 'Account', field: 'customer_account_name' },
                                                            { label: 'First Name', field: 'first_name' },
                                                            { label: 'Last Name', field: 'last_name' },
                                                            { label: 'Email', field: 'email' },
                                                            { label: 'Tel', field: 'tel' },
                                                            { label: 'currency', field: 'currency' },
                                                            { label: '#Positions', field: 'number_of_positions' },
                                                            { label: 'SuperAdmin', field: 'superadmin_access_level' },
                                                            { label: 'Login past 3 Months', field: 'number_of_login_past_3Months' },
                                                            { label: 'Login Last 30 days', field: 'number_of_login_past_30days' },
                                                            { label: 'Alerts Last 30 days', field: 'number_of_alert_past_30days' },


                                                        ]}


                                                    />
                                                ) : < p>No Data</p>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="Clients" id="Clients">


                                <Row>
                                    <h6>Clients :</h6>
                                    <Col xl={12} sm={12}>
                                        <Card>
                                            <CardBody>
                                                {(DATA.clientList && DATA.clientList.length > 0 ? (
                                                    <MoondanceTable
                                                        key={`${DATA.clientList}-${lastUpdate}`}
                                                        title="Clients list :"
                                                        rows={DATA.clientList}
                                                        defaultSort={{ key: 'company_name', direction: 'ascending' }}
                                                        paginationSize={25}
                                                        columns={[
                                                            { label: 'Id', field: 'id' },
                                                            { label: 'Name', field: 'company_name' },
                                                            { label: 'Contact First name', field: 'contact_first_name' },
                                                            { label: 'Contact Last Name', field: 'contact_last_name' },
                                                            { label: 'Contact Email', field: 'contact_email' },
                                                            { label: '#Users', field: 'current_users_count' },
                                                            { label: '#Accounts', field: 'current_accounts_count' },
                                                            { label: '#Positions', field: 'current_positions_count' },

                                                            { label: 'Max Users', field: 'max_users_allowed' },
                                                            { label: 'Max Accounts', field: 'max_accounts_allowed' },
                                                            { label: 'Max Positions', field: 'max_positions_allowed' },


                                                        ]}


                                                    />
                                                ) : < p>No Data</p>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="Accounts" id="Accounts">


                               <Accounts />
                            </TabPane>
                            <TabPane tabId="Logs" id="Logs">

                                <Logs />

                            </TabPane>
                        </TabContent>
                    </Container>
                </div>
            ))}
        </React.Fragment>
    );
}
/*
{
    "id": 1767,
    "status": "",
    "date": "2023-09-28T11:13:39",
    "full_date": "28/09/2023 11:13",
    "timestamp": 1695892419,
    "event": "Fund NAV updated",
    "request": "Post /fund/70/nav",
    "user_ip": "10.244.21.198",
    "user_agent": "70",
    "user_id": 0,
    "asset_id": 0,
    "position_id": 0,
    "name": null,
    "email": null
}
*/


export default MoonBase;