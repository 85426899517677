import React, { useEffect, useState } from "react";

import Form from 'react-bootstrap/Form';
import { UncontrolledCarousel } from "reactstrap";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink, InputGroup
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import UseMoondanceApi from "../lib/api.js";

const ContactForm = () => {

    const moondanceApi = UseMoondanceApi();
    const [from_name, set_from_name] = useState('');
    const [from_email, set_from_email] = useState('');
    const [subject, set_subject] = useState('Filling Contact Form on Moondance.financial');
    const [message, set_message] = useState('');
    const [error_name, set_error_name] = useState('');
    const [error_email, set_error_email] = useState('');
    const [error_message, set_error_message] = useState('');

    const [message_sent, set_message_sent] = useState('');

    async function sendMessage() {
        let error = false;

        if (isValidEmail(from_email)) {
            set_error_email('')
        } else {
            set_error_email('Invalid email address')
            error = true;
        }
        if(from_name.length < 1){
            set_error_name('Please enter your name')
            error = true;
        } else{
            set_error_name('')
        }

        if(message.length < 1){
            set_error_message('Please enter a message')
            error = true;
        } else{
            set_error_message('')
        }

        if(!error){
            let requestData ={
                from_name: from_name,
                from_email: from_email,

                subject: subject,
                message: message,
                type: 'contactForm'
    } 
            try {
                var result = await moondanceApi.sendContactForm(requestData)
                if(result.success){
                    set_message_sent('An email has been dispatched to verify your email address. Please click the link contained within this email to complete the verification process. Note that your message to our team will not be processed until your email address has been confirmed.')
                } else {
                    console.log("Error sending message", result)
                    if(result.data && result.data ==='IPSPAM') {
                        set_message_sent('This IP address has generated an unusually high volume of unverified messages. Please wait and attempt your request again later.')
                    } else {
                        set_message_sent('Error sending message')
                    }
                }
            } catch (error) {
                console.log("Error sending message", error)
                set_message_sent('Error sending message')
            }
        }
    }


    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    return (

        <>
            <Row >
                <h4 >Contact Us</h4>
                <p> Contact us to know more about Moondance</p>
            </Row>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm="3">Your Name</Form.Label>
                <Col sm="9">
                    <input
                        className="form-control"
                        type="text"

                        value={from_name}
                        onChange={(e) => set_from_name(e.target.value)}
                    />
                    <span style={{ color: 'red' }}>{error_name}</span>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm="3">Your Email</Form.Label>
                <Col sm="9">
                    <input
                        className="form-control"
                        value={from_email}
                        type="email"
                        onChange={(e) => set_from_email(e.target.value)}
                    />

                    <span style={{ color: 'red' }}>{error_email}</span>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm="3">Subject</Form.Label>
                <Col sm="9">
                    <input
                        className="form-control"
                        value={subject}
                        type="email"
                        onChange={(e) => set_subject(e.target.value)}
                    />

                    <span style={{ color: 'red' }}>{error_email}</span>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
                <Form.Label column sm="3">Your Message</Form.Label>
                <Col sm="9">
                    <textarea

                        rows={5}
                        className="form-control"
                        type="text"
                        onChange={(e) => set_message(e.target.value)}
                    />

                    <span style={{ color: 'red' }}>{error_message}</span>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" >
                <Col sm="3">&nbsp;</Col>
                <Col sm="3">
                    <Button color="primary" type="submit" onClick={() => sendMessage()}>Send your message</Button>
                    </Col>
                <Col sm="6">
                    <p >{message_sent}</p>
                </Col>
            </Form.Group>

        </>

    )
}


export default ContactForm;
