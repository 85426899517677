import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, CardBody, Col, Row, CardTitle } from "reactstrap";
import ReactApexChart from "react-apexcharts"
import { formatCurrency, formatPercentage, formatNumber } from "../../lib/formatData";

import MoondanceLayout from "../../lib/layout.js";
import { use } from "i18next";
import { set } from "lodash";


export default function BarChart(props) {

  const [fullScreen, setFullScreen] = useState(false)
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([])
  // External function that accesses the chart element
  async function downloadSVG(datatype) {
    const chartInstance = chartRef.current; // Access the chart DOM element

    if (!chartInstance || !chartInstance.chart || !chartInstance.chart.exports) {
      console.error("Chart instance or necessary properties are undefined");
      return;
    }

    // Use the correct method for exporting the chart based on the datatype
    if (datatype === "png") {
      await chartInstance.chart.exports.exportToPng();
    } else if (datatype === "svg") {
      await chartInstance.chart.exports.exportToSVG();
    } else {
      console.error("Unsupported datatype");
    }
  }

  useEffect(() => {
          let updateChartData =

          {

            series: (props.data ? props.data : []),
            options: {
              states: {
                hover: {
                  filter: {
                    type: 'none'
                  }
                }
              },
              chart: {
                toolbar: {
                  show: false // this hides the toolbar
                },

                stacked: (props.stacked ? props.stacked : false)
              },
              fill: {
                colors: (props.options && props.options.colors ? props.options.colors : []),
              },

              tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {

                  // Start the HTML output with a div tag
                  let htmlOutput = '<table>';

                  // Loop through all series
                  for (let i = 0; i < w.config.series.length; i++) {
                    // Get the series name
                    let seriesName = w.config.series[i].name;
                    let yValue = series[i][dataPointIndex];

                    // Append series name and x value to the HTML output
                    if (yValue !== 0) {
                      htmlOutput += '<tr><td><strong>' + seriesName + ':</strong></td>';

                      if (props.scale && (props.scale === "pct_commited" || props.scale === "pct_called")) {
                        htmlOutput += '<td style="text-align:right">' + MoondanceLayout.formatPercentage(yValue) + '</td></tr>';

                      } else {
                        htmlOutput += '<td style="text-align:right">' + MoondanceLayout.formatCurrency(yValue, props.currency) + '</td></tr>';
                      }
                    }

                  }

                  // Close the div tag
                  htmlOutput += '</table>';

                  // Return the completed HTML output
                  return htmlOutput;
                }
              },
              stroke: (props.options && props.options.stroke ? props.options.stroke : {
                colors: (props.options && props.options.colors ? props.options.colors : []),
                width: [0, 0]
              }),
              dataLabels: {
                enabled: false,
                enabledOnSeries: [2]
              },
              markers: (props.options && props.options.markers ? props.options.markers : {
                size: 4,
              }),
              bar: {
                horizontal: false,
                borderWidth: 0, // Set the border width to 0 for the bars
              },
              xaxis:
                (props.options && props.options.xaxis ? props.options.xaxis :
                  {
                    type: 'category',
                    categories: (props.options && props.options.xaxis && props.options.xaxis.categories ? props.options.xaxis.categories : []),

                    convertedCatToNumeric: false,
                  }),
              yaxis: {
                //

                labels: {
                  formatter: function (value) {
                    if (props.scale && props.scale === "pct_commited") {
                      return MoondanceLayout.formatPercentage(value, 2);

                    } else if (props.scale && props.scale === "pct_called") {
                      return MoondanceLayout.formatPercentage(value, 2);

                    } else {
                      return formatNumber(value / 1000) + ' k' + (props.currency ? ' ' + MoondanceLayout.currencySymbol(props.currency) : '€');

                    }
                  },
                },
              },

            }
          }

          if (props.scale && props.scale !== "cash" && props.scale !== "cash_asset_currency") {
            updateChartData.options.yaxis.min = -1;
            // chartData.options.yaxis.max = ;
            let minMax = MoondanceLayout.findMaxMin(props.data);
            updateChartData.options.yaxis.max = (parseInt(minMax['max'] / 0.25) + 1) * 0.25;
          }
          if (props.options && props.options.annotations) {
            updateChartData.options.annotations = props.options.annotations
          }
          setChartData(updateChartData);
        }, [props.data, props.currency, props.display, props.scale, props.options, props.stacked]) 

  function showGraph() {
   
      //console.log('chartData',chartData)
      if(chartData && chartData.options && chartData.series && chartData.series.length > 0){
        return (  <>
          <ReactApexChart
            ref={chartRef}
            key={chartRef}
            options={chartData.options}
            series={chartData.series}

            height={
              (fullScreen ? (window.innerHeight - 100) : (props.height ? props.height : 350))}

            title={props.title}
            className="apex-charts"
          />
          <button className="btn btn-link" onClick={() => { setFullScreen(!fullScreen) }} > {(fullScreen ? <><span className="bx bx-collapse"></span> Reduce</> : <><span className="bx bx-expand"></span> Full Screen</>)} </button>
          <button className="btn btn-link" onClick={() => { downloadSVG('png') }} > <span className="bx bx-export"></span> Export </button>
        </>
        )
      }
}

return (
  <React.Fragment>
    {fullScreen ?
      <div style={{ zIndex: 1000000, width: '100%', height: '100%', backgroundColor: 'white', minWidth: '100%', minHeight: '100%', position: 'fixed', top: '0', left: '0' }}>

        {showGraph()}

      </div>
      : <>{showGraph()}</>
    }

  </React.Fragment>


)
}
/*
 <table className="table">
    {
      props.data.map(
        (item, index) => (
          <tr>
          <td>{item.x}</td>
            <td className="text-end">{formatCurrency(item.value)}</td>
            <td className="text-end">{(index >0 ?formatPercentage(-item.value/props.data[0].value,0):'')}</td>
              
          </tr> 
        )
      )
    }
    </table>
    */
/*   {
              fillColor: '#adb5bd',
              x: 'Capital Called',
              y: [0, -returnData.summary.t_cc_eur],
              value: -returnData.summary.t_cc_eur,
              tooltipLegend: formatPercentage(returnData.summary.t_cc_eur/returnData.summary.t_c_eur) +' of total Commitment',
            }];*/