
import React, { useEffect, useState } from "react";
import { useParams, } from 'react-router-dom';
import logodark from "../../assets/images/moondance_logo_sm.png";
import logolight from "../../assets/images/moondance_logo_sm.png";
import { Link } from 'react-router-dom';

import { Card, CardBody, Alert, Col, Container, Form, Input, Label, Row } from "reactstrap";


import UseMoondanceApi  from "../lib/api";


const ConfirmContactForm = () => {


    const moondanceApi = UseMoondanceApi();
    const params = useParams();
    const activation_code = (params.token ? params.token : null);
    const [submitResult, setSubmitResult] = useState(false);
    const [message, set_message] = useState('');


    async function sentTokenToBackend(){

        try {
            let postParameters = {token: activation_code}
            const response = await moondanceApi.confirmContactToken(postParameters)
            console.log(response)
            if (response.success) {
                set_message('Your message has now been sent to our team !')
            } else {

                set_message('An error occured, your message has NOT been sent to our team !')
                
            }

        

        } catch (error) {
            set_message('An error occured, your message has NOT been sent to our team !')
             
            console.log(error);
            return []
        }    


    }
    useEffect(() => {
        console.log('token', activation_code)
        console.log('params', params)
        sentTokenToBackend()



    }, [activation_code]);
    
    useEffect(() => {
        document.body.className = "bg-pattern";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>

            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card>
                                <CardBody className="p-4">
                                    <div>
                                        <div className="text-center">
                                            <Link to="/">
                                                <img
                                                    src={logodark}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-dark mx-auto"
                                                />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-light mx-auto"
                                                />
                                            </Link>
                                        </div>
                                        <h4 className="font-size-18 text-muted mt-2 text-center">
                                            Confirm your email
                                        </h4><hr/>
                                      
                                            <Row>
                                                <Col md={12}>
                                                    <p className="text-center">
                                                        {message}
                                                    </p>
                                                    <hr/>
                                                    <div className="d-grid mt-4">
                                                <a
                                                    className="fw-medium btn btn-success waves-effect waves-light"
                                                    href="/home" >
                                                    Click here to go back to the home page
                                                </a>
                                                </div>
                                                </Col>
                                                </Row>
                                           
                                       
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );


}


export default ConfirmContactForm;