
const Variables = {

 statusList : [
    { id: 1, label: "Open", value: 1, default: true, description: 'Additional calls and distribution are expected' },
    { id: 4, label: "Liquidated", value: 4, default: false, description: 'NO additional calls or distribution are expected' },
    { id: 2, label: "Draft", value: 2, default: false, description: 'Draft fund, will not be included in reporting' },
    { id: 100, label: "Deleted", value: 100, default: false, description: 'Deleted, will not be included in reporting' },

],

 currencyList : [
    { id: 'EUR', label: 'EUR', description: 'Euro', date: '', rate: '' },
    { id: 'USD', label: 'USD', description: 'US dollar', date: '', rate: '' },
    { id: 'CAD', label: 'CAD', description: 'Canadian dollar', date: '', rate: '' },
    { id: 'GBP', label: 'GBP', description: 'British pound', date: '', rate: '' },
    { id: 'NOK', label: 'NOK', description: 'Norwegian crown', date: '', rate: '' },
    { id: 'CHF', label: 'CHF', description: 'Swiss Franc', date: '', rate: '' },
],

 sectorList : [
    { "id": "business_services", "label": "Business Services" },
    { "id": "consumer_goods", "label": "Consumer Goods" },
    { "id": "diversified", "label": "Diversified" },
    { "id": "energy", "label": "Energy" },
    { "id": "finance", "label": "Finance" },
    { "id": "health", "label": "Health" },
    { "id": "industry", "label": "Industry" },
    { "id": "infrastructure", "label": "Infrastructure" },
    { "id": "technology", "label": "Technology" },
    { "id": "real_estate", "label": "Real Estate" },
    { "id": "impact", "label": "Impact" },
    { "id": "others", "label": "Others" },
],
 geographyList : [

    { id: 'Europe', label: 'Europe', parent: 'Global' },
    { id: 'North America', label: 'North America', parent: 'Global' },
    { id: 'Asia', label: 'Asia' },
    { id: 'Emerging', label: 'Emerging markets' },
    { id: 'Others', label: 'Others' },
    { id: 'Global', label: 'Global' },

],
 assetType : {
    buyout: { label: 'Buyout fund (LBO) - Large', description: 'Combines investment funds with borrowed money', bgcolor: '#198ae3' },
    buyoutsmallmid: { label: 'Buyout fund (LBO) - Small / Mid', description: 'Combines investment funds with borrowed money', bgcolor: '#198ae3' },
    growth: { label: 'Growth Equity', description: 'Growth capital invests in mature companies looking to grow their business by entering new markets or buying other companies', bgcolor: '#bab0f0' },
    venture: { label: 'Venture Capital (VC) & Early growth', description: 'Funding early-stage startups and new businesses', bgcolor: '#6d95e9' },
    co_invest: { label: 'Co-investment', description: 'Invest alongside others funds in the world.', bgcolor: '#99a2ed' },
    secondaries: { label: 'Secondaries', description: 'Buying pre-existing investor commitments to private-equity funds.', bgcolor: '#f092b9' },
    direct: { label: 'Direct Equity', description: 'Direct ownership of shares or stocks in a company', bgcolor: '#ea7699' },
    funds_of_funds: { label: 'Fund of Funds', description: 'Acts as an investor and buys into a portfolio of other private equity funds', bgcolor: '#ffe4ff' },
    infrastructure: { label: 'Infrastructure', description: 'Infrastructure funds invest in assets that provide essential utilities or services', bgcolor: '#edd1f9' },
    impact: { label: 'Impact', description: 'Impact fund', bgcolor: '#edd1f9' },
    real_estate: { label: 'Real Estate', description: 'Real estate fund', bgcolor: '#edd1f9' },
    other: { label: 'Others', description: 'Other funds', bgcolor: '#ea7699' },
},

 transactionType : [
    { value: 'capital_call', label: 'Capital Call', sign: '-' },
    { value: 'distribution', label: 'Distribution', sign: '+' },
    { value: 'recallable', label: 'Recallable Capital Repayment', sign: '+' },
    { value: 'dividend', label: 'Dividend payment', sign: '+' },
    { value: 'setup_fee', label: 'Setup Fee', sign: '-' },
    { value: 'recalled_distributions', label: 'Recalled Distributions', sign: '-' }
    /*  { value: 'Reimbursment', label: 'Reimbursment', sign: '+' },
         { value: 'share_sold', label: 'Shares sold', sign: '+' },
         { value: 'share_purchase', label: 'Shares purchased', sign: '-' },
       */

],

 dark_colorScheme :  {

    main_backgroundColor: '#000000',
    default_text: '#AEAEB2',
    secondary_backgroundColor: '#3A3A3C',
    selected_background: '#000000',
    selected_text: '#AEAEB2',
    actual_neutral: '#718190',
    expected_neutral: '#BFBFBF',
    actual_red: '#A52A2A',
    actual_green: '#354230',
    secondary: '#74788d',
    primary: '#78E5CD',
    danger: '#ff3d60',
    success: '#354230',
    info: '#4aa3ff',
    gray: '#74788d',
    dark: '#343a40',
    gold: '#fcb92c'
  },

 light_colorScheme : {
    main_backgroundColor: '#FFFFFF',
    default_text: '#000000',
    secondary_backgroundColor: '#F3F3F4',
    selected_background: '#F3F3F4',
    selected_text: '#000000',
    actual_neutral: '#718190',
    expected_neutral: '#DDDDDD',
    actual_red: '#FF3D60',
    actual_green: '#0FC074',
    secondary: '#74788d',
    primary: '#78E5CD',
    danger: '#ff3d60',//#FF3D60
    success: '#0FC074', //#0ac074',
    info: '#4aa3ff',
    gray: '#74788d',
    dark: '#343a40'

  }, 

   colorScheme :{
    main_backgroundColor: '#FFFFFF',
    default_text: '#000000',
    secondary_backgroundColor: '#F3F3F4',
    selected_background: '#F3F3F4',
    selected_text: '#000000',
    actual_neutral: '#718190',
    expected_neutral: '#DDDDDD',
    actual_red: '#FF3D60',
    actual_green: '#0FC074',
    secondary: '#74788d',
    primary: '#78E5CD',
    danger: '#ff3d60',//#FF3D60
    success: '#0FC074', //#0ac074',
    info: '#4aa3ff',
    gray: '#74788d',
    dark: '#343a40'

  }, 

}

export  default Variables