import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Col, Row, Dropdown, Card, CardBody,
  DropdownMenu,
  DropdownItem, Container,
  DropdownToggle,UncontrolledAlert
} from "reactstrap";
import { MoondanceContext } from '../../../App';

import MoondanceLayout from "../../lib/layout.js";

import MoondanceTable from "../../lib/components/MoondanceTable.js";
import ReactApexChart from "react-apexcharts"

import { buildAccountParameter, positionPerGroup, positionPerGroupGeographies, positionPerGroupSectors } from "../../lib/formatData";
import UseMoondanceApi from "../../lib/api";
import { statusList, currencyList, sectorList, geographyList, assetType, transactionType, dark_colorScheme, light_colorScheme, colorScheme } from "../../lib/variables/variables.js";


import { Link } from 'react-router-dom';
import { set } from "lodash";
function PositionChart(props) {

  const [ai_reply, setAiReply] = useState('');
  const moondanceApi = UseMoondanceApi();
  const { selectedStatus } = useContext(MoondanceContext);
  const { userCurrency } = useContext(MoondanceContext);
  const { selectedAccountList } = useContext(MoondanceContext);
  const [DATA, setData] = useState(null)
  const [fullScreen, setFullScreen] = useState(false)
  const [TABLE_DATA, setTableData] = useState({});
  const [chart_type] = useState('bar');
  const [x_option, set_x_option] = useState([
    { label: 'Per Position', id: 'asset_name', horizontal: true, showSecYAxis: "asset_vintage_year" },
    { label: 'Asset type', id: 'asset_type' },
    { label: 'Vintage', id: 'asset_vintage_year' },
    { label: 'Account', id: 'position_account_name' },
    { label: 'Currency', id: 'asset_currency' },
    { label: 'Intermediate', id: 'asset_intermediate' },
    { label: 'Geography', id: 'geography' },
    { label: 'Sectors', id: 'sectors' }
  ]);


  const [y_option, set_y_option] = useState([
    { label: 'Internal Rate of Return (IRR)', id: 'position_irr_in_user_currency', y_axis_format: 'percentage', data_label_format: 'percentage', show_average: true },
    { label: 'Total Value to Paid-In (TVPI)', id: 'position_tvpi_in_user_currency', y_axis_format: 'number', data_label_format: 'number', show_average: true },
    { label: 'Distributions to Paid-In (DPI)', id: 'position_dpi_in_user_currency', y_axis_format: 'number', data_label_format: 'number', show_average: true },
    { label: 'Commitment', id: 'position_commited_in_user_currency_at_inception_rate', y_axis_format: 'currency', show_average: false },
    { label: 'Last NAV', id: 'position_last_nav_in_user_currency', y_axis_format: 'currency', show_average: false },
    { label: 'Estimated open Commitment', id: 'position_expected_remaining_callable_capital_in_user_currency', show_average: false }
  ]);

  const [order_options] = useState([
    {
      id: 'A-Z',
      label: 'By label (Ascending)',
    },
    {
      id: 'Z-A',
      label: 'By label (Descending)',
    },
    {
      id: 'valueAsc',
      label: 'By value (Ascending)'
    },
    {
      id: 'valueDesc',
      label: 'By value (Descending)'
    },

    {
      id: 'ysecAsc',
      label: 'By vintage (Ascending)'
    }]);
  const chartRef = useRef(null);
  const [selected_y, set_selected_y] = useState([]);
  const [selected_x, set_selected_x] = useState([]);
  const [actionDropDown_y, set_actionDropDown_y] = useState(false)
  const [data_label, set_data_label] = useState('value')
  const [actionDropDown_x, set_actionDropDown_x] = useState(false)

  const [OrderDropDown, set_orderDropDown] = useState(false)
  const [order, set_order] = useState('A-Z')
  const [horizontal, set_horizontal] = useState(false)
  const [height, set_height] = useState(500)
  const [defaultHeight, setDefaultHeight] = useState(500)
  const [chart_options, set_chart_options] = useState({
    chart: {
      type: 'bar',
      toolbar: {
        show: false // this hides the toolbar
      },
      bar: {
        horizontal: false,
        borderWidth: 0, // Set the border width to 0 for the bars
      },

    },

  })

  // External function that accesses the chart element
  async function downloadSVG(datatype) {
    const chartInstance = chartRef.current; // Access the chart DOM element

    if (!chartInstance || !chartInstance.chart || !chartInstance.chart.exports) {
      console.error("Chart instance or necessary properties are undefined");
      return;
    }

    // Use the correct method for exporting the chart based on the datatype
    if (datatype === "png") {
      await chartInstance.chart.exports.exportToPng();
    } else if (datatype === "svg") {
      await chartInstance.chart.exports.exportToSVG();
    } else {
      console.error("Unsupported datatype");
    }
  }


  useEffect(() => {
    set_selected_x(x_option.find(x => x.id === props.x))

  }, [props.x]);
  useEffect(() => {
    set_selected_y(y_option.find(y => y.id === props.y))
  }, [props.y]);

  useEffect(() => {
    if (fullScreen) {
      set_height(window.innerHeight - 100)
    } else {
      set_height(defaultHeight)
    }

  }, [fullScreen, props.data, selected_y, selected_x, props.horizontal, props.order]);


  useEffect(() => {

    let prop_sort = order_options.find(sort => sort.id === props.sort)
    //console.log('sort option', prop_sort)
    if (prop_sort.id && prop_sort.id !== order) {
      set_order(prop_sort.id)
    }
  }, [props.sort]);

  function format_y_axis(value, format, sum = false, addpercentage = false) {

    if (format === 'percentage') {
      return MoondanceLayout.formatPercentage(value, 2);
    } else if (format === 'number') {
      return MoondanceLayout.formatNumber((Math.round(value * 100) / 100));
    } else if (format === 'percentageOfSum') {

      return MoondanceLayout.formatPercentage(value / sum, 2)
    }

    return MoondanceLayout.formatNumber(value / 1000, 1) + ' k' + (props.currency ? (MoondanceLayout.currencyShort[props.currency] ? MoondanceLayout.currencyShort[props.currency] : ' ' + props.currency) : '')
  }

  function sortData(data, key, order) {
    const combinedData = data.x.map((xValue, index) => ({
      x: xValue,
      y: data.y[index],
      y_sec: (data.y_sec && data.y_sec[index] ? data.y_sec[index] : 0),
    }));

    combinedData.sort((a, b) => {
      if (key === 'x') {
        return order === 'asc' ? a.x.toString().localeCompare(b.x.toString()) : b.x.toString().localeCompare(a.x.toString());
      } else if (key === 'y') {
        return order === 'asc' ? a.y - b.y : b.y - a.y;
      } else if (key === 'y_sec') {
        return order === 'asc' ? a.y_sec - b.y_sec : b.y_sec - a.y_sec;
      }
    });

    // Splitting combined data back to separate x and y arrays
    const sortedX = combinedData.map(item => item.x);
    const sortedY = combinedData.map(item => item.y);

    return { x: sortedX, y: sortedY };
  }

  useEffect(() => {


    async function fetchData() {
      //console.log('fetchData',order)
      var returnData = props.data

      if (returnData.listPositions.length > 0) {

        var api_parameters = {
          account: buildAccountParameter(selectedAccountList),
          status: buildAccountParameter(selectedStatus),
          calculateIrr: true
        }
        if (selected_x.id === 'geography') {
          returnData.byGeographies = await moondanceApi.fetchAssetsGeographiesList(api_parameters);
          returnData.byGeographies = returnData.byGeographies.data
          returnData.statsPerType = positionPerGroupGeographies(returnData, selected_y.id)

        } else if (selected_x.id === 'sectors') {
          returnData.bySectors = await moondanceApi.fetchAssetsSectorsList(api_parameters);
          returnData.bySectors = returnData.bySectors.data
          returnData.statsPerType = positionPerGroupSectors(returnData, selected_y.id)

        } else {
          returnData.statsPerType = positionPerGroup(returnData.listPositions, selected_x.id, selected_y.id, selected_y.show_average, (selected_x.showSecYAxis ? selected_x.showSecYAxis : false))

        }
        //console.log('returnData',selected_x.id,selected_y.id,selected_y.show_average,returnData)

        //ORDER
        let temp_data = []
        temp_data = returnData.statsPerType

        if (order === 'valueAsc') {
          temp_data = sortData(temp_data, 'y', 'asc');
        } else if (order === 'valueDesc') {
          temp_data = sortData(temp_data, 'y', 'desc');
        } else if (order === 'Z-A') {
          temp_data = sortData(temp_data, 'x', 'desc');
        } else if (order === 'ysecAsc') {
          temp_data = sortData(temp_data, 'y_sec', 'asc');
        } else {
          temp_data = sortData(temp_data, 'x', 'asc');
        }

        //console.log('temp_data',order,returnData.statsPerType,temp_data)
        returnData.statsPerType.x = temp_data.x
        returnData.statsPerType.y = temp_data.y


        var dd = {}
        dd.label = returnData.statsPerType.x
        dd.s1 = {};
        dd.s1.name = selected_y.label
        dd.s1.data = returnData.statsPerType.y
        dd.sumY = returnData.statsPerType.sumY


        dd.series = [];
        dd.series.push(dd.s1)
        dd.selected_x = selected_x



        dd.options = {
          chart: {
            toolbar: {
              show: false // this hides the toolbar
            },

          },
          fill: {
            colors: [MoondanceLayout.colorScheme.primary],
          },

          yaxis:
            (horizontal ?

              {
                type: 'category',
                categories: (dd.label ? dd.label : []),
                labels: {
                  maxWidth: 350,
                  show: true,
                  formatter: function (value) {
                    return value;

                  },
                }
              } :
              {

                labels: {
                  formatter: function (value) {
                    return format_y_axis(value, (selected_y.y_axis_format ? selected_y.y_axis_format : false), (dd.sumY ? dd.sumY : false), true)
                  },
                },
              }
            ),
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },


          xaxis:

            (horizontal ?
              {
                type: 'category',
                categories: (dd.label ? dd.label : []),
                labels: {
                  show: true,
                  formatter: function (value) {
                    return format_y_axis(value, (selected_y.y_axis_format ? selected_y.y_axis_format : false), (dd.sumY ? dd.sumY : false), true)


                  },
                }
              } :
              {
                type: 'category',
                categories: (dd.label ? dd.label : []),
                labels: {
                  show: true,
                  formatter: function (value) {
                    return value;

                  },
                }
              }
            ),
          plotOptions: {
            bar: {
              horizontal: (horizontal ? horizontal : false),
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (value) {
              return format_y_axis(value, (selected_y.data_label_format ? selected_y.data_label_format : false), (dd.sumY ? dd.sumY : false), true)
            },
            offsetY: (horizontal ? 0 : (fullScreen ? -35 : -25)),
            textAnchor: (horizontal ? 'middle' : 'middle'),
            style: {
              fontSize: (fullScreen ? '18px' : '14px'),
              colors: ["#304758"],

            },
            background: {
              enabled: false,
              background: '#fff',
              opacity: 1,
            }
          },
        }
        setData(dd);

        if (fullScreen) {
          set_height(window.innerHeight - 100)
        } else if (horizontal && dd.options.yaxis.categories && dd.options.yaxis.categories.length > 5) {

          //console.log(dd.options.yaxis.categories)
          set_height(Math.min(dd.options.yaxis.categories.length * 40, 1500))
          setDefaultHeight(Math.min(dd.options.yaxis.categories.length * 40, 1500))
        } else {
          set_height(Math.min(window.innerHeight * 0.6, 700))
          setDefaultHeight(Math.min(window.innerHeight * 0.6, 700))

        }


      }
      let table_data = []
      if (props.data.statsPerType && props.data.statsPerType.x && props.data.statsPerType.y) {
        props.data.statsPerType.x.map((item, index) => {
          table_data.push({ x: item, y: props.data.statsPerType.y[index] })
        })

        setTableData(table_data)
      }
    }

    fetchData();

  }, [props.data, horizontal, order, selected_y, selected_x, fullScreen]);


  async function analyse_ai() {
    console.log('analyse_ai', DATA);
    setAiReply(<><span className="spinner-border text-primary" style={{ height: '1em', width: '1em', marginRight: '1em' }} role="status"></span> Waiting for AI to analyse this page ...</>);

    let AIDATA = DATA;
    AIDATA.currency = userCurrency
    try {
      // Convert the data to JSON format
      let new_ai_reply = await moondanceApi.ai_analyse({
        data: JSON.stringify(AIDATA)
      });
      console.log('ai_reply', new_ai_reply);

      // Ensure new_ai_reply is not undefined or null before checking its length
      if (new_ai_reply && new_ai_reply.data && new_ai_reply.data.content) {


        setAiReply(new_ai_reply.data.content);
      } else {
        setAiReply('No data returned from AI');
      }
    } catch (error) {
      setAiReply('AI is not responding');
      console.log(error);
    }


  }



  function showGraph() {

    return (
      <>
        <Row>
          <div className=" d-flex bd-highlight">
            <div className="me-auto" style={{ marginBottom: "1em" }}>

              <h2 style={{ marginLeft: '1em' }}>  {y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)) ? y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)).label : ''}
                {x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)) ? ' per ' + x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)).label : ''}
              </h2>
            </div>
            <div className="ms-auto" style={{ marginBottom: "1em" }}>
              {(!props.hideAiButton ? <button className="btn  btn-info" style={{ marginRight: '0.5em' }} onClick={() => { analyse_ai() }}><i className="mdi mdi-robot"></i> Ask AI</button> : '')}
            </div>
          </div>
        </Row>
      

        {DATA && (
          <ReactApexChart
            ref={chartRef}
            key={selected_x.id + '_' + selected_y.id + '_' + order + '_' + horizontal}
            series={DATA.series}
            type={chart_type}

            options={DATA.options}
            height={height}
            title={(DATA.title ? DATA.title : '')}
            className="apex-charts"
          />
        )}

        <Row>
          <Col xl={6} sm={12} className="d-flex flex-row ">

            <button className="btn btn-link" onClick={() => { set_horizontal(!horizontal) }} ><span className="mdi mdi-autorenew"></span> Rotate </button>
            <button className="btn btn-link" onClick={() => { setFullScreen(!fullScreen) }} > {(fullScreen ? <><span className="bx bx-collapse"></span> Reduce</> : <><span className="bx bx-expand"></span> Full Screen</>)} </button>
            <button className="btn btn-link" onClick={() => { downloadSVG('png') }} > <span className="bx bx-export"></span> Export </button>
          </Col>
          <Col xxl={6} xl={12} md={12} sm={12} className="d-flex flex-row ">

            <Dropdown
              isOpen={actionDropDown_y}
              toggle={() => set_actionDropDown_y(!actionDropDown_y)}
            >
              <DropdownToggle className="btn btn-link" caret={true}>
                {y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)) ? y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)).label : ''}
                <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                {y_option.length > 0 && y_option.map((item, index) => (
                  <DropdownItem key={index} onClick={() => { set_selected_y(y_option.find(y => y.id === item.id)) }} >
                    <Link>{item.label}</Link>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <div className="my-2"> per </div>
            <Dropdown
              isOpen={actionDropDown_x}
              toggle={() => set_actionDropDown_x(!actionDropDown_x)}
            >
              <DropdownToggle className="btn btn-link" caret={true}>
                {x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)) ? x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)).label : ''}
                <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                {x_option.length > 0 && x_option.map((item, index) => (
                  <DropdownItem key={index} onClick={() => { set_selected_x(x_option.find(x => x.id === item.id)) }} >
                    <Link>{item.label}</Link>
                  </DropdownItem>
                ))}

              </DropdownMenu>
            </Dropdown>
            <div className="my-2">order by</div>
            <Dropdown
              isOpen={OrderDropDown}
              toggle={() => set_orderDropDown(!OrderDropDown)}
            >
              <DropdownToggle className="btn btn-link" caret={true}>
                {order_options.find(x => x.id === order).label}

                <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                {order_options.length > 0 && order_options.map((item, index) => (
                  <DropdownItem key={index} onClick={() => { set_order(item.id) }} >
                    <Link>{item.label}</Link>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </>)
  }

  return (
    <React.Fragment>


      {(fullScreen ?
        <div style={{ zIndex: 100000, width: '100%', height: '100%', backgroundColor: 'white', minWidth: '100%', minHeight: '100%', position: 'fixed', top: '0', left: '0' }}>
          {showGraph()}
        </div>
        :
        <>
          {ai_reply ? <UncontrolledAlert color="info"><h5>Asking AI : </h5><p style={{ whiteSpace: 'pre-line' }}>{ai_reply}</p></UncontrolledAlert> : ''}
        <Row>
          <Col xl={8} sm={12} >
            <Card>
              <CardBody>
                {showGraph()}
              </CardBody>
            </Card>
          </Col>
          <Col xl={4} sm={12}>
            <Card>
              <CardBody>
                {(TABLE_DATA ?
                  <MoondanceTable
                    rows={TABLE_DATA}

                    columns={[
                      {
                        label: (x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)) ? x_option.find(x => x.id === (selected_x.id ? selected_x.id : props.x)).label : ''),
                        field: 'x',
                      },
                      {
                        label: (y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)) ? y_option.find(y => y.id === (selected_y.id ? selected_y.id : props.y)).label : ''),
                        field: 'y',
                        format: (selected_y.data_label_format ? selected_y.data_label_format : 'number'),
                        customFormat: (row) => {
                          return format_y_axis(row['y'], (selected_y.data_label_format ? selected_y.data_label_format : false), false, true)
                        },
                        align: 'right'
                      },
                    ]}
                    hideAiReply={true}
                    hideAiButton={true}

                  />
                  : 'NO data')}

              </CardBody>
            </Card>
          </Col>

        </Row>
        </>

      )}


    </React.Fragment >

  )
}

export default PositionChart
/* 
         */