import React from "react";
import { UncontrolledAlert, Button } from "reactstrap";

function Alert(props) {
  const icon = {
    success: <i className="mdi mdi-check-all"></i>,
    danger: <i className="mdi mdi-block-helper"></i>,
    info: <i className="mdi mdi-alert-circle"></i>,
    warning: <i className="mdi mdi-alert"></i>,
  };

  return (
    <UncontrolledAlert color={props.status ? props.status : "info"}>
      {props.status && icon[props.status] && <span>{icon[props.status]} </span>}
      {props.message ? props.message : ""}
      <Button close onClick={props.onClose} />
    </UncontrolledAlert>
  );
}

export default Alert;
