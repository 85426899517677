
import React, { useState, useContext, useEffect } from "react";

import * as XLSX from 'xlsx';

import UseMoondanceApi from "../../lib/api";
import {
  Card, CardBody, CardTitle, Col, Container, Button, Nav, Table,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown, UncontrolledAlert
} from "reactstrap";

import { useParams, Link, useNavigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import MoondanceLayout from '../../lib/layout.js';
import { set } from "lodash";


export default function MoondanceTable(props) {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const { defaultSort = { key: null, direction: 'ascending' } } = props;
  const [pagination_size, set_pagination_size] = useState((props.paginationSize ? props.paginationSize : 15));
  const [starting_position, set_starting_position] = useState((props.starting_position ? props.starting_position : 0));
  const [step, set_step] = useState((props.step ? props.step : (props.paginationSize ? props.paginationSize : 15)));
  const [exportTriggered, setExportTriggered] = useState(false);
  const [prevStartingPosition, setPrevStartingPosition] = useState(null);
  const [prevPaginationSize, setPrevPaginationSize] = useState(null);
  const [key, setKey] = useState('table');
  const moondanceApi = UseMoondanceApi();
  const [ai_reply, setAiReply] = useState('');

  let obect_rows = Object.values(props.rows);
  const sortedRows = React.useMemo(() => {
    let sortableRows = [...obect_rows];
    //console.log('sortedRows', sortConfig.key,sortableRows)
    if (sortConfig.key !== null) {
      sortableRows.sort((a, b) => {
        let first = a[sortConfig.key];
        let second = b[sortConfig.key];

        // Handle null or undefined values by treating them as less than any other value
        if (first == null) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (second == null) return sortConfig.direction === 'ascending' ? 1 : -1;

        // Check if both are numbers
        if (typeof first === 'number' && typeof second === 'number') {
          return sortConfig.direction === 'ascending' ? first - second : second - first;
        }

        // Convert to strings and compare as lowercase for case-insensitivity if they're not numbers
        first = String(first).toLowerCase();
        second = String(second).toLowerCase();

        if (first < second) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (first > second) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    //console.log('sortedRows', sortConfig.key,sortableRows)
    return sortableRows;
  }, [props.rows, sortConfig]);

  useEffect(() => {
    if (exportTriggered) {
      // Perform the export action
      exportToExcel(key);
      // Reset the state
      setExportTriggered(false);
      set_starting_position(prevStartingPosition);
      set_pagination_size(prevPaginationSize);
    }
  }, [exportTriggered, prevStartingPosition, prevPaginationSize]);

  useEffect(() => {
    if (props.tableId) {
      setKey(props.tableId);
    } else {
      setKey(Math.random().toString(36).substring(7));
    }
    setAiReply('');
  }, [props]);

  const handleExport = () => {

    setPrevStartingPosition(starting_position);
    setPrevPaginationSize(pagination_size);

    // Set the state to trigger the export
    set_starting_position(0);
    set_pagination_size(sortedRows.length);
    setExportTriggered(true);
  };
  async function analyse_ai() {
    let table = document.getElementById(key)
    let tableArray = tableToTableData(table);
    setAiReply(<><span className="spinner-border text-primary" style={{ height: '1em', width: '1em', marginRight: '1em' }} role="status"></span> Waiting for AI to analyse this table ...</>);
    try {
      // Convert the data to JSON format
      let new_ai_reply = await moondanceApi.ai_analyse({
        data: tableArray
      });
      console.log('ai_reply', new_ai_reply);

      // Ensure new_ai_reply is not undefined or null before checking its length
      if (new_ai_reply && new_ai_reply.data && new_ai_reply.data.content) {
        setAiReply(new_ai_reply.data.content.replace(/\\n/g, "<br>"));
      } else {
        setAiReply('No data returned from AI');
      }
    } catch (error) {
      setAiReply('AI is not responding');
      console.log(error);
    }


  }


  const tableToTableData = (table) => {

    const tableArray = [];
    if(props.title){
      tableArray.push([props.title]); // Add the title as the first row
    }
    for (let row of table.rows) {
      const rowData = [];
      for (let cell of row.cells) {
        let cellValue = cell.innerText.trim();
        const format = cell.getAttribute('data-format');

        if (format === 'number' || format === 'percentage' || format === 'currency') {
          // Remove non-numeric characters except for commas, dots, and minus signs
          let numberValue = parseFloat(cellValue.replace(/[^0-9,-\s.]/g, '').replace(',', '.').replace(/\s/g, ''));
          if (!isNaN(numberValue)) {
            // Check if there are any suffixes indicating multiplication (e.g., 'k')
            if (/[kK]/.test(cellValue)) {
              numberValue *= 1000;
            }
            rowData.push(numberValue);
          } else {
            rowData.push(cell.innerText); // If not a number, push original text
          }
        } else {
          rowData.push(cell.innerText); // If the attribute is not 'number', push original text
        }
      }
      tableArray.push(rowData);
    };
    return tableArray;
  };


  const exportToExcel = (tableId, fileName = 'table.xlsx') => {
    // Select the table element
    const table = document.getElementById(tableId);

    // Convert the table rows to an array of arrays
    let tableArray = tableToTableData(table);
    // Convert the array to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(tableArray);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and force a download
    XLSX.writeFile(wb, fileName);
  };



  const requestSort = (key) => {
    //console.log('requestSort', key)
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    set_starting_position(0)
    setSortConfig({ key, direction });
  };
  const getSortDirectionIcon = (column) => {
    if (sortConfig.key === column.field) {
      return sortConfig.direction === 'ascending'
        ? <span className="mdi mdi-chevron-down"></span>
        : <span className="mdi mdi-chevron-up"></span>;
    }
    return null //''<span className="fas fa-sort"></span>''


  };
  const getColumnTotal = (column) => {
    let total = 0;
    obect_rows.map((row, rowIndex) => {
      total = total + parseFloat((row[column.field] ? row[column.field] : 0))

    })
    return total
  }
  /*
  
    colorScheme: {
      main_backgroundColor: '#FFFFFF',
      default_text: '#000000',
  
      secondary_backgroundColor: '#F3F3F4',
      selected_background: '#F3F3F4',
      selected_text: '#000000',
      actual_neutral: '#718190',
      expected_neutral: '#BFBFBF',
      actual_red: '#A52A2A',
      actual_green: '#354230',
      secondary: '#74788d',
      primary: '#0bb197',
      danger: '#ff3d60',
      success: '#0ac074',
      info: '#4aa3ff',
      gray: '#74788d',
      dark: '#343a40'
  
    },
  */
  return (
    <div className="table-responsive " >

      <Row className="flex">
        {(props.title  && !props.hideTitle ? <h5 className="mb-3">{props.title} </h5> : '')}

      </Row>
      <div className=" d-flex bd-highlight">
       

      
        <div className="me-auto" style={{ marginBottom: "1em" }}>

        
          {(pagination_size < sortedRows.length) ? <Button className="btn btn-outline-primary" style={{ marginRight: '0.5em' }} onClick={() => { set_starting_position(0); set_pagination_size(sortedRows.length) }}>Show  All</Button> : ''}

          {(starting_position > 0) ? <Button className="btn btn-outline-primary" style={{ marginRight: '0.5em' }} onClick={() => { set_starting_position((starting_position - step > 0 ? starting_position - step : 0)) }}><span className="fas fa-chevron-left"></span> Previous  {step}</Button> : ''}

          {(starting_position + pagination_size < sortedRows.length) ? <Button className="btn btn-outline-primary" onClick={() => { set_starting_position(starting_position + step) }}>Next {step} <span className="fas fa-chevron-right"></span></Button> : ''}

        </div>
        <div className="ms-auto" style={{ marginBottom: "1em" }}>
        <button className="btn  btn-outline-primary" style={{ marginRight: '0.5em' }} onClick={handleExport}><i className="mdi mdi-microsoft-excel"></i> Export</button>
         {(!props.hideAiButton ? <button className="btn  btn-info" style={{ marginRight: '0.5em' }} onClick={() => { analyse_ai() }}><i className="mdi mdi-robot"></i> Ask AI</button>:'')}

        </div>
      </div>
      <p className="me-auto">Showing {starting_position + 1} to {((starting_position + pagination_size) < sortedRows.length ? (starting_position + pagination_size) : sortedRows.length)} of {sortedRows.length} entries
        </p>
    
      {ai_reply ? <UncontrolledAlert color="info"><h5>Asking AI : </h5><p style={{ whiteSpace: 'pre-line' }}>{ai_reply}</p></UncontrolledAlert> : ''}

      <Table id={key} className={(MoondanceLayout.is_dark_mode ? 'darkMode MoondanceTable table-centered' : 'MoondanceTable table-centered')} style={{ width: '100%', maxWidth: '100%', }}>
        <thead className="thead-light">

          <tr style={{ backgroundColor: MoondanceLayout.colorSchemesecondary_backgroundColor }} >
            {(props.actions ? <th></th> : '')}
            {props.columns.map((column, index) => (
              (column.hide ? '' :
                <th
                  key={index}
                  style={{ wordWrap: 'normal', width: column.width || '', textAlign: column.align || 'left', ...(column.style ? column.style : {}) }}
                  className={column.className || ''}
                  onClick={() => requestSort(column.field)}
                >
                  <Link className="btn btn-link">{column.label}{getSortDirectionIcon(column)}</Link>
                </th>
              )
            ))}

          </tr>
        </thead>
        <tbody >

          {sortedRows.map((row, rowIndex) => (
            ((rowIndex < starting_position + pagination_size && rowIndex >= starting_position) ?
              <tr key={rowIndex}>
                {(props.actions ? <td
                  style={{ width: '100px' }}
                >
                  {props.actions.map((action, actionIndex) => {

                    const showAction = action.condition ? row[action.condition] === 1 : true; // Assuming the condition to check if it equals 1 for "true"
                    return showAction ? (
                      <Button
                        key={actionIndex}
                        onClick={() => (action.onClick ? action.onClick(row) : '')}
                        className="btn-sm btn-rounded waves-effect waves-light btn-outline-primary">{(action.icon ? <span className={action.icon}></span> : '')} {action.label}</Button>
                    )
                      : null;
                  })}
                </td> : '')}
                {props.columns.map((column, columnIndex) => (
                  (column.hide ? '' :

                    <td
                      key={columnIndex}
                      style={{ width: column.width || '', textAlign: column.align || 'left', ...(column.style ? column.style : {}) }}
                      className={column.className || ''}
                      data-format={column.format || undefined} // Ensure data-format is properly assigned


                    > {column.customFormat
                      ? column.customFormat(row)
                      : (column.format
                        ? MoondanceLayout.formatValue(row[column.field], column.format, (column.formatUnit ? column.formatUnit : 2))
                        : row[column.field]
                      )
                      }
                    </td>
                  )
                ))}

              </tr> : '')
          ))}

        </tbody>
        <tfoot>
          <tr key="total" style={{ backgroundColor: MoondanceLayout.colorSchemesecondary_backgroundColor }}>
            {(props.actions ? <th
              style={{ width: '100px' }}
            >Total</th> : '')}
            {props.columns.map((column, columnIndex) => (
              (column.hide ? '' :

                <th
                  key={columnIndex}
                  style={{ width: column.width || '', textAlign: column.align || 'left', ...(column.style ? column.style : {}) }}
                  className={column.className || ''}

                >{
                    (
                      column.showTotal
                        ? (column.format
                          ? MoondanceLayout.formatValue(getColumnTotal(column), column.format, (column.formatUnit ? column.formatUnit : 2))
                          : getColumnTotal(column)
                        )
                        : '')}</th>
              )
            ))}
          </tr>
        </tfoot>
      </Table>
      <Row>
        <div className="d-flex justify-content-end">
          {(starting_position > 0) ? <Button className="btn btn-outline-primary" style={{ marginRight: '0.5em' }} onClick={() => { set_starting_position((starting_position - step > 0 ? starting_position - step : 0)) }}><span className="fas fa-chevron-left"></span> Previous  {step}</Button> : ''}

          {(starting_position + pagination_size < sortedRows.length) ? <Button className="btn btn-outline-primary" onClick={() => { set_starting_position(starting_position + step) }}>Next {step} <span className="fas fa-chevron-right"></span></Button> : ''}
        </div>
      </Row>
    </div>
  );
}
