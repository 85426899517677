
import React, { useState, useContext, useEffect } from "react";
import {
    Container
} from "reactstrap";
import Logs from '../admin/logs.js'

 // this page should not be displayed if the user is not an admin
 

const UserActivityLog = () => {

    return (
        <React.Fragment>
            <div className='page-content' >
                <Container fluid={true}>
                    <Logs />
                </Container>
            </div>
        </React.Fragment>
    )
}



export default UserActivityLog;