
import React, { useState, useContext, useEffect } from "react";
import {
    Card, CardBody, Col, Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { MoondanceContext } from '../../App';
import MoondanceLayout from "../lib/layout.js";
import MoondanceTable from "../lib/components/MoondanceTable.js";
import UseMoondanceApi from "../lib/api";
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Select from "react-select";


const Accounts = (props) => {
    const moondanceApi = UseMoondanceApi();
    const [data, setData] = useState({})
    const [accountListKeyword, setAccountListKeyword] = useState("");
    const { lastUpdate } = useContext(MoondanceContext);

    const fetchAccountList = async () => {
        const accountList = await moondanceApi.admin_get_accounts_list(
                { 
                    keyword: accountListKeyword,
                    user_id: (props && props.user_id ? props.user_id :0),
                });
        if (accountList && accountList.data) {
            console.log(accountList.data)
            setData(accountList.data)
        }
    };

    const [defaultColumns, setDefaultColumns] = useState(
        [
            { label: 'Id', field: 'id' },
            { label: 'Name', field: 'name' },
            { label: 'Customer', field: 'customer_account_name' },
            { label: 'Contact Email', field: 'contact_email' },
            { label: '#Users', field: 'number_of_users' },
            { label: '#Admin', field: 'number_of_admins' },
            { label: '# Open Positions', field: 'number_of_open_positions', showTotal: true },
            { label: '#Positions', field: 'number_of_positions', showTotal: true },
        ]
    )
    useEffect(() => {
        fetchAccountList();
    }, [lastUpdate, accountListKeyword,props]);


    return (

        <Row>
            <h6>Accounts:</h6>
            <Col xl={12} sm={12}>
                <Card>
                    <CardBody>
                        {(data && data.length > 0 ? (
                            <MoondanceTable
                                key={`accountList-${accountListKeyword}-${lastUpdate}`}
                                title="Accounts list :"
                                rows={data}
                                defaultSort={{ key: 'company_name', direction: 'ascending' }}
                                paginationSize={25}
                                columns={defaultColumns}
                            />
                        ) : < p>No Data</p>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Row>

    )

}



export default Accounts;