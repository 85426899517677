import React, { useState, useEffect, useContext, } from "react";

import { Modal, Col, Row, Button, NavItem, NavLink, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Container, Card, CardBody, CardTitle, } from "reactstrap";
import { MoondanceContext } from '../../App';

import MoondanceLayout from '../lib/layout.js';
import { useNavigate } from 'react-router-dom';

export default function SelectAccount(props) {
    const { accountList } = useContext(MoondanceContext);
    const { selectedAccountList, setSelectedAccountList } = useContext(MoondanceContext);
    const { selectedStatus, setSelectedStatus } = useContext(MoondanceContext);

    const { darkMode } = useContext(MoondanceContext);
    const [tmpSelectedAccountList, setTmpSelectedAccountList] = useState([]);
    const [tmpSelectedStatus, setTmpSelectedStatus] = useState([]);
    const [showRefresh, setShowRefresh] = useState(false);


    const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
    const navigate = useNavigate();
    const [modal_open, set_modal_open] = useState(false)

    useEffect(() => {
        setTmpSelectedAccountList(selectedAccountList)
        setTmpSelectedStatus(selectedStatus)
    }, [selectedAccountList, selectedStatus]);

    const triggerRefresh = () => {
        console.log('triggerRefresh', tmpSelectedAccountList)
        setSelectedAccountList(tmpSelectedAccountList)
        setSelectedStatus(tmpSelectedStatus)


        localStorage.setItem('selectedAccount', JSON.stringify(tmpSelectedAccountList));
        localStorage.setItem('selectedStatus', JSON.stringify(tmpSelectedStatus));

        setLastUpdate(Math.floor(Date.now()))
        navigate(-1);

    }

    const selectAccountUpdate = (index, action, event) => {

        //console.log('selectAccountUpdate', index, action, event    )
        if (action === "select") {
            // Find the selected element in accountList
            const selectedElement = accountList[index];

            // Add it to the selectedAccountList (if it's not already present)
            if (!tmpSelectedAccountList.some((item) => item.value === selectedElement.value)) {
                setTmpSelectedAccountList((prevSelected) => [...prevSelected, selectedElement]);
            }
        } else if (action === "unselect") {
            // Find the selected element in selectedAccountList based on some unique identifier (e.g., value)
            const selectedElement = accountList[index];

            // Find the index of the element to remove from selectedAccountList
            const removeIndex = tmpSelectedAccountList.findIndex((item) => item.value === selectedElement.value);

            if (removeIndex !== -1) {
                setTmpSelectedAccountList((prevSelected) =>
                    prevSelected.filter((item, idx) => idx !== removeIndex)
                );
            }
        }

        setShowRefresh(true)
    };
    const selectStatusUpdate = (index, action, event) => {

        if (action === "select") {
            // Find the selected element in statusList
            const selectedElement = MoondanceLayout.statusList[index];

            // Add it to the selectedStatus (if it's not already present)
            if (!tmpSelectedStatus.some((item) => item.value === selectedElement.value)) {
                setTmpSelectedStatus((prevSelected) => [...prevSelected, selectedElement]);
            }
        } else if (action === "unselect") {
            // Find the selected element in selectedStatus based on some unique identifier (e.g., value)
            const selectedElement = MoondanceLayout.statusList[index];

            // Find the index of the element to remove from selectedStatus
            const removeIndex = tmpSelectedStatus.findIndex((item) => item.value === selectedElement.value);

            if (removeIndex !== -1) {
                setTmpSelectedStatus((prevSelected) =>
                    prevSelected.filter((item, idx) => idx !== removeIndex)
                );
            }
        }

        setShowRefresh(true)
    };

    return (

        <React.Fragment>

            <div className={(darkMode ? 'page-content darkMode' : 'page-content')} >
                <Row>
                    <h1
                        className="modal-title mt-0">
                        Select Account and position status
                    </h1><hr />
                    <Col sm="12" xl="4">
                        <Card><CardBody>
                            <h6 style={{ marginLeft: "1em" }}>Account:</h6>

                            {accountList.length > 0 ? (
                                (tmpSelectedAccountList && accountList && accountList.map((element, index) => (
                                    <div key={index} onClick={(e) => selectAccountUpdate(index, (tmpSelectedAccountList.some(selectedElement => selectedElement.value === element.value) ? 'unselect' : 'select'), e)} >
                                        <p >
                                            {(tmpSelectedAccountList.some(selectedElement => selectedElement.value === element.value) ? <span style={{ width: '5em' }} className="dripicons-checkmark mr-2"></span> : <span style={{ width: '5em' }}  >&nbsp;&nbsp;&nbsp;</span>)}&nbsp;{element.label}</p>
                                    </div>
                                )))) : (<p>No accounts found</p>)}
                            {(showRefresh === true ?
                                <div onClick={() => triggerRefresh()} >
                                    <Button className="btn btn-success form-control" >Refresh results</Button>
                                </div>
                                : '')}
                        </CardBody></Card>
                    </Col>
                    <Col sm="12" xl="4">
                        <Card><CardBody>
                            <h6 style={{ marginLeft: "1em" }}>Status:</h6>
                            {MoondanceLayout.statusList && MoondanceLayout.statusList.map((element, index) => (

                                <div key={index} onClick={(e) => selectStatusUpdate(index, (tmpSelectedStatus.some(selectedElement => selectedElement.value === element.value) ? 'unselect' : 'select'), e)} >
                                    <p  >
                                        {(tmpSelectedStatus.some(selectedElement => selectedElement.value === element.value) ? <span style={{ width: '5em' }} className="dripicons-checkmark mr-2"></span> : <span style={{ width: '5em' }}  >&nbsp;&nbsp;&nbsp;</span>)}&nbsp;{element.label}</p>
                                </div>

                            ))}
                            {(showRefresh === true ?
                                <div onClick={() => triggerRefresh()} >
                                    <Button className="btn btn-success form-control" >Refresh results</Button>
                                </div>
                                : '')}


                        </CardBody></Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment >)
}



