import React, { useEffect, useState } from "react";
import { UncontrolledCarousel } from "reactstrap";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";

import img1 from "../../assets/images/public/jcurve.png";
import img2 from "../../assets/images/public/fund_overview.png";

import img3 from "../../assets/images/public/conso1.png";
import img4 from "../../assets/images/public/conso2.png";
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Carousel = () => {



    return (

        <>
            <div id="carouselHome" className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" >
                        <img src={img1} className="d-block w-100" alt="Track fund past performance and modelize future cashflows" />
                        <div style={{ textAlign: "center" }}>
                            <h3 >Track fund past performance and modelize future cashflows</h3>
                            <p>Display past capital calls, distribution and expected future cashflows </p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={img2} className="d-block w-100" alt="Visualize investment data with clarity" />
                        <div style={{ textAlign: "center" }}>
                            <h3 >Visualize investment data</h3>
                            <p>Instantly visualizing commitments, contributions, gains, losses, and net asset value with precision and clarity.</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={img3} className="d-block w-100" alt="Visualize investment data with clarity" />
                        <div style={{ textAlign: "center" }}>

                            <h3>Portfolio Overview at a Glance</h3>
                            <p>Aggregate and examine key performance indicators across sectors, currencies, geographic regions, vintage years, and more, for a comprehensive portfolio analysis.</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={img4} className="d-block w-100" alt="Visualize investment data with clarity" />
                        <div style={{ textAlign: "center" }}>

                            <h3>Portfolio Overview at a Glance</h3>
                            <p>Aggregate and examine key performance indicators across sectors, currencies, geographic regions, vintage years, and more, for a comprehensive portfolio analysis.</p>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselHome" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselHome" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}


export default Carousel;

/* <UncontrolledCarousel
                  indicators={true}
                    items={[
                        {
                            altText: "Track fund past perfomance and modelize future cashflows",
                            caption: "Track fund past perfomance and modelize future cashflows",
                            key: 1,
                            src: img1,
                        },
                        {
                            altText: "Instantly visualize commitments, contributions, gains, losses, and net asset value with precision and clarity",
                            caption: "Instantly visualize commitments, contributions, gains, losses, and net asset value with precision and clarity",
                            key: 2,
                            src: img2,
                        },

                    ]}
                />*/