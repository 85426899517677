
import React, { useState, useContext, useEffect, useCallback, useRef } from "react";

import { Button, Modal, Card, CardBody, Col, Container,  FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import UpdateNav from './nav_update.js';



function NavUpdate(props) {
    const { isOpen, closeModal } = props;
    return (

        <React.Fragment>
            <div className="page-content">
                <Card >


                    <CardBody >
                        <UpdateNav
                            show={true}
                            showOpenButton={false}
                            isModal={false}
                            data={(props && props.data ? props.data : null)}
                        />
                    </CardBody>
                </Card>


            </div>
        </React.Fragment >
    )

};

export default NavUpdate