import React, { useEffect, useState } from "react";
import logodark from "../../assets/images/moondance_logo_sm.png";
import logolight from "../../assets/images/moondance_logo_sm.png";
import { Link } from 'react-router-dom';
import { Card, CardBody, Alert, Col, Container, Form, Input, Label, Row } from "reactstrap";
import UseMoondanceApi from "../lib/api";


const MoondanceLostPassword = () => {


    const moondanceApi = UseMoondanceApi();
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState();
    const [submitResult, setSubmitResult] = useState(false);


    function handleEmailChange(e) {
        setErrors(false)
        setSubmitResult(false)
        setEmail(e.target.value);
    }

    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        console.log(emailRegex.test(email));
        return emailRegex.test(email);
    };

    function handleSubmit() {
        const newErrors = {};
        const requestData = {}
        var isError = false
        console.log(email)
        if (!validateEmail(email)) {
            newErrors.email = ' Please enter a valid email'
            isError = true
        } else {
            requestData.email = email
        }

        if (isError) {
            setErrors(newErrors)

            console.log(newErrors)



        } else {
            console.log('request new password')
            moondanceApi.requestNewPassword(requestData).then(response => {
                setErrors({})
                response.success = true
                setSubmitResult(response)
                console.log(response)
            })
                .catch(error => {
                    newErrors.alert = 'An error occured'
                    setErrors(newErrors)
                    setSubmitResult(error)


                });
        }


    }
    useEffect(() => {
        document.body.className = "bg-pattern";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    return (
        <React.Fragment>

            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card>
                                <CardBody className="p-4">
                                    <div>
                                        <div className="text-center">
                                            <Link to="/">
                                                <img
                                                    src={logodark}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-dark mx-auto"
                                                />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-light mx-auto"
                                                />
                                            </Link>
                                        </div>
                                        <h4 className="font-size-18 text-muted mt-2 text-center">
                                            Request a new password.
                                        </h4><hr/>
                                        {submitResult.success &&
                                            (<Row>
                                                <Col md={12}>
                                                    <p>An email has been sent to this email adress, click on the link on this email to reset your password
                                                    </p>
                                                </Col>
                                            </Row>
                                            )
                                        }
                                        {!submitResult.success && (


                                            <Form
                                                className="form-horizontal"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Email</Label>
                                                            <Input
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Enter email"

                                                                onChange={handleEmailChange}
                                                                type="email"

                                                            />
                                                            {errors.email && (<label className="error mt-2 text-danger">{errors.email}</label>)}

                                                            {submitResult.error && (<label className="error mt-2 text-danger">An error occured !</label>)}
                                                        </div>


                                                        <div className="d-grid mt-4">
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                type="submit"
                                                            >
                                                                Reset your password
                                                            </button>
                                                        </div>
                                                        <div className="mt-5 text-center">
                                                            <p >
                                                                Don't have an account ?{" "}
                                                                <Link to="/register" className="fw-medium text-primary">
                                                                    {" "}
                                                                    Register{" "}
                                                                </Link>{" "}
                                                            </p>

                                                        </div>

                                                    </Col>
                                                </Row>
                                            </Form>
                                        )
                                        }
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );


}


export default MoondanceLostPassword;