
import React, {  useState,  useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { MoondanceContext } from '../../App';

function SidebarCustom(props) {

  const { darkMode } = useContext(MoondanceContext);
  const[sidebarData,setSideBarData]=useState([]);
  const { selectedAccountList } = useContext(MoondanceContext);

  const { toggleTransactionModal } = useContext(MoondanceContext);
  const { toggleNavModal } = useContext(MoondanceContext);
  


  const functionMap = {
    toggleTransactionModal: toggleTransactionModal,
    toggleNavModal: toggleNavModal
    // other functions can be added as needed
  };
 

  


  function refreshFilter(event) {
    return false;
  }
  useEffect(() => {

    const dashboardSidebar = [
      {
        label: "Views",
        isMainMenu: true,
      },
      
      {
        label: "KPIs",
        icon: "mdi mdi-desktop-mac-dashboard",
        url: "/dashboard/summary",
        isHasArrow: true,
        
      },
      {
        label: "Positions",
        icon: "fas fa-chess",
        isHasArrow: true,
        url: "/dashboard/positions",
      },
      {
        label: "Cash Flows",
        icon: "fas fa-money-check-alt",
        url: "/dashboard/cashflows",
        isHasArrow: true,
      },
    
      {
        label: "Transactions",
        icon: "mdi mdi-cash-multiple",
        isHasArrow: true,
        url: "/dashboard/transactions",
      },
     
      {
        label: "Budget",
        icon: " fas fa-piggy-bank",
        url: "/dashboard/budget",
        isHasArrow: true,
      },
      {
        label: "Charts",
        icon: "mdi mdi-chart-areaspline",
        isHasArrow: true,
        url: "/dashboard/charts",
      },
      {
        label: "Table",
        icon: "mdi mdi-table",
        isHasArrow: true,
        url: "/dashboard/table",
      },
      {
        label: "Notes",
        icon: "  bx bx-notepad",
        url: "/dashboard/notes",
        isHasArrow: true,
      },
    ];
  

    const actionsSidebar = [
    
      {
        label: "Actions",
        isMainMenu: true,
      },
      {
        label: "Add Transaction",
        icon: "mdi mdi-cash",
        isHasArrow: true,
        url: '/addTransaction'
      },
      {
        label: "Update NAV",
        icon: "mdi mdi-gold",
        isHasArrow: true,
        url: "/addNav",
      },
     
      {
        label: "Add Position",
        icon: "mdi mdi-bank-plus",
        isHasArrow: true,
        url: "/addPosition",
      },
      {
        label: "Group Nav Update",
        icon: "mdi mdi-gold",
        isHasArrow: true,
        url: '/navbulk'
      },
      {
        label: "Reporting",
        isMainMenu: true,
      },
 
    
     
     
     
     
    ];


  
    const settingsSidebar = [
      {
        label: "Settings",
        isMainMenu: true,
      },
      {
        label: "Select Account",
        icon: "mdi mdi-account-group",
        isHasArrow: true,
        url: '/selectAccount'
      },
      {
        label: "Your profile",
        icon: "mdi mdi-account-circle-outline",
        isHasArrow: true,
        url: "/userprofile",
      },
      {
        label: "Accounts Settings",
        icon: "mdi mdi-account-group",
        isHasArrow: true,
        url: "/accounts",
      },
      {
        label: "Activity log",
        icon: "mdi mdi-text-account",
        isHasArrow: true,
        url: "/logs",
      },
      {
        label: "logout",
        icon: "mdi mdi-logout",
        isHasArrow: true,
        url: "/logout",
      },
      {
        label: "Help & Support",
        isMainMenu: true,
      },
      {
        label: "Contact us",
        icon: "mdi mdi-logout",
        isHasArrow: true,
        url: "/contact",
      },


      
    ];
  
    let tempSidebar = [];
  
    if (selectedAccountList.length > 0) {
      tempSidebar = tempSidebar.concat(dashboardSidebar);
      tempSidebar = tempSidebar.concat(actionsSidebar);
    }
    
  
    tempSidebar = tempSidebar.concat(settingsSidebar);
  
    setSideBarData(tempSidebar);
  
  }, [selectedAccountList]);


  return (

    <React.Fragment>
      <div id="sidebar-menu" className={(darkMode  ? ' darkMode' :'')}>
        <ul className="metismenu list-unstyled" id="side-menu-item">
          {(sidebarData || []).map((item, key) => (
            <React.Fragment key={key}>
              {item.isMainMenu ? (
                <li className="menu-title">{props.t(item.label)}</li>
              ) : (
                <li key={key}>
                  {item.action && (
                    <Link 
                      onClick={() => { 
                        functionMap[item.action]();
                      }}
                      className={
                        (item.issubMenubadge || item.isHasArrow)
                          ? " "
                          : "has-arrow"
                      }
                    >
                      <i
                        className={item.icon}
                        style={{ marginRight: "5px" }}
                      ></i>
                      {item.issubMenubadge && (
                        <span
                          className={
                            "badge rounded-pill float-end " + item.bgcolor
                          }
                        >
                          {" "}
                          {item.badgeValue}{" "}
                        </span>
                      )}
                      <span>{props.t(item.label)}</span>
                    </Link>
                  )}

                  {item.url && (
                    <Link
                      to={item.url ? item.url : "/#"}
                      onClick={() => {

                        var body = document.body;
                        if (window.screen.width <= 998) {
                          body.classList.toggle("sidebar-enable");
                        }
                       }}
                    >
                      <i
                        className={item.icon}
                        style={{ marginRight: "5px" }}
                      ></i>
                      {item.issubMenubadge && (
                        <span
                          className={
                            "badge rounded-pill float-end " + item.bgcolor
                          }
                        >
                          {" "}
                          {item.badgeValue}{" "}
                        </span>
                      )}
                      <span>{props.t(item.label)}</span>
                    </Link>
                  )}
                  {item.subFilter && (
                    <div>
                      <Link

                        className={
                          (item.issubMenubadge || item.isHasArrow)
                            ? " "
                            : "has-arrow"
                        }
                      >
                        <i
                          className={item.icon}
                          style={{ marginRight: "5px" }}
                        ></i>
                        {(item.issubMenubadge || item.isHasArrow) && (
                          <span
                            className={
                              "badge rounded-pill float-end " + item.bgcolor
                            }
                          >
                            {" "}
                            {item.badgeValue}{" "}
                          </span>
                        )}
                        <span>{props.t(item.label)}</span>
                      </Link>
                      <ul className="sub-menu">
                        {item.subFilter.map((item, key) => (
                          <li key={key}>

                            <label className="subFilter" >
                              <input
                                id={item.id}
                                className={item.className}
                                defaultChecked={item.checked}
                                type="checkbox"

                              />
                              {item.sublabel}
                            </label>

                          </li>
                        ))}
                        <li><button className=" subFilter btn btn-sm btn-primary RefreshFilter" onClick={refreshFilter} >Refresh</button>
                        </li>
                      </ul>

                    </div>
                  )}
                  {item.subItem && (
                    <ul className="sub-menu">
                      {item.subItem.map((item, key) => (
                        <li key={key}>
                          <Link
                            to={item.link}
                            className={
                              item.subMenu && "has-arrow waves-effect"
                            }
                          >
                            {props.t(item.sublabel)}
                          </Link>
                          {item.subMenu && (
                            <ul className="sub-menu">
                              {item.subMenu.map((item, key) => (
                                <li key={key}>
                                  <Link to="#">
                                    {props.t(item.title)}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )}
            </React.Fragment >
          ))}
        </ul>

      </div>




    </React.Fragment>
  )
}

export default SidebarCustom;