import React, { useState, useContext, useEffect } from "react";
import {
    Card, CardBody, CardTitle, Col, Container, Button, Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";

import { MoondanceContext } from '../../App.js';


import AddPositionWizard from "../pages/position_add.js"


const AddPositionWizardContainer = () => {

    const { darkMode } = useContext(MoondanceContext);

    return (
        <React.Fragment>


            <div className={(darkMode ? 'page-content darkMode' : 'page-content')} >
                <Container fluid={true}>
                    <Row style={{ marginBottom: '1em' }}>
                        <h1>Add Position</h1>
                    </Row>
                    <AddPositionWizard editMode="new"  show_essentials={true} />

                </Container>
            </div>
        </React.Fragment>
    )


}
export default AddPositionWizardContainer;