
import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Col, Row, Container, Button, InputGroup } from "reactstrap";
import { Link, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import UseMoondanceApi from "../lib/api";
import MoondanceLayout from "../lib/layout";
import { MoondanceContext } from '../../App';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { testurl, formatGroupLabel, groupAndSortPositions, buildAccountParameter, buildTransactionTypeList, formatOptionLabel, formatDate, } from "../lib/formatData.js";




const AddNotes = () => {
    const moondanceApi = UseMoondanceApi();

    const params = useParams();
    const id = (params.id ? params.id : false);

    const [matching_position_update, set_matching_position_update] = useState([]);
    const [matching_position_list, set_matching_position_list] = useState([]);
    const { addAlert } = useContext(MoondanceContext);
    const [positionList, setPositionList] = useState();
    const [noteText, setNoteText] = useState('');
    const [noteTitle, setNoteTitle] = useState('');
    const { selectedAccountList } = useContext(MoondanceContext);
    const { selectedStatus } = useContext(MoondanceContext);
    const [notes, setNotes] = useState([]);
    const [selectedFund, setSelectedFund] = useState();
    const [selected_position_details, set_selected_position_details] = useState(null);

    const [submitResult, setSubmitResult] = useState(false);

    const { accountList } = useContext(MoondanceContext);
    const [all_positions_list, set_all_positions_list] = useState([]);
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  
    const [error, setError] = useState({});

    const handleChange = (html) => {
        setError({})
        setNoteText(html);
    };
    let modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    let formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    async function handle_select_position(f) {
        console.log('handle_select_position')
        setError({})
        setSelectedFund(f.value);
    }



    const fetchSelectedPositionDetails = async (id) => {
        var api_parameters = {
            account: buildAccountParameter(accountList),
            userCurrency: userCurrency,
        }
        try {
            const thisFund = await moondanceApi.fetchPositionDetails(api_parameters, id);
            return thisFund.data;

        } catch (error) {
            return false;
        }
    };
    async function fetch_position_list(selectedAccountOnly = true) {
        var api_parameters = {
            account: (selectedAccountOnly ? buildAccountParameter(selectedAccountList) : buildAccountParameter(accountList) ) ,
            status: 'All',
            userCurrency: userCurrency,
        }
        console.log('api_parameters',selectedAccountOnly, api_parameters)

        try {
            const response = await moondanceApi.fetchPositionsList(api_parameters)
               
            if (response && response.data.length > 0) {
                if(selectedAccountOnly){
                    let positionList = groupAndSortPositions(response.data)  ///SECURITY
                    setPositionList(positionList)
                } else {
                    console.log('all_positions_list',response.data)
                    set_all_positions_list(response.data)
                    
                }
            } else {
                return []
            }

        } catch (error) {
            console.log('error',error);
            return []
        }


        return []   

    }

    async function find_matching_position(this_position_id) {

        let this_matching_position_update = [];
        if(all_positions_list.length > 0){
            let this_matching_position_list = MoondanceLayout.findMatchingPositions(all_positions_list, this_position_id);
            console.log('this_matching_position_list:', this_matching_position_list);
            set_matching_position_list(this_matching_position_list);
            this_matching_position_list.map((item, index) => {
                console.log('item', item)
                this_matching_position_update.push( {
                    id: item.position_id,
                    account: item.position_account_name,
                    asset_name: item.asset_name,
                    update: false
                })
            })
       
        }
        console.log('this_matching_position_update:', this_matching_position_update);
        set_matching_position_update(this_matching_position_update)
      
    }
    function handleChangeSimilarPositionCheck(id, checkStatus) {
        console.log('handleChangeSimilarPositionCheck', id, checkStatus);
        let newupdatedMatchingPositionUpdate = { ...matching_position_update };
       
        Object.entries(newupdatedMatchingPositionUpdate).forEach(([key, item]) => {
            if (item.id === id) {
                // Update the specific position update
                console.log('set', id, 'to', checkStatus);
                newupdatedMatchingPositionUpdate[key] = {
                    id:item.id,
                    account: item.account,
                    asset_name: item.asset_name,
                    update: checkStatus
                };
            }
        });
        console.log('updatedMatchingPositionUpdate', newupdatedMatchingPositionUpdate);
       
        set_matching_position_update(newupdatedMatchingPositionUpdate);
    }
    function validForm() {
        let newError = {};
        let isValid = true;
        console.log('validForm', selectedFund, noteTitle, noteText)
        if(!selectedFund){
            newError.selectedFund = 'Please select a position'
            isValid  = false;
        }
        if(!noteTitle){
            newError.noteTitle = 'Please enter a title'
            isValid  = false;
        }   
        if(!noteText){
            newError.noteText = 'Please enter a note'
            isValid  = false;
        }
        setError(newError)
        return isValid;
        
    }

    async function postLinkNoteToPosition(note_id, position_id) {

        moondanceApi.postLinkNoteToPosition({note_id:note_id,position_id:position_id}).then(response => {
            console.log('response', response)
            setSubmitResult(true)        
            addAlert({
                status: 'success',
                title: 'Note added',
                message: 'This note has been added to position #'+position_id
            })    

        }).catch(error => {
            console.log(error);
            addAlert({
                status: 'danger',
                title: 'Failed to link note with position #'+position_id+' !',
                message: error
            })
        })

    }
    function handleSaveForm() {
        
        if(validForm()){
            let note = {
                title: noteTitle,
                text: noteText,
            }
            console.log('note', note)
            moondanceApi.postNote(note).then(response => {
                    console.log('response', response)

                    if(response.success && response.data){
                        postLinkNoteToPosition(response.data, selectedFund)
                        console.log('set_matching_position_update',matching_position_update)
                        Object.entries(matching_position_update).map(([index, item]) => {
                        
                            if(item.update){
                                postLinkNoteToPosition(response.data, item.id)
                            }
                        
                    });
                    } else {
                        console.log('error inserting new note : No ID obtained');
                    }
                    
                    
            }).catch(error => {
                console.log(error);
                addAlert({
                    status: 'danger',
                    title: 'Failed to add note  !',
                    message: error
                })
            })     
        }
    }

    useEffect(() => {
        fetch_position_list(true);
        fetch_position_list(false);
    }, [selectedAccountList, selectedStatus]);

    useEffect(() => {
        find_matching_position(selectedFund)
    }, [selectedFund,all_positions_list]);


    return (
        <React.Fragment >
            <div className='page-content' >
                <Container fluid={true}>
                    <Card>
                        <CardBody >


                            <Row>
                                <h5
                                    className="modal-title mt-0"
                                    id="myModalLabel"
                                >
                                    Add Note
                                </h5>
                            </Row>
                            <Row>
                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label >Select Position</Form.Label>
                                    {error.selectedFund && (<label className="error mt-2 text-danger">{error.selectedFund}</label>)}
                              
                                    <Select
                                        id="floatingSelectFund"
                                        value={positionList ?
                                            positionList.flatMap(group => group.options).find(option => option.value === parseInt(selectedFund))
                                            : null}
                                        onChange={(f) => { handle_select_position(f); }}
                                        placeholder="Select Fund"
                                        options={positionList}
                                        formatOptionLabel={formatOptionLabel}
                                        formatGroupLabel={formatGroupLabel}
                                        styles={MoondanceLayout.select2customStyles}
                                    />
                                    {(selected_position_details ? <p>Account :{selected_position_details.position_account_name} <br />Commitment : {MoondanceLayout.formatCurrency(selected_position_details.position_commitment_in_asset_currency, selected_position_details.asset_currency)}</p> : '')}

                                    </Form.Group>
                                    {(matching_position_list.length > 0) ? 
                                            <Form.Group as={Row} className="mb-3" > 
                                                   <Form.Label >This note can also be linked to the following positions :</Form.Label> 
                                                   {Object.entries(matching_position_update).map(([index, item]) => (
                                                         <inputGroup key={index} className="d-flex align-items-center" style={{ marginTop:'0.5em',    }}>
                                                              <input
                                                            onClick={() => (handleChangeSimilarPositionCheck(item.id,!item.update))}
                                                            type="checkbox"
                                                            checked={item.update}
                                                            style={{ marginRight: '1em'     }}
                                                        ></input>
                                                            <p  style={{ marginBottom:'0px',    }}>{item.asset_name} in account {item.account} </p>
                                                        </inputGroup>
                                                    ))}
                                                    <p  style={{ marginTop:'1em',    }}>Check the box to link this note to the corresponding position. Any changes made to the note will automatically be applied to each linked position.</p>
                                            </Form.Group> :''}
                               

                                < Form.Group as={Row} className="mb-3" >
                                    <Form.Label >Note Tile</Form.Label>
                                    {error.noteTitle && (<label className="error mt-2 text-danger">{error.noteTitle}</label>)}
                            
                                    <InputGroup>
                                    <input className="form-control" type="text" value={noteTitle} onChange={(e) => { console.log(e.target.value); setNoteTitle(e.target.value);     setError({}) }} />
                                    </InputGroup>
                                 
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label >Your Note</Form.Label>

                                    {error.noteText && (<label className="error mt-2 text-danger">{error.noteText}</label>)}
                                    <ReactQuill

                                        style={{ minHeight: '45em', marginBottom: '3em' }}
                                        value={noteText}
                                        onChange={handleChange}
                                        modules={modules}
                                        formats={formats}
                                        rows={15}
                                    />



                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" >
                                    <button
                                        type="button"
                                        className="btn btn-primary form-control"
                                        onClick={() => { handleSaveForm() }}
                                    >
                                        Save Note
                                    </button>

                                </Form.Group>
                                <hr />
                            </Row>
                        </CardBody>
                    </Card>
                </Container>

            </div>
        </React.Fragment >
    )

}
export default AddNotes;