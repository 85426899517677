

import Variables from '../lib/variables/variables.js';


const Format = {

    number_format: 'fr-FR',


    formatDate(date, format = 'dd/mm/yyyy') {
        // console.log(date)
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        // add leading zero if day or month is less than 10
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }

        if (format === 'yyyy') {
            return `${day}/${month}/${year}`;
        } else if (format === 'yyyy-mm-dd') {
            return `${year}-${month}-${day}`;

        } else {
            return `${day}/${month}/${year}`;

        }
    },
    formatDate_DMY_to_YMD(dateString) {

        if (dateString === 0) { return 0 }

        if (dateString instanceof Date) {
            // If dateString is already a Date object, format it as desired
            const year = dateString.getFullYear();
            const month = String(dateString.getMonth() + 1).padStart(2, '0');
            const day = String(dateString.getDate()).padStart(2, '0');
            return year + '-' + month + '-' + day;
        }

        var parts = dateString.split('/');
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        var year = parseInt(parts[2], 10);
        var transformedDate = year + '-' + month + '-' + day;

        //console.log('transformedDate', transformedDate)
        return transformedDate;

    },
    capitalizeFirstLetter([first, ...rest]) {
        return first.toUpperCase() + rest.join('');
    },
    formatCurrency(value, currency = 'EUR', maximumFractionDigits = 0) {
        value = parseFloat(value);
        if (!value || value === 0) {
            return "-";
        } else {
            if (currency !== null && currency !== undefined && currency !== "" && currency !== "undefined") {
                return value.toLocaleString(this.number_format, { style: "currency", currency: currency, maximumFractionDigits: maximumFractionDigits })
            } else {
                return value.toLocaleString(this.number_format)
            }
        }
    },
    formatPercentage(value, digits = 2, showNullAsValue = false) {
        value = parseFloat(value);
        if ((!value || value === 0) && !showNullAsValue) {
            return "-";
        } else {
            return value.toLocaleString(this.number_format, { style: "percent", maximumFractionDigits: digits })

        }
    },
    formatNumber(value, digits = 2) {
        value = parseFloat(value);
        if (!value || value === 0) {
            return "-";
        } else {
            return value.toLocaleString(this.number_format, { maximumFractionDigits: digits })

        }
    },
    formatValue(value, format, formatUnit = 2) {
        if (format === 'currency') { return this.formatCurrency(value, formatUnit) }
        else if (format === 'number') { return this.formatNumber(value, formatUnit) }
        else if (format === 'percentage') { return this.formatPercentage(value, formatUnit) }
        else if (format === 'timestampToDate') { return this.timestampToDateString(value); }
        else if (format === 'findInArray' && Array.isArray(formatUnit)) {
            var thisValue = formatUnit.find(item => item.value === value)
            if (thisValue) {
                return thisValue.label
            } else {
                return value
            }
        }
        else { return value }


    },
    customSelectPattern: {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#0bb197' : 'white',
            color: state.isFocused ? 'white' : 'black',
        }),
    },
    select2customStyles: {
        option: (defaultStyles, state) => {

            return {
                ...defaultStyles,
                color: state.isSelected ? Variables.colorScheme.selected_text : Variables.colorScheme.default_text,
                backgroundColor: Variables.colorScheme.main_backgroundColor,
                "&:hover": {
                    backgroundColor: Variables.colorScheme.secondary_backgroundColor, //Variables.colorScheme.secondary_backgroundColor,
                    color: Variables.colorScheme.selected_text
                },
            };
        },
        menu: (provided) => ({
            ...provided,
            backgroundColor: Variables.colorScheme.main_backgroundColor, // Replace with your desired color
        }),
        control: (defaultStyles) => ({
            ...defaultStyles,
            backgroundColor: Variables.colorScheme.main_backgroundColor,
        }),
        menuPortal: base => ({ ...base, zIndex: 99999, backgroundColor: 'red' }), //Variables.colorScheme.main_backgroundColor 
        singleValue: (defaultStyles) => ({ ...defaultStyles, color: "inherit" }),

    },
    findMaxMin(dataArray) {
        let max = Number.MIN_SAFE_INTEGER;
        let min = Number.MAX_SAFE_INTEGER;

        // Iterate through each data series
        dataArray.forEach((series) => {
            // Extract the 'data' array
            const data = series.data;

            // Find the max and min in the current 'data' array
            const currentMax = Math.max(...data);
            const currentMin = Math.min(...data);

            // Update global max and min if current max/min are greater/smaller
            if (currentMax > max) {
                max = currentMax;
            }
            if (currentMin < min) {
                min = currentMin;
            }
        });

        return { max, min };
    },


}

export default Format