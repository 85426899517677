
import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { sectorList, geographyList, assetType, getStatusLabel, formatCurrency, statusList, formatNumber, formatPercentage, buildcurrentYearChart, buildFundDetail, buildFlatSerie } from "../lib/formatData";

import MoondanceLayout from "../lib/layout";
import { MoondanceContext } from '../../App';


export default function PositionYtd(props) {

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
 // console.log('PositionYtd', props.data)

  return (

    <React.Fragment>
  
        <h4>Current Year</h4>
        {(props && props.data && props.data.currentYear ?
        MoondanceLayout.card(
          {
            title: <span>Expected call before 31/12/{MoondanceLayout.currentYear()}</span>,
            value: (props.data.currentYear.sum_real_capital_call_in_user_currency - props.data.currentYear.sum_model_capital_call_in_user_currency > 0
              ? <span className="badge bg-danger me-1">{formatCurrency(-props.data.currentYear.sum_real_capital_call_in_user_currency + props.data.currentYear.sum_model_capital_call_in_user_currency, userCurrency)}</span>
              : '-'
            ),
            value_sub: (userCurrency !== props.data.summary.asset_currency && props.data.currentYear.sum_real_capital_call_in_asset_currency - props.data.currentYear.sum_model_capital_call_in_asset_currency > 0
              ? formatCurrency(-props.data.currentYear.sum_real_capital_call_in_asset_currency + props.data.currentYear.sum_model_capital_call_in_asset_currency, props.data.summary.asset_currency)
              : null
            ),
            subText:
              <>
                <span>Already called in {props.data.currentYear.year} :<br />{formatCurrency(props.data.currentYear.sum_real_capital_call_in_user_currency, userCurrency)}</span>
                {(userCurrency !== props.data.summary.asset_currency && props.data.currentYear.sum_real_capital_call_in_asset_currency !== 0
                  ? <span><br />{formatCurrency(props.data.currentYear.sum_real_capital_call_in_asset_currency, props.data.summary.asset_currency)}</span> : '')}</>
            ,

            xlWidth: 4,
            smWidth: 4,
            xsWidth: 12,
          }
        )

        :'')}

        {(props && props.data && props.data.currentYear ?
        MoondanceLayout.card(
          {
            title: <span>Expected distribution before 31/12/{props.data.currentYear.year}</span>,
            value: (props.data.currentYear.sum_model_distribution_in_user_currency - props.data.currentYear.sum_real_distribution_in_user_currency > 0
              ? <span className="badge bg-success me-1">{formatCurrency(props.data.currentYear.sum_model_distribution_in_user_currency - props.data.currentYear.sum_real_distribution_in_user_currency, userCurrency)}</span>
              : '-'
            ),
            value_sub: (userCurrency !== props.data.summary.asset_currency && props.data.currentYear.sum_model_distribution_in_asset_currency - props.data.currentYear.sum_real_distribution_in_asset_currency > 0
              ? formatCurrency(props.data.currentYear.sum_model_distribution_in_asset_currency - props.data.currentYear.sum_real_distribution_in_asset_currency, props.data.summary.asset_currency)
              : null
            ),
            subText:
              <>
                <span>Already distributed in {props.data.currentYear.year} :<br />{formatCurrency(props.data.currentYear.sum_real_distribution_in_user_currency, userCurrency)}</span>
                {(userCurrency !== props.data.summary.asset_currency ? <span><br />{formatCurrency(props.data.currentYear.sum_real_distribution_in_asset_currency, props.data.summary.asset_currency)}</span> : '')}</>
            ,

            xlWidth: 4,
            smWidth: 4,
            xsWidth: 12,
          }
        )

        :'')}

      {(props && props.data && props.data.currentYear ?
              MoondanceLayout.card(
          {
            title: <span>Expected balance on 31/12/{props.data.currentYear.year}</span>,
            value: <span className="badge bg-info me-1">{formatCurrency(
              (
                props.data.currentYear.sum_model_capital_call_in_user_currency
                + props.data.currentYear.sum_model_distribution_in_user_currency
              ), userCurrency)}</span>,
            value_sub: (userCurrency !== props.data.summary.asset_currency
              ? formatCurrency(
                props.data.currentYear.sum_model_capital_call_in_asset_currency
                + props.data.currentYear.sum_model_distribution_in_asset_currency
                , props.data.summary.asset_currency
              )
              : null
            ),
            subText:<>
                <span>Current balance in {props.data.currentYear.year} :<br />{formatCurrency(props.data.currentYear.sum_real_balance_in_user_currency, userCurrency)}</span>
                {(userCurrency !== props.data.summary.asset_currency ? <span><br />{formatCurrency(props.data.currentYear.sum_real_balance_in_asset_currency, props.data.summary.asset_currency)}</span> : '')}</>
            ,

            xlWidth: 4,
            smWidth: 4,
            xsWidth: 12,
              }
        )

        :'')}
    </React.Fragment >
  );
};