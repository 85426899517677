
import React, { useState, useContext, useEffect, useRef } from "react";
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Row, Col, InputGroup } from "reactstrap";
import Select from "react-select";


//Date select  library
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { MoondanceContext } from '../../App.js';
import MoondanceLayout from "../lib/layout.js";
import UseMoondanceApi from "../lib/api.js";
//{ moondanceApi.fetchPositionListForUser, moondanceApi.getLastExchangeRate, addNewTransaction, updateTransaction, fetchFundsList, fetchFundDetails } from "../lib/api";
import { testurl, formatGroupLabel, groupAndSortPositions, buildAccountParameter, buildTransactionTypeList, formatOptionLabel, formatDate, } from "../lib/formatData.js";
import { useNavigate, useParams } from 'react-router-dom';
import { use } from "i18next";




const AddTransactionShortForm = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const moondanceApi = UseMoondanceApi();

    const { selectedAccountList } = useContext(MoondanceContext);
    const { addAlert } = useContext(MoondanceContext);
    const [showForm, setShowForm] = useState(false);
    //const { selectedAccountList } = useContext(MoondanceContext);
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);

    const { accountList } = useContext(MoondanceContext);
    //const { selectedStatus = '' } = useContext(MoondanceContext);
    const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);

    const [positionList, setPositionList] = useState();

    const [matching_position_list, set_matching_position_list] = useState([]);

    const [matching_position_update, set_matching_position_update] = useState([]);

    const [fundDefaultValue, setFundDefaultValue] = useState((props.data ? { id: props.data.position_id, label: props.data.fund_name } : null));
    const [currencyList, setCurrencyList] = useState([]);
    const [currencyDefaultValue, setCurrencyDefaultValue] = useState([{ id: 'EUR', value: 'EUR', label: 'EUR' }]);
    const [transactionId, setTransactionId] = useState(null);
    const [all_positions_list, set_all_positions_list] = useState([]);
    const [selected_position_details, set_selected_position_details] = useState(null);

    const [transactionTypeList, setTransactionType] = useState(buildTransactionTypeList());
    const [transactionSign, setTransactionSign] = useState('-');
    const [selectedTransactionType, setSelectedTransactionType] = useState(null);
    const [defaultTransactionType, setDefaultTransactionType] = useState(null);
    const [exchangeRate, setExchangeRate] = useState(1);

    const [selectedFund, setSelectedFund] = useState((props.data && props.data.position_id ? props.data.position_id : null))
    const [selectedDate, setSelectedDate] = useState((props.data && props.data.date_int ? props.data.date_int : null));
    const [selectedCurrency, setSelectedCurrency] = useState((props.data && props.data.asset_currency ? props.data.asset_currency : 'EUR'));
    const [amount, setAmount] = useState(0);
    const [shares, setShares] = useState(0);
    const [amountPerShare, setAmountPerShare] = useState(0);

    const [note, setNote] = useState((props.data && props.data.note ? props.data.note : ''));
    const [bank, setBank] = useState('');
    // const [xrate, setXrate] = useState(props.data && props.data.xrate ? props.data.xrate : 1);
    const [navupdate, setUpdateNav] = useState(false);
    const [submitResult, setSubmitResult] = useState(false);
    const [deleteStep, setDeleteStep] = useState(0);
    const [loadingMatchingPosition, setLoadingMatchingPosition] = useState(false);


    const params = useParams();
    const id = params.id;

    const [errors, setErrors] = useState({});
    //set_transaction_modal( props.modalStatus)
    const flatpickrRef = useRef(null);

    useEffect(() => {
        setCurrencyList(currencyList)
    }, [currencyList])

    const customSelectPattern = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#0bb197' : 'white',
            color: state.isFocused ? 'white' : 'black',
        }),

    };
    async function fetchCurrencyList() {
        try {
            var date = MoondanceLayout.formatDate(new Date(), 'yyyy-mm-dd')
            var data = await moondanceApi.getLastExchangeRate(date);
            //console.log('fetchCurrencyList', data)

        } catch (error) {
            //console.log(error);
        }
    }
    function handleCloseForm() {
        setShowForm(false)
    }

    function handleOpenForm() {
        setErrors({})
        setSubmitResult(false)
        setShowForm(true)
    }
    function handleSelectDate(selectedDates, dateStr, instance) {

        setSubmitResult(false)
        setSelectedDate(dateStr);
    }

    async function fetch_all_position_list() {
        //console.log('fetch_all_position_list', accountList)
        var api_parameters = {
            account: buildAccountParameter(accountList),
            status: 'All',
            userCurrency: userCurrency,
        }
        //console.log('api_parameters', api_parameters)

        try {
            const response = await moondanceApi.fetchPositionsList(api_parameters)

            if (response.data.length > 0) {
                set_all_positions_list(response.data)
                //console.log('fetch_all_position_list', response.data)
                return response.data

            } else {
                return []
            }

        } catch (error) {
            console.log(error);
            return []
        }

        setLoading(false)
        return []

    }




    async function handle_select_position(f) {
        set_matching_position_list({});
        console.log('handle_select_position', f)
        setFundDefaultValue(f)
        setSubmitResult(false)
        setSelectedFund(f.value);
        find_matching_position(f.value)
    }

    async function find_matching_position(this_position_id) {
        setLoadingMatchingPosition(true)
        //console.log('find_matching_position');

        // Wait for both fetch_all_position_list and fetchSelectedPositionDetails to complete
        const [new_all_positions_list, this_position_details] = await Promise.all([
            (all_positions_list.length === 0 ? fetch_all_position_list() : all_positions_list),
            fetchSelectedPositionDetails(this_position_id)
        ]);

        set_all_positions_list(new_all_positions_list);
        set_selected_position_details(this_position_details);

        //console.log('all_positions_list fetched:', all_positions_list);
        //console.log('this_position_details fetched:', this_position_details);

        // Update state based on fetched details
        if (this_position_details && this_position_details.asset_currency) {
            setSelectedCurrency(this_position_details.asset_currency);
            set_selected_position_details(this_position_details);
        }

        // Find matching positions
        let this_matching_position_list = MoondanceLayout.findMatchingPositions(new_all_positions_list, this_position_id);
        //console.log('this_matching_position_list:', this_matching_position_list);

        set_matching_position_list(this_matching_position_list);

        // Update matching position updates
        if (this_matching_position_list && this_matching_position_list.length > 0) {
            let this_matching_position_update = [];

            this_matching_position_list.map((item, index) => {
                let this_amount = amount * (item.position_commited_in_asset_currency / this_position_details.position_commitment_in_asset_currency);
                let this_shares = shares * (item.position_commited_in_asset_currency / this_position_details.position_commitment_in_asset_currency);
                this_matching_position_update.push({
                    id: item.position_id,
                    amount: this_amount,
                    shares: this_shares,
                    name: item.asset_name,
                    commitment: item.position_commited_in_asset_currency,
                    account_name: item.position_account_name,
                    update: (matching_position_update && matching_position_update[item.position_id] && matching_position_update[item.position_id].update ? matching_position_update[item.position_id].update : false)
                });
            });

            //console.log('this_matching_position_update', this_matching_position_update);
            set_matching_position_update(this_matching_position_update);
            setLoadingMatchingPosition(false)
        } else {
            set_matching_position_update({});
            setLoadingMatchingPosition(false)
        }
        setLoading(false)

    }




    function handleDelete() {
        if (deleteStep === 0) {
            setDeleteStep(1)
        } else if (deleteStep === 1) {

            setDeleteStep(0)
            const requestData = {}
            requestData.transaction_id = parseInt(transactionId)

            moondanceApi.deleteTransaction(requestData).then(response => {
                if (response.success) {
                    addAlert({
                        status: 'success',
                        title: 'Transaction Deleted',
                        message: 'This transaction has been deleted'
                    })
                    setLastUpdate(Math.floor(Date.now()))
                    if (props.closeModal) {
                        props.closeModal()
                    }
                } else {
                    addAlert({
                        status: 'danger',
                        title: 'Transaction has NOT been deleted',
                        message: 'This transaction has NOT been deleted'
                    })
                    if (props.closeModal) {
                        props.closeModal()
                    }
                }
            })
                .catch(error => {

                    addAlert({
                        status: 'danger',
                        title: 'Transaction Deleted',
                        message: 'This transaction has NOT been deleted'
                    })
                    if (props.closeModal) {
                        props.closeModal()
                    }
                });




        }

    }



    const fetchSelectedPositionDetails = async (id) => {
        var api_parameters = {
            account: buildAccountParameter(accountList),
            userCurrency: userCurrency,
        }
        try {
            const thisFund = await moondanceApi.fetchPositionDetails(api_parameters, id);
            return thisFund.data;

        } catch (error) {
            return false;
        }
    };
    function handleSelectCurrency(c) {
        setSubmitResult(false)
        setSelectedCurrency(c.id);
        setCurrencyDefaultValue(c)
    }
    function handleSelectTransactionType(t) {
        setSubmitResult(false)
        setDefaultTransactionType(t)
        setSelectedTransactionType(t.value);

        let newErrors = errors
        var matchingRowthisTransactionType = MoondanceLayout.transactionType.find(item => item.value === t.value);
        console.log('matchingRowthisTransactionType', matchingRowthisTransactionType)

        if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '-') {
            setTransactionSign('-')
        } else if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '+') {
            setTransactionSign('+')
        }
        if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '-' && amount > 0) {
            newErrors.amount = 'A negative value is required'
            setErrors(newErrors)
            setAmount(-amount)
        } else if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '+' && amount < 0) {
            newErrors.amount = 'A positive value is required'
            setTransactionSign('+')

            setErrors(newErrors)
            setAmount(-amount)
        }
        formIsValid('selectedTransactionType')
    }



    function handleAmountChange(e) {
        let numericValue = parseFloat(e.target.value);

        let newErrors = errors
        var matchingRowthisTransactionType = MoondanceLayout.transactionType.find(item => item.value === selectedTransactionType);
        if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '-' && numericValue > 0) {
            newErrors.amount = 'A negative value is required'
            setTransactionSign('-')
            setErrors(newErrors)
            numericValue = -numericValue
        } else if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '+' && numericValue < 0) {
            newErrors.amount = 'A positive value is required'
            setTransactionSign('+')
            setErrors(newErrors)
            numericValue = -numericValue
        }

        setErrors(newErrors)
        setSubmitResult(false)
        setAmount(numericValue);
        if (shares !== 0) {
            setAmountPerShare(numericValue / shares)
        }


        if (matching_position_list && matching_position_list.length > 0) {
            let this_matching_position_update = []
            matching_position_list.map((item, index) => {
                let this_amount = numericValue * (item.position_commited_in_asset_currency / selected_position_details.position_commitment_in_asset_currency)
                let this_shares = parseFloat(shares * (item.position_commited_in_asset_currency / selected_position_details.position_commitment_in_asset_currency))
                this_matching_position_update[item.position_id] = {
                    id: item.position_id,
                    amount: this_amount,
                    name: item.asset_name,
                    shares: this_shares,
                    account_name: item.position_account_name,
                    commitment: item.position_commited_in_asset_currency,
                    update: (item.update ? item.update : false)
                }
            })
            set_matching_position_update(this_matching_position_update)
        }
    }


    function handleSharesChange(e) {

        let shareCount = parseFloat(e.target.value);
        if (transactionSign === '-') {  // if amount is negative, shares should be positive (new shares received)
            if (shareCount < 0) { shareCount = -shareCount }
        } else if (transactionSign === '+') {  // if amount is positive, shares should be negative (shares sold)
            if (shareCount > 0) { shareCount = -shareCount }
        }
        //console.log('handleSharesChange', e.target.value)
        //console.log('Amount', amount)
        setSubmitResult(false)
        setShares(shareCount);
        setAmountPerShare(Math.abs(amount / e.target.value))



        if (matching_position_list && matching_position_list.length > 0) {
            let this_matching_position_update = []
            matching_position_list.map((item, index) => {
                let this_amount = amount * (item.position_commited_in_asset_currency / selected_position_details.position_commitment_in_asset_currency)
                let this_shares = parseFloat(e.target.value * (item.position_commited_in_asset_currency / selected_position_details.position_commitment_in_asset_currency))
                this_matching_position_update.push({
                    id: item.position_id,
                    amount: this_amount,
                    name: item.asset_name,
                    shares: this_shares,
                    account_name: item.position_account_name,
                    commitment: item.position_commited_in_asset_currency,
                    update: (matching_position_update[index] ? matching_position_update[index] : true)
                })
            })
            console.log(matching_position_update, this_matching_position_update)
            set_matching_position_update(this_matching_position_update)
        }

    }

    function handleAmountPerShareChange(e) {
        let numericValue = parseFloat(e.target.value);
        setSubmitResult(false)
        setAmountPerShare(numericValue);
        if (shares !== 0) {
            setAmount(numericValue * shares)
        }

    }


    function handleNoteChange(e) {
        setSubmitResult(false)
        setNote(e.target.value);
    }

    function handleBankChange(e) {
        setSubmitResult(false)
        setBank(e.target.value);
    }
    function handleUpdateNavChange(e) {
        setSubmitResult(false)
        setUpdateNav(e.target.checked);
    }
    function handleChangeSimilarPositionCheck(id, checkStatus) {
        let newupdatedMatchingPositionUpdate = { ...matching_position_update };
        //console.log('updatedMatchingPositionUpdate', newupdatedMatchingPositionUpdate);
        Object.entries(newupdatedMatchingPositionUpdate).forEach(([key, item]) => {
            if (item.id === id) {
                // Update the specific position update
                console.log('set', id, 'to', checkStatus);
                newupdatedMatchingPositionUpdate[key] = {
                    ...item,
                    update: checkStatus
                };
            }
        });
        set_matching_position_update(newupdatedMatchingPositionUpdate);
    }


    function formIsValid(ignore = null) {
        let formIsValid = true;
        const newErrors = {};
        if (!selectedFund && ignore !== 'selectedFund') {
            newErrors.fund = 'Select a position';
            formIsValid = false;
        }
        if (!selectedTransactionType && ignore !== 'selectedTransactionType') {
            newErrors.type = 'Select a type of transaction'
            formIsValid = false;
        }
        if (!selectedCurrency && ignore !== 'selectedCurrency') {
            newErrors.currency = 'Select a currency'
            formIsValid = false;
        }
        /* if (!amount && ignore !== 'amount') {
             newErrors.amount = 'Specify the amount'
             formIsValid = false;
         }*/
        if (selectedTransactionType && ignore !== 'selectedTransactionType') {
            var matchingRowthisTransactionType = MoondanceLayout.transactionType.find(item => item.value === selectedTransactionType);
            if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '-' && amount > 0) {
                newErrors.amount = 'A negative value is required'
                setAmount(-amount)
            } else if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '+' && amount < 0) {
                newErrors.amount = 'A positive value is required'
                setAmount(-amount)
            }
        }
        setErrors(newErrors)
        if (formIsValid) {
            return true;
        } else {
            return false
        }
    }
    function handleSaveForm() {
        const newErrors = {};
        const requestData = {}

        if (formIsValid()) {
            requestData.position_id = parseInt(selectedFund)
            if (!selectedDate) {
                requestData.date = formatDate(new Date(), 'yyyy-mm-dd')
            } else {
                var parts = selectedDate.split('/');
                var newDateStr = `${parts[2]}-${parts[1]}-${parts[0]}`;
                requestData.date = newDateStr
            }
            requestData.type = selectedTransactionType
            requestData.currency = selectedCurrency
            requestData.value = parseFloat(amount)
            requestData.shares = shares;
            requestData.navupdate = navupdate;
            requestData.note = note;
            requestData.bank = bank;

            const timestamp = Date.now();

            //console.log('requestData', requestData)
            //console.log('matching_position_update', matching_position_update)

            if (transactionId) {
                console.log('updateTransaction')
                moondanceApi.updateTransaction(transactionId, requestData).then(response => {
                    if (response.success) {
                        setErrors({})
                        addAlert({
                            status: 'success',
                            title: 'Transaction Update',
                            message: 'This transaction has been updated'
                        })
                        setSubmitResult(response)
                        console.log(submitResult)
                        setLastUpdate(timestamp)

                    } else {
                        console.log('response', response)
                        addAlert({
                            status: 'danger',
                            title: 'Update Transaction Failed  position ' + requestData.position_id + ' !',
                            message: response.error
                        })
                        response.successMessage = 'This transaction has  NOT been successfully added !'

                        setSubmitResult(response)
                        console.log(submitResult)
                        setLastUpdate(timestamp)
                        setShowForm(false)

                    }
                })
                    .catch(error => {
                        addAlert({
                            status: 'danger',
                            title: 'Update Transaction Failed  position ' + requestData.position_id + ' !',
                            message: error
                        })
                        setErrors({})
                        setSubmitResult(error)
                        console.log(submitResult)

                    });
                if (props.closeModal) {
                    props.closeModal()
                }
            } else {

                console.log('addNewTransaction', matching_position_update)
                if (typeof matching_position_update === 'object' && !Array.isArray(matching_position_update)) {

                    Object.keys(matching_position_update).forEach(key => {

                        let this_request_update = {
                            value: matching_position_update[key].amount,
                            type: requestData.type,
                            shares: matching_position_update[key].shares,
                            currency: requestData.currency,
                            bank: requestData.bank,
                            note: requestData.note,
                            navupdate: requestData.navupdate,
                            position_id: matching_position_update[key].id,
                            date: requestData.date,
                        }
                        moondanceApi.addNewTransaction(this_request_update).then(response => {
                            setErrors({})
                            addAlert({
                                status: 'success',
                                title: 'Add Transaction',
                                message: 'A transaction has been added to position ' + this_request_update.position_id + ' !'
                            })

                        }).catch(error => {
                            addAlert({
                                status: 'danger',
                                title: 'Add Transaction Failed Position:' + this_request_update.position_id,
                                message: error
                            })
                        });
                    })
                } else {
                    console.log('matching_position_update is not array')
                }
                moondanceApi.addNewTransaction(requestData).then(response => {

                    if (response.success) {
                        setErrors({})
                        addAlert({
                            status: 'success',
                            title: 'Add Transaction',
                            message: 'A transaction has been added to position ' + requestData.position_id + ' !'
                        })


                        response.successMessage = 'This transaction has been successfully added !'
                        setSubmitResult(response)
                        console.log(submitResult)
                        setLastUpdate(timestamp)
                        setShowForm(false)

                        navigate('/position/' + selectedFund);

                    } else {
                        console.log('response', response)
                        addAlert({
                            status: 'danger',
                            title: 'Add Transaction Failed  position ' + requestData.position_id + ' !',
                            message: (response.error ? response.error : 'An error occured')
                        })
                        response.successMessage = 'This transaction has  NOT been successfully added !'

                        setSubmitResult(response)
                        console.log(submitResult)
                        setLastUpdate(timestamp)
                        setShowForm(false)

                    }
                })
                    .catch(error => {
                        setErrors({})
                        setSubmitResult(error)
                        console.log(submitResult)
                        setShowForm(false)
                        addAlert({
                            status: 'danger',
                            title: 'Add Transaction Failed  position ' + requestData.position_id + ' !',
                            message: error
                        })

                    });
            }

            setLastUpdate(Math.floor(Date.now()))
            if (props.closeModal) {
                props.closeModal()
            }

        } else {
            console.log('isError')

        }

    }





    //set default date to today if it is empty
    useEffect(() => {
        if (!selectedDate) {
            var date = formatDate(new Date())
            setSelectedDate(date)
        }
    }, [selectedDate]);

    useEffect(() => {
        setLoading(true)
        if (selectedFund) {
            fetch_all_position_list().then((fundList) => {
                find_matching_position(selectedFund)
                console.log('fetch_all_position_list', fundList.find(item => item.position_id === parseInt(selectedFund)), fundList)

                if( fundList.find> 0 && fundList.find(item => item.id === selectedFund).asset_currency){
                   
                   setSelectedCurrency(fundList.find(item => item.id === selectedFund).asset_currency)
                }   
            })
        }

        setLoading(false)
    }, [selectedFund]);


    useEffect(() => {

        fetchCurrencyList()

        //console.log('Adding transaction', props, params)

        //When editing a Transaction 
        if (props && props.data && props.data.id && props.data.id !== transactionId) {
            //console.log('EDITING TRANSACTION props.data', params, props.data)
            setTransactionId(props.data.id)
            if (props.data.position_id) {
                setSelectedFund(props.data.position_id)
            }
            if (props.data.date) {
                setSelectedDate(props.data.date)
            }
            if (props.data.type) {
                setSelectedTransactionType(props.data.type)
                var matchingRowthisTransactionType = MoondanceLayout.transactionType.find(item => item.value === props.data.type);
                if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '-') {
                    setTransactionSign('-')
                } else if (matchingRowthisTransactionType && matchingRowthisTransactionType.sign === '+') {
                    setTransactionSign('+')
                }
            }
            if (props.data.value_in_transaction_currency) {
                setAmount(props.data.value_in_transaction_currency)
            }
            if (props.data.bank) {
                setBank(props.data.bank)
            }
            if (props.data.transaction_currency) {
                setSelectedCurrency(props.data.transaction_currency)
                console.log('matchingRow', currencyList)

                // Filtering the currency list to include only matching currencies
                let matchingCurrencies = currencyList.filter(item =>
                    item.id === props.data.transaction_currency || item.id === props.data.asset_currency
                );
                setCurrencyList(matchingCurrencies);


            }
            if (props.data.shares) {
                setShares(props.data.shares)
                setAmountPerShare(props.data.value_in_transaction_currency / props.data.shares)
            }
        } else if (params.id && !selectedFund) {
            //console.log('Adding transaction with known position id', params.id, props.data)
            setSelectedFund(params.id)


        } else if (!selectedFund && !params) {
            //console.log('Adding transaction with NO position id')

            setTransactionId(null)
        }

    }, [props.data, params])



    useEffect(() => {
        if (selectedAccountList && selectedAccountList.length > 0) {
            var api_parameters = {
                account: buildAccountParameter(selectedAccountList),
                status: '1,',
                userCurrency: userCurrency,
            }
            try {
                moondanceApi.fetchPositionsList(api_parameters).then(response => {
                    if (response.data && response.data.length > 0) {
                        let filteredPositionList = response.data.filter(item => item.user_can_add_transaction === 1)
                        let positionList = groupAndSortPositions(filteredPositionList)  ///SECURITY
                        setPositionList(positionList)

                    }
                    /*   setTransactionType(buildTransactionTypeList())
                       if (props.data && props.data.fund_id) {
                           setSelectedFund(parseInt(props.data.fund_id))
                           setFundDefaultValue(response.data.find(item => item.id === (parseInt(props.data.fund_id))));
                       }*/
                })
            }
            catch (error) {
                console.log(error);
            }
        }
    }, [selectedAccountList])



    useEffect(() => {
        if (props.show) {
            setShowForm(props.show)
        }

    }, [props]);


    useEffect(() => {
        console.log('Matching positions :', matching_position_update)
    }, [matching_position_update])



    return (

        <React.Fragment>

            {(loading ?
                <p >
                    <span className="spinner-border text-primary" style={{ height: '1em', width: '1em', marginRight: '1em' }} role="status"></span>
                    Loading  positions</p>
                :
                <>
                    {((showForm || (props && props.plainPage)) ?

                        <Card >
                            <CardBody >

                                {((props && !props.isModal) ?
                                    <Row>
                                        <h5
                                            className="modal-title mt-0"
                                            id="myModalLabel"
                                        >
                                            Add Transaction
                                        </h5>

                                        {((props && !props.plainPage) ?
                                            <button
                                                type="button"
                                                onClick={handleCloseForm}
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            : '')}
                                        <hr />
                                    </Row> : ''
                                )}

                                <Row>
                                    <Form>

                                        <div className="mb-3" id="add_transaction_delete_transaction_group" style={{ display: 'none' }}>
                                            <label id="add_transaction_confirm_delete-helper"
                                                className=" mt-2 text-danger">Please type "DELETE" to confirm</label>

                                            <input className="form-control" type="text" id="add_transaction_confirm_delete" required
                                                placeholder="Please type DELETE to confirm" />
                                            <button type="button" id="delete_transaction_submit_button"
                                                className="form-control btn btn-lg btn-danger delete_transaction_submit_button">Delete
                                                this transaction !</button>

                                        </div>

                                        <Row>
                                            <Form.Group as={Row} className="mb-3" >
                                                <Form.Label column sm="4">Select Position</Form.Label>
                                                <Col sm="8">
                                                    <Select
                                                        id="floatingSelectFund"
                                                        value={positionList ?
                                                            positionList.flatMap(group => group.options).find(option => option.value === parseInt(selectedFund))
                                                            : null}
                                                        onChange={(f) => { handle_select_position(f); }}
                                                        placeholder="Select Fund"
                                                        options={positionList}
                                                        formatOptionLabel={formatOptionLabel}
                                                        formatGroupLabel={formatGroupLabel}
                                                        styles={MoondanceLayout.select2customStyles}
                                                    />
                                                    {(selected_position_details ? <p>Account :{selected_position_details.position_account_name} <br />Commitment : {MoondanceLayout.formatCurrency(selected_position_details.position_commitment_in_asset_currency, selected_position_details.asset_currency)}</p> : '')}
                                                    {(loadingMatchingPosition ?
                                                        <p >
                                                            <span className="spinner-border text-primary" style={{ height: '1em', width: '1em', marginTop: '1em', marginRight: '1em' }} role="status"></span>
                                                            Loading matching positions</p>
                                                        : '')}

                                                </Col>
                                            </Form.Group>

                                            <hr />
                                        </Row>

                                        <Row>
                                            <Form.Group as={Row} className="mb-3" >
                                                <Form.Label column sm="4">Date</Form.Label>
                                                <Col sm="8">
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control "
                                                            onChange={handleSelectDate}
                                                            value={selectedDate}
                                                            options={{
                                                                clickOpens: false,  // ensure this is true
                                                                allowInput: true,   // ensure direct input is allowed
                                                                dateFormat: "d/m/Y",
                                                            }}
                                                            ref={flatpickrRef}

                                                        />
                                                        <div className="input-group-append" onClick={() => flatpickrRef.current.flatpickr.open()}>
                                                            <span className="input-group-text">
                                                                <i className="mdi mdi-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </InputGroup>
                                                    {errors.date && (<label className="error mt-2 text-danger">{errors.date}</label>)}


                                                    {(props.isModal ? '' : <p className="card-title-desc" style={{ marginTop: '1em' }}>If the transaction date is set in the future and involves a foreign currency, the current exchange rate will be used until the exact rate can be determined on the future date. Please note that transactions scheduled for the future may affect the reporting of your portfolio.</p>)}

                                                </Col>
                                            </Form.Group>
                                            < Form.Group as={Row} className="mb-3" >
                                                <Form.Label column sm="4">Type of transaction</Form.Label>
                                                <Col sm="8">
                                                    <Select
                                                        id="add_transaction_type"
                                                        onChange={(t) => {
                                                            handleSelectTransactionType(t);
                                                        }}
                                                        value={
                                                            transactionTypeList.find(option => option.value === selectedTransactionType)
                                                        }
                                                        options={transactionTypeList}
                                                        formatOptionLabel={formatOptionLabel}

                                                        styles={MoondanceLayout.select2customStyles}

                                                    />
                                                    {errors.type && (<label className="error mt-2 text-danger">{errors.type}</label>)}

                                                </Col>
                                            </Form.Group>

                                            < Form.Group as={Row} className="mb-3" >

                                                <Form.Label column sm="4">
                                                    {(transactionSign === '-' ? 'Additional Shares' : (transactionSign === '+' ? 'Repurchased Shares' : 'Additional or repurchased Shares'))}
                                                </Form.Label>
                                                <Col sm="8">
                                                    <input className="form-control" type="number" value={shares} onChange={(e) => { handleSharesChange(e); }} />
                                                    {errors.shares && (<label className="error mt-2 text-danger">{errors.shares}</label>)}
                                                    {(props.isModal ? '' : <p className="card-title-desc" style={{ marginTop: '1em' }}>Depending on the transaction and the structure of the asset, transactions may result in additional shares being issued, as in the case of a capital call, or shares being repurchased in the event of a distribution. Other structures might not involve any change in the number of shares owned but may simply increase or decrease the value of each already owned share. In this latter scenario, the corresponding form input should be entered as "0" </p>)}
                                                </Col>

                                            </Form.Group>
                                            < Form.Group as={Row} className="mb-3" >

                                                <Form.Label column sm="4">Total Amount {props && props.data && props.data.summary && props.data.summary.asset_currency ? '(' + props.data.summary.asset_currency + ')' : ''}</Form.Label>
                                                <Col sm="8">

                                                    <InputGroup>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={amount}
                                                            onChange={(e) => { handleAmountChange(e); }}
                                                        />
                                                        <span className ="input-group-text" >{selectedCurrency}</span>
                                                   
                                                    </InputGroup>
                                                    {errors.amount && (<label className="error mt-2 text-danger">{errors.amount}</label>)}
                                                    {(props.isModal ? '' : <p className="card-title-desc" style={{ marginTop: '1em' }}>Please indicate the total amount for this transaction. Note that the transaction must be entered in the currency of the asset.</p>)}
                                                </Col>
                                            </Form.Group>



                                            < Form.Group as={Row} className="mb-3" >
                                                <Form.Label column sm="4">Amount per share</Form.Label>
                                                <Col sm="8">
                                                    <InputGroup>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            value={amountPerShare}
                                                            onChange={(e) => { handleAmountPerShareChange(e); }}
                                                        />
                                                    <span className ="input-group-text" >{selectedCurrency}</span>
                                                    </InputGroup>
                                                    {errors.amountPerShare && (<label className="error mt-2 text-danger">{errors.amountPerShare}</label>)}


                                                    {(shares > 0 ?
                                                        <p>Amount per share : {MoondanceLayout.formatCurrency(amountPerShare, selectedCurrency)}</p> :
                                                        (props.isModal ? '' : <p className="card-title-desc" style={{ marginTop: '1em' }}>Since the number of additional or repurchased shares is set to zero, the per-share amount is calculated based on previously owned shares and is provided for informational purposes. The total number of pre-owned shares is calculated from the initial quantity of shares or from the number specified in the most recent available Net Asset Value, adjusted for any additional or repurchased shares from earlier transactions related to this position.</p>)

                                                    )}
                                                </Col>

                                            </Form.Group>

                                            < Form.Group as={Row} className="mb-3" >
                                                <Form.Label column sm="4">Bank (optional)</Form.Label>
                                                <Col sm="8">
                                                    <input className="form-control" type="text" value={bank} onChange={(e) => { handleBankChange(e); }} />
                                                </Col>
                                            </Form.Group>

                                            < Form.Group as={Row} className="mb-3" >
                                                <Form.Label column sm="4">Note</Form.Label>
                                                <Col sm="8">
                                                    <input className="form-control" type="text" value={note} onChange={(e) => { handleNoteChange(e); }} />
                                                    {(props.isModal ? '' : <p className="card-title-desc" style={{ marginTop: '1em' }}>The Bank and Note fields are strictly for reporting and reference purposes</p>)}
                                                </Col>

                                            </Form.Group>
                                            < Form.Group as={Row} className="mb-3" >

                                                <Form.Label column sm="4"> </Form.Label>
                                                <Col sm="8">
                                                    <input
                                                        type="checkbox"
                                                        onChange={handleUpdateNavChange} />&nbsp; Check this box to update NAV with this transaction amount
                                                    {(props.isModal ? '' : <p className="card-title-desc" style={{ marginTop: '1em' }}>By checking this field, the system will estimate the new current net asset value of this position based on the last known value, adjusted for any subsequent transactions.</p>)}

                                                </Col >


                                            </Form.Group>
                                            {(loadingMatchingPosition ?
                                                < Form.Group as={Row} className="mb-3" >

                                                    <Col lg="12">
                                                        <p >
                                                            <span className="spinner-border text-primary" style={{ height: '1em', width: '1em', marginRight: '1em' }} role="status"></span>
                                                            Loading matching positions</p>
                                                    </Col>
                                                </Form.Group>
                                                : '')}
                                            {(!loadingMatchingPosition && !transactionId && matching_position_update && Object.keys(matching_position_update).length > 0 ?
                                                < Form.Group as={Row} className="mb-3" >
                                                    <Col lg="12">
                                                        <h5>Similar Positions in Other Accounts</h5>
                                                        <p>There are similar positions available in different accounts to which you have access.
                                                            You can add this transaction to those positions by selecting the corresponding checkboxes.
                                                            The transaction amount will be recalculated based on the commitment of each position.</p>
                                                    </Col>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Update </th>
                                                                <th>Account</th>
                                                                <th>Shares (+/-)</th>
                                                                <th>Amount/Shares</th>
                                                                <th>Total Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {Object.entries(matching_position_update).map(([index, item]) => (
                                                                <tr key={item.id}>
                                                                    <td>

                                                                        <input
                                                                            onClick={() => (handleChangeSimilarPositionCheck(item.id, !item.update))}
                                                                            type="checkbox"
                                                                            checked={item.update}
                                                                        >
                                                                        </input></td>
                                                                    <td>{item.id} - {item.name} - {item.account_name}<br />Commitment :{MoondanceLayout.formatCurrency(item.commitment, selectedCurrency)}</td>

                                                                    <td> {MoondanceLayout.formatNumber(shares * parseFloat((item.commitment / selected_position_details.position_commitment_in_asset_currency)))}</td>
                                                                    <td>{MoondanceLayout.formatCurrency(amountPerShare, selectedCurrency)}</td>

                                                                    <td> {MoondanceLayout.formatCurrency(amount * parseFloat((item.commitment / selected_position_details.position_commitment_in_asset_currency)), selectedCurrency)}</td>


                                                                </tr>

                                                            ))}
                                                        </tbody>

                                                    </table>

                                                </Form.Group>
                                                : ''

                                            )}
                                            < Form.Group as={Row} className="mb-3" >
                                                <Col lg="12">

                                                    <button
                                                        type="button"
                                                        className="btn btn-primary form-control"
                                                        onClick={() => { handleSaveForm() }}
                                                    >
                                                        Save transaction
                                                    </button>
                                                </Col>
                                            </Form.Group>
                                            {(transactionId && deleteStep === 0 ?
                                                < Form.Group as={Row} className="mb-3" >
                                                    <Col lg="12">

                                                        <button
                                                            type="button"
                                                            className="btn btn-danger form-control"
                                                            onClick={() => { handleDelete() }}
                                                        >
                                                            Delete transaction
                                                        </button>
                                                    </Col>
                                                </Form.Group> : '')}
                                            {(transactionId && deleteStep === 1 ?
                                                < Form.Group as={Row} className="mb-3" >
                                                    <Col lg="12">

                                                        <button
                                                            type="button"
                                                            className="btn btn-danger form-control"
                                                            onClick={() => { handleDelete() }}
                                                        >
                                                            Confirm Delete Tansaction !
                                                        </button>
                                                    </Col>
                                                </Form.Group> : '')}



                                        </Row>
                                    </Form>

                                </Row>

                            </CardBody>
                        </Card >
                        :

                        (props.showOpenButton ?
                            <>

                                <button
                                    className="btn btn-info btn-sm "
                                    type="button"
                                    onClick={handleOpenForm}
                                > Add new transaction
                                </button>
                                <br /> </>
                            : '')
                    )}
                    <br /></>
            )}
        </React.Fragment >




    )

};

export default AddTransactionShortForm