import React, { useState, useEffect } from "react";
import Routes from "./Routes/index";

//Styles

import './assets/scss/theme.scss';


//Moondance API & Helpers
import UseMoondanceApi from "./moondance/lib/api";
import { TransactionForm } from "./moondance/pages/transactions_add_modal.js";
import { UpdateModalForm } from "./moondance/pages/nav_update_modal.js";
import { testurl, setupInactivityRedirect } from "./moondance/lib/formatData";


import MoondanceLayout from "./moondance/lib/layout";

import ReactGA from 'react-ga';
const TRACKING_ID = "G-X0GQ6NNSDY"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export const MoondanceContext = React.createContext(
  {
    accountList: {},
    selectedAccountList: {},
    selectedStatus: '1,',
    setSelectedStatus: () => { },
    getSelectedStatus: () => '',

    //Transaction Modal
    transactionModal: false,
    transactionModalData: {},
    toggleTransactionModal: () => { },
    closeTransactionModal: () => { },


    //NAV Modal
    navModal: false,
    lastUpdate: Date(),
    setLastUpdate: () => { },
    navModalData: {},
    toggleNavModal: () => { },
    closeNavModal: () => { },

    //user currency
    userCurrency: 'EUR',
    setUserCurrency: () => { },

    //user currency
    numberFormat: 'fr-FR',
    setNumberFormat: () => { },

    
  }
);

// To start monitoring inactivity and redirect to '/login' after 10 minutes
setupInactivityRedirect();



function App() {
  const url = window.location.href;
  const excludedPaths = [
    'login', 'register', 'forgot-password', 'reset-password', 'confirm-signup', 'home', 'confirm_contact_form'
  ];

  const [userCurrency, setUserCurrency] = useState('EUR');
  const [numberFormat, setNumberFormat] = useState('fr-FR');
  const [selectedAccountList, setSelectedAccountList] = useState(false);
  const [accountList, setAccountList] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([{ id: 1, label: "Open", value: 1, default: true, description: 'Additional calls and distribution are expected' }])
  const [lastUpdate, setLastUpdate] = useState(0);

  const [portraitMode, setPortraitMode] = useState(window.matchMedia("(orientation: portrait)").matches);

  const [darkMode, setDarkMode] = useState(false);

  ////////////////////////////////////////////////////////////////////////
  //Transaction Modal
  const [transactionModal, setTransactionModal] = useState(false);
  const [transactionModalData, setTransactionModalData] = useState({});
  const toggleTransactionModal = () => {
    setTransactionModal(!transactionModal);
  };
  const closeTransactionModal = () => {
    setTransactionModalData({})
    setTransactionModal(false);
  };
  ////////////////////////////////////////////////////////////////////////  
  //Alerts
  const [alertContent, setAlertContent] = useState([]);

  const addAlert = (value) => {

    const currentTime = new Date();
    const timestamp = currentTime.toLocaleString(); // You can format the timestamp as desired

    setAlertContent((prevAlerts) => [
      ...prevAlerts,
      {
        status: (value.status ? value.status : 'info'),
        title: (value.title ? value.title : 'Notification '),
        message: (value.message ? value.message : ''),
        link: (value.link ? value.link : false),
        time: currentTime,
        timeStamp: timestamp
      },]);

  }
  //check Token  validity





  window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
    const portrait = e.matches;
    if (portrait) {
        console.log("Portrait mode");
        setPortraitMode(true);
    } else {
        // do something else
        console.log("Landscape mode");
        setPortraitMode(false);
    }
});




  ////////////////////////////////////////////////////////////////////////
  //NAV Modal
  const [navModal, setNavModal] = useState(false);
  const [anyModal, setAnyModal] = useState(false);
  const [navModalData, setNavModalData] = useState({});
  const toggleNavModal = () => {

    setNavModal(!navModal);
  };
  const closeNavModal = () => {
    setNavModalData({})
    setNavModal(false);
  };

  const toggleDarkMode = (value) => {
    // Check if the element with the class exists
    const element = document.querySelector('.css-1omuo8w-MuiToolbar-root');
    if (element) {
      // Change the background color based on the dark mode value
      element.style.backgroundColor = value ? 'black' : 'white';
    }
    // Update the dark mode state
    MoondanceLayout.switchDarkMode(value);

    setLastUpdate(Math.floor(Date.now()))
    console.log('toggleDarkMode', Math.floor(Date.now()))
    setDarkMode(value);
  };

  if (localStorage.getItem("darkmode") === 'true' && !darkMode) {
    toggleDarkMode(true);
  }
  ////////////////////////////////////////////////////////////////////////  

  useEffect(() => {
      const moondanceApi = UseMoondanceApi();
      moondanceApi.clearCache();
   
  }, [lastUpdate]);
  useEffect(() => {
    function checkUser() {

      const moondanceApi = UseMoondanceApi();


      if (!excludedPaths.some(path => testurl(path, url)) && url !== 'http://localhost:3000/' && url !== 'https://www.moondance.financial/' && url !== 'https://moondance.financial/') {
        moondanceApi.getLoggedinUser().then(response => {
          if (response) {
            //console.log('getLoggedinUser', response)
            if (response.data && response.data.currency) {
              setUserCurrency(response.data.currency)
              if (response.data.currency === 'USD') {
                setNumberFormat('en-US')
                MoondanceLayout.number_format = 'en-US'
              }
            }


            moondanceApi.fetchAccounts().then(response2 => {
              if (response2.selectArray) {
                //console.log('AccountList', response2.selectArray);
                setAccountList(response2.selectArray)

                let existingSelectedAccountList = localStorage.getItem('selectedAccount');

                if (existingSelectedAccountList) {
                  existingSelectedAccountList = JSON.parse(existingSelectedAccountList);

                  // Filter existingSelectedAccountList based on response2.selectArray
                  const validatedSelectedAccountList = existingSelectedAccountList.filter(account =>
                    response2.selectArray.some(selectAccount => selectAccount.value === account.value)
                  );
                  // If validatedSelectedAccountList is empty, fallback to response2.defaultSelectedArray
                  if (validatedSelectedAccountList.length === 0 && response2.defaultSelectedArray) {
                    setSelectedAccountList(response2.defaultSelectedArray);
                    // Optionally, update localStorage to reflect the fallback selection
                    localStorage.setItem('selectedAccount', JSON.stringify(response2.defaultSelectedArray));
                  } else {
                    setSelectedAccountList(validatedSelectedAccountList);
                    // Update localStorage with the validated list                    
                    localStorage.setItem('selectedAccount', JSON.stringify(validatedSelectedAccountList));
                  }
                } else {
                  setSelectedAccountList(response2.defaultSelectedArray)
                }

                let existingSelectedStatus = localStorage.getItem('selectedStatus');
                if (existingSelectedStatus) {
                  setSelectedStatus(JSON.parse(existingSelectedStatus))
                }


              }
            }).catch(error => {
              console.log('Account list not found');
              window.location.href = '/accounts';
            }
            )
          } else {
            console.log('user not found')
            window.location.href = '/home';
          }
        }).catch(error => {
          console.log('Error while getting logged-in user:', error);
          window.location.href = '/logout';
        })
      }
    }
    checkUser()
  }, []);

  function checkTokenExpiration() {

    let token = localStorage.getItem('authUser');
    if (!token || isTokenExpired(token)) {
      console.log('Token expired');
      if (!excludedPaths.some(path => testurl(path, url)) && url !== 'http://localhost:3000/' && url !== 'https://www.moondance.financial/' && url !== 'https://moondance.financial/') {
        window.location.href = '/logout';
      }
    }

  }


  // Set an interval to execute isTokenExpired every 5 seconds (5000 milliseconds)
  setInterval(() => {
    if (!excludedPaths.some(path => testurl(path, url)) && url !== 'http://localhost:3000/' && url !== 'https://www.moondance.financial/' && url !== 'https://moondance.financial/') {

      checkTokenExpiration();
    }
  }, 5000);


  function isTokenExpired(token) {
    try {
      // Decode the JWT token
      const base64Url = token.split('.')[1]; // Get the payload part
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(atob(base64));

      // Get the current time and convert to Unix timestamp
      const currentTime = Math.floor(Date.now() / 1000);
      //console.log(currentTime, payload.exp, (payload.exp - currentTime) / 60)
      // Check if the token is expired
      if (payload.exp - currentTime < 0) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error("Error checking token expiration:", e);
      return true; // If there's an error, consider the token expired for safety
    }
  }



  return (
    <MoondanceContext.Provider value={
      {
        userCurrency,
        setUserCurrency,
        accountList,
        selectedAccountList,
        setSelectedAccountList,
        selectedStatus,
        setSelectedStatus,
        //Transaction Modal
        transactionModal,
        transactionModalData,
        setTransactionModalData,
        toggleTransactionModal,
        closeTransactionModal,

        //Nav Modal
        navModal,
        navModalData,
        setNavModalData,
        toggleNavModal,
        closeNavModal,
        lastUpdate,
        setLastUpdate,
        //Dark Mode
        darkMode,
        toggleDarkMode,
        //Alerts
        addAlert,
        alertContent,
        anyModal,
        setAnyModal,
        portraitMode

      }} >
      <div className={darkMode ? 'dark-theme' : 'light-theme'}>

        {(accountList && selectedAccountList) && (
          <TransactionForm isOpen={transactionModal} closeModal={closeTransactionModal} data={transactionModalData} />
        )}
        {(accountList && selectedAccountList) && (
          <UpdateModalForm isOpen={navModal} closeModal={closeNavModal} data={navModalData} />
        )}
        <Routes />
      </div>
    </MoondanceContext.Provider>
  )

}



export default App;
